import Api from '@/store/helpers/api';
import { logger } from '@/store/logger';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from './helpers/storeState';

const MAX_ENTITIES_PER_PAGE = 20;

const initialState = () => ({
  ...storeState,
  filter: {},
  orderBy: null,
  entitiesPage: null,
  offset: 0,
});

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },

  getters: {
    ...storeStateGetters,
    entitiesPage: (state) => state.entitiesPage,
    entitiesPageIndex: (state) => (state.offset > 0 ? (state.offset / MAX_ENTITIES_PER_PAGE) : 0),
    totalPages: (state) => (state.entitiesPage
      ? Math.ceil(state.entitiesPage.totalEntityCount / MAX_ENTITIES_PER_PAGE) : null),
    filter: (state) => state.filter,
    offset: (state) => state.offset,
    orderBy: (state) => state.orderBy,
  },

  mutations: {
    ...storeStateMutations,
    SET_ENTITIES_PAGE(state, page) {
      state.entitiesPage = page;
      logger.debug('entitesPage has been updated', state.entitiesPage);
    },
    SET_FILTER(state, filter) {
      state.filter = filter;
      logger.debug('entities filter has been updated', state.filter);
    },
    SET_OFFSET(state, offset) {
      state.offset = Math.floor(
        offset / MAX_ENTITIES_PER_PAGE,
      ) * MAX_ENTITIES_PER_PAGE;
    },
    SET_ORDER_BY(state, orderBy) {
      state.orderBy = orderBy;
    },
    RESET(state) {
      logger.debug('Reseting state of entities store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ dispatch, commit }, { filter = {}, orderBy = null }) => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing entity store');
        commit('SET_FILTER', filter);
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    // NOTE: Entities are PAGINATED.
    getEntities: async ({ rootGetters },
      {
        offset,
        filter, // { nameContains?: string }
        orderBy = null, // string in format direction:column e.g., asc:name or desc:relationshipCount
        maxPageSize = MAX_ENTITIES_PER_PAGE,
      }) => (new Api(process.env, rootGetters['authenticate/idToken']))
      .get('inter-document-entity/overview', {
        offset, maxPageSize, ...filter, ...(orderBy !== null && { orderBy }),
      }),

    filterEntities: async ({ dispatch, commit, getters }, filter) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Filtering entities - the offset is set to 0');
      try {
        commit('SET_FILTER', { ...getters.filter, ...filter });
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    sortEntities: async ({ dispatch, commit }, { orderBy = null }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Sorting entities - ', orderBy);
      try {
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    refreshCurrentPage: async ({ commit, getters, dispatch }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Re-fetching the current page of entities');
      try {
        const response = await dispatch('getEntities', { offset: getters.offset, filter: getters.filter, orderBy: getters.orderBy });
        commit('SET_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    nextPage: async ({ getters, dispatch, commit }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const isCurrentPageLastPage = getters.totalPages === 0 ? true : getters.entitiesPageIndex + 1 === getters.totalPages;
      const disableNext = isCurrentPageLastPage;
      // A null token is the token of the first page - prevent execution to avoid a loop.
      if (disableNext) {
        logger.debug('The current page is the last page, no update will be made as next page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset + MAX_ENTITIES_PER_PAGE);
      logger.debug('Fetching the next page of entities - offset has been updated to ', getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    prevPage: async ({ getters, dispatch, commit }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.entitiesPageIndex === 0) {
        logger.debug('The current page is the first page, no update will be made as previous page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset - MAX_ENTITIES_PER_PAGE);
      logger.debug('Fetching the previous page of entities - offset has been updated to ', getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    jumpToPage: async ({ getters, dispatch, commit }, pageNumber) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const newOffset = MAX_ENTITIES_PER_PAGE * (pageNumber - 1);
      commit('SET_OFFSET', newOffset);
      logger.debug(`Fetching page ${pageNumber} of entities - offset has been updated to `, getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    deleteEntities: async (
      {
        rootGetters, dispatch, getters, commit,
      }, ids,
    ) => {
      logger.debug('Deleting entities with the following interdocument ids: ', ids);
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('interdocumententity/delete', { uuids: ids })
        .then(async () => {
          logger.debug(`Entities ${ids} deleted successfully`);
          const newOffset = getters.offset - ids.length;
          commit('SET_OFFSET', newOffset < 0 ? 0 : newOffset);
          await dispatch('refreshCurrentPage');
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
        })
        .catch((e) => {
          logger.error('Failed to delete entities: ', ids);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
    createEntities: async ({ rootGetters, commit, dispatch }, entities) => {
      logger.debug('Creating entities: ', entities);
      try {
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        const response = await new Api(process.env, rootGetters['authenticate/idToken']).post('interdocumententity/create', { entities });
        logger.debug('Entities created successfully');
        await dispatch('refreshCurrentPage');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return response;
      } catch (e) {
        logger.error('Failed to create entities: ', entities);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    updateEntity: async ({ rootGetters, commit, dispatch }, {
      id, akas, name, clientIdentifier, fins,
    }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Updating entity: ', id, akas, name, clientIdentifier, fins);
      return new Api(process.env, rootGetters['authenticate/idToken']).patch(`interdocumententity/${id}`, {
        akas, name, clientIdentifier, fins,
      })
        .then(async (response) => {
          await dispatch('refreshCurrentPage');
          logger.debug('Entity updated successfully', response);
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
        })
        .catch((e) => {
          logger.error('Failed to update entity: ', id);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
  },
};

export default store;
