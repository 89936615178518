import Api from '@/store/helpers/api';
import GETUsageUpload from '@/store/helpers/mockResponses/usage/GETUsageUpload';
import GETUsageAccuracy from '@/store/helpers/mockResponses/usage/GETUsageAccuracy';
import { logger } from '@/store/logger';

const store = {
  namespaced: true,
  state: {

  },

  getters: {

  },

  mutations: {

  },

  actions: {
    getStatUpload: ({ rootGetters }) => {
      logger.debug('Getting stat upload');

      if (!rootGetters['documentRequest/offline']) {
        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .get('usage/upload');
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 1500, GETUsageUpload());
      }));
    },
    getStatAccuracy: ({ rootGetters }) => {
      if (!rootGetters['documentRequest/offline']) {
        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .get('usage/accuracy');
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 1500, GETUsageAccuracy());
      }));
    },
  },
};

export default store;
