<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="6 9 12 6"
  >     <path
    d="M12.3175 9.12364L17.8287 14.0965C18.1725 14.4068 17.9641 15 17.5112 15H6.48883C6.0359 15 5.82745 14.4068 6.17134 14.0965L11.6825 9.12364C11.8652 8.95879 12.1348 8.95879 12.3175 9.12364Z"
    fill="currentColor"
  />   </svg>
</template>
