const getMappingFor = (dict, val) => {
  const m = {};
  Object.entries(dict).forEach(([k, v]) => {
    if (val in v) {
      m[k] = v[val];
    }
  });

  return m;
};

const getMappingFrom = (dict, val) => {
  const m = {};
  Object.entries(dict).forEach(([k, v]) => {
    m[v[val]] = k;
  });

  return m;
};

export {
  getMappingFor, getMappingFrom,
};
