import ValidationException from '../exceptions/ValidationException';

const validateDocumentRequest = (docReq) => {
  if (!docReq || Object.keys(docReq).length === 0) {
    throw new ValidationException('Internal API error while fetching document metrics');
  }
  if (!docReq.DOCUMENT_REQUEST) {
    throw new ValidationException('No document request');
  }
  if (!docReq.DOCUMENT_REQUEST.id) {
    throw new ValidationException('No document request id');
  }
  if (!docReq.DOCUMENT_TYPE || !docReq.DOCUMENT_TYPE.name) {
    throw new ValidationException('No document type');
  }
  if (!docReq.GROUPING) {
    throw new ValidationException('No groupings available for this document');
  }
  if (!docReq.METRICS_AND_DATAPOINTS || !docReq.METRICS_AND_DATAPOINTS.length) {
    throw new ValidationException('No metrics available for this document');
  }

  return true;
};

export default validateDocumentRequest;
