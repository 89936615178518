import axios from 'axios';
import Api from '@/store/helpers/api';
import GETInsight from '@/store/helpers/mockResponses/GETInsight';
import { logger } from '@/store/logger';

const initialState = () => ({
  insights: [],
});

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },

  getters: {

  },

  mutations: {
    RESET(state) {
      Object.assign(state, initialState());
    },
    SET_INSIGHTS(state, insights) {
      state.insights = insights;
    },
  },

  actions: {
    init: ({ commit }) => {
      commit('RESET');
    },
    getInsights: ({ commit, dispatch }) => dispatch('getInsightsUrl')
      .then((r) => {
        commit('SET_INSIGHTS', r);
        logger.debug('Successfully fetched and set insights:', r);
        return r;
      }),
    getInsightsUrl: ({ getters, rootGetters }) => {
      logger.debug(`Getting insights url, offline: ${getters.offline}`);

      if (rootGetters['documentRequest/offline']) {
        return new Promise(((resolve) => {
          setTimeout(resolve, 500, GETInsight());
        }));
      }

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('insights')
        .then((url) => axios.get(url))
        .then((r) => r.data);
    },
  },
};

export default store;
