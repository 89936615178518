<!-- eslint-disable max-len -->
<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.46127 0.777488C9.34287 0.647984 9.17548 0.574219 9 0.574219C8.82453 0.574219 8.65714 0.647984 8.53873 0.777488L5.2054 4.42332C4.97248 4.67807 4.99019 5.0734 5.24494 5.30632C5.49969 5.53924 5.89502 5.52153 6.12794 5.26678L8.375 2.80905V12.0326C8.375 12.3777 8.65482 12.6576 9 12.6576C9.34518 12.6576 9.625 12.3777 9.625 12.0326V2.80906L11.8721 5.26678C12.105 5.52153 12.5003 5.53924 12.7551 5.30632C13.0098 5.0734 13.0275 4.67807 12.7946 4.42332L9.46127 0.777488Z"
      fill="currentColor"
    />
    <path
      d="M2.125 11.1992C2.125 10.854 1.84518 10.5742 1.5 10.5742C1.15482 10.5742 0.875 10.854 0.875 11.1992V11.2449C0.874984 12.3846 0.87497 13.3032 0.972104 14.0257C1.07295 14.7758 1.2887 15.4073 1.79029 15.9089C2.29189 16.4105 2.92345 16.6263 3.67354 16.7271C4.39602 16.8242 5.31462 16.8242 6.45428 16.8242H11.5457C12.6854 16.8242 13.604 16.8242 14.3265 16.7271C15.0766 16.6263 15.7081 16.4105 16.2097 15.9089C16.7113 15.4073 16.9271 14.7758 17.0279 14.0257C17.125 13.3032 17.125 12.3846 17.125 11.2449V11.1992C17.125 10.854 16.8452 10.5742 16.5 10.5742C16.1548 10.5742 15.875 10.854 15.875 11.1992C15.875 12.3954 15.8737 13.2297 15.789 13.8591C15.7068 14.4706 15.5565 14.7944 15.3258 15.025C15.0952 15.2557 14.7714 15.4061 14.1599 15.4883C13.5304 15.5729 12.6962 15.5742 11.5 15.5742H6.5C5.30382 15.5742 4.46956 15.5729 3.8401 15.4883C3.22862 15.4061 2.90481 15.2557 2.67418 15.025C2.44354 14.7944 2.29317 14.4706 2.21096 13.8591C2.12633 13.2297 2.125 12.3954 2.125 11.1992Z"
      fill="currentColor"
    />
  </svg>
</template>
