/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { ActionContext } from 'vuex';
import {
  allowedStates, storeState, storeStateGetters, storeStateMutations,
} from '../helpers/storeState';
import { logger } from '../logger';
import { GetSchemasMetadataResponse, Getters, State } from './types';
import Api from '../helpers/api';

const initialState = (): State => ({
  ...storeState,
  data: null,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  schemas: (state: State) => state.data?.items ?? [],
  documentTypeAssignments: (state: State) => {
    if (!state.data) {
      return {};
    }
    const schemas = state.data.items;
    const documentTypeAssignments: { [key: string]: { version: number, displayName: string, schemaId: string } } = {};
    schemas.forEach((schema) => {
      const { associatedDocumentTypes } = schema;
      associatedDocumentTypes.forEach((associatedDocumentType) => {
        const { type, version, displayName } = associatedDocumentType;
        if (type in documentTypeAssignments) {
          documentTypeAssignments[type] = documentTypeAssignments[type].version > version ? documentTypeAssignments[type] : { version, displayName, schemaId: schema.documentSchemaId };
        } else {
          documentTypeAssignments[type] = { version, schemaId: schema.documentSchemaId, displayName };
        }
      });
    });

    return documentTypeAssignments;
  },
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_DATA(state: State, data: GetSchemasMetadataResponse) {
      state.data = data;
    },
    RESET(state: State) {
      logger.debug('Reseting state of schemas store');
      Object.assign(state, initialState());
    },
  },
  actions: {
    init: async ({ commit, dispatch }: ActionContext<State, any>): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing schemas store');
        const response: GetSchemasMetadataResponse = await dispatch('getSchemas');
        commit('SET_DATA', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        logger.debug('Failed to initialise schemas store', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    getSchemas: async (
      { rootGetters }: ActionContext<State, any>,
    ): Promise<GetSchemasMetadataResponse> => {
      logger.debug('Fetching schemas');
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('schemas/metadata', {
          maxPageSize: 10000,
          isTemplate: false,
        } as any);
    },

    deleteSchema: async ({ rootGetters }: ActionContext<State, any>,
      { documentSchemaId }: { documentSchemaId: string }): Promise<void> => {
      logger.debug(`Deleting schema -- id ${documentSchemaId}`);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .delete(`schemas/${documentSchemaId}`);
    },

    publishSchema: async ({ rootGetters }: ActionContext<State, any>,
      { documentSchemaId }: { documentSchemaId: string }): Promise<void> => {
      logger.debug(`Publishing schema -- id ${documentSchemaId}`);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(`schemas/${documentSchemaId}/publish`, {});
    },

    refreshSchemas: async ({ commit, dispatch }: ActionContext<State, any>): Promise<void> => {
      try {
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Refreshing schemas store');
        const response: GetSchemasMetadataResponse = await dispatch('getSchemas');
        commit('SET_DATA', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        logger.debug('Failed to refresh schemas store', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    assignDocumentType: async (
      { rootGetters, commit }: ActionContext<State, any>,
      { documentType, documentSchemaId }: { documentType: string, documentSchemaId: string },
    ): Promise<void> => {
      logger.debug('Fetching schemas');
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('versioned-document-types', {
          documentType,
          documentSchemaId,
        } as any)
        .then(() => commit('SET_STORE_STATUS', allowedStates.IS_READY))
        .catch((e) => {
          logger.debug('Failed to assign document type to schema', e);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
  },
};

export default store;
