<template>
  <table class="contained-table">
    <tr :style="{zIndex: '1', position: 'relative'}">
      <th>
        Name
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('name')"
          @click="onClickColumnSort('name')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th v-if="enableUserSettingsUplift">
        Email
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('email')"
          @click="onClickColumnSort('email')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th v-if="enableUserSettingsUplift">
        Role
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('role')"
          @click="onClickColumnSort('role')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th>
        Status
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('account_status')"
          @click="onClickColumnSort('account_status')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
    </tr>

    <tr
      v-for="user in users"
      :key="user.id"
    >
      <td>
        <a
          v-if="canUpdateUsers"
          href="#"
          class="cell-link"
          @click.prevent="onUserNamePressed(user)"
        >
          {{ user.name }}
        </a>
        <span v-else>{{ user.name }}</span>
      </td>
      <td v-if="enableUserSettingsUplift">
        {{ user.email }}
      </td>
      <td v-if="enableUserSettingsUplift">
        <user-role-pill :user-permission-level="user.permissions" />
      </td>
      <td class="active-status">
        {{ getAccountStatusDisplay(user.account_status) }}
      </td>
    </tr>
  </table>
  <p
    v-if="!users.length"
    class="hint"
  >
    No users to display.
  </p>
</template>
<script>
import { getAccountStatusDisplay } from '@/store/helpers/mapping/permissions';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import UserRolePill from '@/components/users/UserRolePill.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TriangleIcon,
    UserRolePill,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    order: {
      type: String,
      default: null,
    },
  },
  emits: ['userSelected', 'updateOrder'],
  data() {
    return {
      selectedOrderByColumn: null,
      canUpdateUsers: null,
    };
  },
  computed: {
    ...mapGetters({
      orderByColumn: 'users/orderByColumnName',
      orderByDirection: 'users/orderByDirection',
    }),
    enableUserSettingsUplift() {
      return FEATURE_FLAGS.USER_SETTINGS_UPLIFT;
    },
    sortIconClass() {
      if (this.orderByDirection === 'desc') { return 'sort-desc'; }
      if (this.orderByDirection === 'asc') { return 'sort-asc'; }
      return '';
    },
  },
  async created() {
    const res = await this.checkCanUpdateUsers();
    this.canUpdateUsers = res;
  },
  methods: {
    ...mapActions({
      sortUsers: 'users/sortUsers',
      checkPermission: 'localisation/checkPermission',
    }),
    onUserNamePressed(user) {
      this.$log.info('onUserNamePressed', user);
      this.$emit('userSelected', user.id);
    },
    getAccountStatusDisplay,
    sortedColumnClass(columnName) {
      if (this.orderByColumn === columnName) {
        return this.sortIconClass;
      }
      return '';
    },
    onClickColumnSort(columnName) {
      // Remove sort if this column is already sorted in descending direction
      if (this.orderByColumn === columnName && this.orderByDirection === 'desc') {
        this.updateOrderByQueryParam(null);
        return;
      }
      // Sort this column by descending if this column already sorted in ascending direction
      if (this.orderByColumn === columnName && this.orderByDirection === 'asc') {
        this.updateOrderByQueryParam(`${columnName}:desc`);
        return;
      }
      // Sort by ascending
      this.updateOrderByQueryParam(`${columnName}:asc`);
    },
    updateOrderByQueryParam(orderBy) {
      this.$router.push({ query: { ...this.$route.query, orderBy } });
    },
    async checkCanUpdateUsers() {
      const sufficientPermissions = await this.checkPermission('CAN_UPDATE_USERS');
      return sufficientPermissions;
    },
  },
};
</script>
<style lang="scss" scoped>
.active-status {
  color: $col-grey;
}
.icon-container:hover {
  background-color: transparent;
}

.sort-desc {
  svg {
    fill: $bg-body-neg;
    stroke: $bg-body-neg;
  }
}

.sort-asc {
  svg {
    transform: rotate(0deg);
    fill: $bg-body-neg;
    stroke: $bg-body-neg;
  }
}
</style>
