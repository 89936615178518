import {
  computed, ComputedRef, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

type Props = {
  page: ComputedRef<number>;
  maxPageSize: ComputedRef<number>;
  orderBy?: ComputedRef<string>;
  filters: ComputedRef<{ [key: string]: string | string[] }>;
};

export default ({
  page,
  maxPageSize,
  orderBy,
  filters,
}: Props) => {
  const route = useRoute();
  const router = useRouter();

  /**
   * Computed property that generates a query object for the router based on the current state
   * of page, maxPageSize, orderBy, and filters.
   */
  const query = computed(() => ({
    page: page.value,
    maxPageSize: maxPageSize.value,
    ...orderBy && { orderBy: orderBy.value },
    ...filters.value,
  }));

  /**
   * Automatically update the URL query string when the query object changes.
   */
  watch(query, (newVals) => {
    router.push({ query: { ...newVals } });
  });

  /**
   * Extracts query parameters from the current route and provides them in a structured format,
   * ensuring type conversion and providing default values when necessary.
   */
  const queryParams = computed(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      page, maxPageSize, orderBy, ...restQueryParams
    } = route.query;

    const f: { [key: string]: any } = {};
    Object.keys(restQueryParams).forEach((key) => {
      f[key] = restQueryParams[key];
    });

    return {
      page: Number(page) || 1,
      maxPageSize: Number(maxPageSize) || 20,
      orderBy: orderBy || '',
      filters: f,
    };
  });

  return {
    queryParams,
  };
};
