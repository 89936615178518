import { errorMessages } from '@/store/helpers/display/toastMessages';
import ValidationException from '../exceptions/ValidationException';

/**
 * Throw exception when validation failed.
 * By default, use the description on the validator to let the
 * user know what went wrong.
 */
const throwValidatorException = (validator) => {
  let message;
  if (validator.description) {
    message = `Validation failed: ${validator.description}`;
  } else if (validator.type === 'INCLUDE' && validator.options?.length) {
    message = `Validation failed: Value must match one of the following metric options: ${validator.options.join(', ')}`;
  } else {
    message = errorMessages.FAILED_METRIC_VALIDATION;
  }
  throw new ValidationException(message);
};

/**
 * Validate options if there are any.
 */
const validateOptions = (value, validator) => {
  if (!('options' in validator)) {
    return;
  }

  if (
    (validator.type === 'INCLUDE' && !validator.options.includes(value))
    || (validator.type === 'EXCLUDE' && validator.options.includes(value))
  ) {
    throwValidatorException(validator);
  }
};

/**
 * Validate regex if there is any.
 */
const validateRegex = (value, validator) => {
  if (!('regex' in validator)) {
    return;
  }

  const regexPattern = new RegExp(validator.regex);
  if (
    (validator.type === 'INCLUDE' && !regexPattern.test(value))
    || (validator.type === 'EXCLUDE' && regexPattern.test(value))
  ) {
    throwValidatorException(validator);
  }
};

/**
 * Base metric validator method; given a set of validators,
 * make sure they are all fit to a value.
 */
const validateMetric = (value, validators) => {
  // If no value, skip.
  // Note: 0 value is accepted.
  if (value === null || value === '') {
    return;
  }

  // If no validators, skip.
  if (validators.length === 0) {
    return;
  }

  validators.forEach((validator) => {
    validateOptions(value, validator);
    validateRegex(value, validator);
  });
};

/**
 * Validate ENTITY_NAME type metric.
 */
const validateEntityName = (name, validators) => {
  const trimmedName = name.replace(/\s+/g, '').toLowerCase();
  if (trimmedName === '') {
    throw new ValidationException(errorMessages.NO_NAME_ENTITY);
  }
  validateMetric(trimmedName, validators);
};

/**
 * Extension to 'validateEntityName' for for multiple names.
 */
const validateManyEntityNames = (names, validators) => {
  names.forEach((name) => {
    validateEntityName(name, validators);
  });
};

export { validateMetric, validateEntityName, validateManyEntityNames };
