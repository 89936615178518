import { logger } from '@/store/logger';

const defaultState = () => ({
  breadcrumbIndex: 0,
  alternativeAnswerDisplays: [],
  selectedAnswer: {
    watchTriggerIndex: 0, // can be watched to get selected answer datapoint index (even if the same as last index triggered)
    index: 0, // datapoint index
  },
});

const store = {
  namespaced: true,
  state: {
    ...defaultState(),
  },

  getters: {
    breadcrumbIndex: (state) => state.breadcrumbIndex,
    alternativeAnswerDisplays: (state) => state.alternativeAnswerDisplays,
    selectedAlternativeAnswerTrigger: (state) => state.selectedAnswer.watchTriggerIndex,
    selectedAlternativeAnswerIndex: (state) => state.selectedAnswer.index,
  },

  mutations: {
    SET_PROP: (state, { key, value }) => {
      state[key] = value;
    },
    UPDATE_ALTERNATIVES: (state, { breadcrumbIndex, alternativeAnswers }) => {
      // We want to update our alternative answers in a single transaction
      state.breadcrumbIndex = breadcrumbIndex;
      state.alternativeAnswerDisplays = alternativeAnswers;
    },
    UPDATE_SELECTED_ALTERNATIVE_ANSWER_INDEX: (state, index) => {
      state.selectedAnswer.watchTriggerIndex += 1;
      state.selectedAnswer.index = index;
    },
    RESET_ALL(state) {
      Object.assign(state, defaultState());
    },
  },

  actions: {
    updateAlternatives: (_, { breadcrumbIndex, alternativeAnswers }) => {
      // Currently, skipping updating alternative answers
      // commit('UPDATE_ALTERNATIVES', { breadcrumbIndex, alternativeAnswers: [...alternativeAnswers] });
      logger.debug('would have updated alternative answers:', breadcrumbIndex, alternativeAnswers);
    },
    updateSelectedAlternativeAnswerIndex: ({ commit }, { index }) => {
      commit('UPDATE_SELECTED_ALTERNATIVE_ANSWER_INDEX', index);
      logger.debug('updated selected alternative answer:', index);
    },
    reset: ({ commit }) => {
      commit('RESET_ALL');
    },
  },
};

export default store;
