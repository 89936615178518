/**
 * Decodes a JWT ID token.
 *
 * This function does not verify the token. Pass only tokens that you trust.
 *
 * @param {*} token JWT ID token.
 * @returns Decoded token.
 */
const decodeIdToken = (token) => {
  const payload = token.split('.')[1];
  const parsed = payload.replace(/-/g, '+').replace(/_/g, '/');

  const encoded = window.atob(parsed)
    .split('')
    .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
    .join('');

  const decoded = decodeURIComponent(encoded);
  return JSON.parse(decoded);
};

export default decodeIdToken;
