import { logger } from '@/store/logger';
import {
  storeState, storeStateGetters, storeStateMutations, allowedStates,
} from './helpers/storeState';
import Api from './helpers/api';
import GETDocumentTypes from './helpers/mockResponses/GETDocumentTypes';
import backendToDocumentTypes from './helpers/request/transformers/transformDocumentTypes';

const store = {
  namespaced: true,
  state: {
    ...storeState,
    documentTypes: null,
  },

  getters: {
    ...storeStateGetters,
    documentTypes: (state) => state.documentTypes,
    documentTypesToDisplayMapping: (state) => {
      if (!state.documentTypes) { return {}; }
      const dict = {};
      state.documentTypes.forEach((documentType) => {
        dict[documentType.id] = documentType.name;
      });
      return dict;
    },
  },

  mutations: {
    ...storeStateMutations,
  },

  actions: {
    lazyGet: async ({ commit, dispatch, getters }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);

      if (getters.documentTypes?.length) {
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return Promise.resolve(getters.documentTypes);
      }

      try {
        const rawDocTypes = await dispatch('getDocumentTypes');
        logger.debug('Getting doc types', rawDocTypes);
        const docTypes = backendToDocumentTypes(rawDocTypes);
        commit('SET_PROP', { key: 'documentTypes', value: docTypes });
        commit('SET_STORE_STATUS', allowedStates.IS_READY);

        return docTypes;
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    getDocumentTypes: ({ rootGetters }) => {
      logger.debug(`Getting document types, offline: ${rootGetters['documentRequest/offline']}`);

      if (!rootGetters['documentRequest/offline']) {
        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .get('documenttype');
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, GETDocumentTypes());
      }));
    },

  },
};

export default store;
