<template>
  <div class="dropdown-menu">
    <slot name="menuContent" />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.dropdown-menu {
  z-index: 2;
  position: absolute;
  right: 0;
  margin-top: 5px;
  padding: 4px 0px;

  border-radius: 8px;
  display: flex;
  width: 220px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border: 1px solid #F2F2F2;
  background: #FFF;

  /* Shadow/300 */
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
</style>
