<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9067 4.19149C13.1688 4.41613 13.1992 4.81069 12.9745 5.07277L8.32317 10.4994L12.9745 15.926C13.1992 16.188 13.1688 16.5826 12.9067 16.8072C12.6447 17.0319 12.2501 17.0015 12.0255 16.7394L7.02546 10.9061C6.82485 10.672 6.82485 10.3267 7.02546 10.0926L12.0255 4.25928C12.2501 3.9972 12.6447 3.96685 12.9067 4.19149Z"
      fill="currentColor"
    />
  </svg>
</template>
