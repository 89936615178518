import { logger } from '@/store/logger';

const store = {
  namespaced: true,
  state: {
    idsQueue: [],
    currentQueueIndex: null,
  },

  getters: {
    currentDocReqId: (state) => (state.currentQueueIndex !== null ? state.idsQueue[state.currentQueueIndex] : null),
    currentQueueIndex: (state) => state.currentQueueIndex,
    currentQueueLength: (state) => state.idsQueue.length,
    currentQueueIndexIsLast: (state) => state.currentQueueIndex === state.idsQueue.length - 1,
  },

  mutations: {
    SET_QUEUE(state, idsQueue) {
      state.idsQueue = idsQueue;
    },
    SET_CURRENT_QUEUE_INDEX(state, queueIndex) {
      state.currentQueueIndex = queueIndex;
    },
    INCREMENT_CURRENT_QUEUE_INDEX(state) {
      state.currentQueueIndex += 1;
    },
  },

  actions: {
    createNewQueue: ({ commit }, ids) => {
      commit('SET_QUEUE', ids);
      const newCurrentQueueIndex = ids.length ? 0 : null;
      commit('SET_CURRENT_QUEUE_INDEX', newCurrentQueueIndex);
    },
    incrementQueuePosition: ({ commit, getters }) => {
      logger.debug('currentQueueIndexIsLast:', getters.currentQueueIndexIsLast);
      if (getters.currentQueueIndexIsLast) {
        commit('SET_CURRENT_QUEUE_INDEX', null);
      } else {
        commit('INCREMENT_CURRENT_QUEUE_INDEX');
      }
      logger.debug('resolving with results:', getters.currentQueueIndex);
      return Promise.resolve(getters.currentQueueIndex);
    },
  },
};

export default store;
