import {
  ComputedRef,
  Ref, computed, ref,
} from 'vue';

/**
 * RowSelection represents the selection state of multiple rows in a table.
 * It is an object where each key corresponds to a unique row identifier (typically the `rowKey`),
 * and each value is an object containing:
 * - `isSelected`: A boolean that indicates whether the row is selected.
 * - `value`: The actual row data associated with that row identifier.
 */
export type RowSelection = {
  [key: string]: {
    isSelected: boolean,
    value: any,
  }
};

export type RowSelectionController = {
  rowIds: ComputedRef<string[]>,
  rowSelection: Ref<RowSelection>,
  selectedRowIds: ComputedRef<string[]>,
  selectedRows: ComputedRef<any>,
  selectRows: (ids: string[]) => void,
  unselectRows: (ids: string[]) => void,
  toggleSelectRow: (id: string) => void,
  toggleSelectMultipleRows: (ids: string[]) => void,
  selectAll: () => void,
  unselectAll: () => void,
  resetRowSelection: () => void,
  isAllRowsSelected: ComputedRef<boolean>,
};

/**
 * A Row is a generic object with key-value pairs.
 * One of the keys must be a unique identifier for the row.
 */
export type Row = Record<string, any>;

type Props = {
  /**
   * Computed reference for an array of rows. Each row is a record with key-value pairs.
   */
  rows: ComputedRef<Row[]>;

  /**
   * A string representing the key used to uniquely identify each row.
   */
  rowKey: string;
};

export default ({
  rows,
  rowKey, // Unique row identifier key
}: Props): RowSelectionController => {
  /**
   * Reactive state to track the selected rows. Each entry contains:
   * - `isSelected`: Boolean flag to indicate if the row is selected.
   * - `value`: The actual row data.
   */
  const rowSelection: Ref<RowSelection> = ref({});

  /**
   * Computed reference to get an array of row IDs based on the unique `rowKey` provided.
   */
  const rowIds: ComputedRef<string[]> = computed(() => rows.value.map((row: Row) => row[rowKey]));

  /**
   * Computed reference to get an array of selected row IDs.
   * Filters the rowSelection object to return only the keys (IDs) of selected rows.
   */
  const selectedRowIds: ComputedRef<string[]> = computed(
    () => Object.keys(rowSelection.value).filter(
      (key: string) => rowSelection.value[key].isSelected === true,
    ),
  );

  /**
   * Computed reference to get an array of selected row data.
   * Maps the selectedRowIds to their corresponding row values.
   */
  const selectedRows: ComputedRef<any[]> = computed(
    () => selectedRowIds.value.map((id) => rowSelection.value[id].value),
  );

  /**
   * Computed reference to check if all rows are selected.
   * Returns `true` if every row is selected, otherwise `false`.
   * It ensures that no rows are selected when the list is empty.
   */
  const isAllRowsSelected = computed(() => {
    if (rowIds.value.length === 0) {
      return false; // If no rows exist, all rows can't be selected
    }
    return rowIds.value.every((val) => selectedRowIds.value.includes(val));
  });

  /**
   * Toggles the selection state of a single row based on its ID.
   * If the row is already selected, it will be deselected, and vice versa.
   *
   * @param id - The unique identifier of the row to toggle.
   */
  function toggleSelectRow(id: string) {
    const value = rows.value.find((row: Row) => row[rowKey] === id);
    if (!value) return; // Do nothing if row is not found

    const isCurrentlySelected = rowSelection.value[id]?.isSelected;
    rowSelection.value[id] = {
      isSelected: !isCurrentlySelected,
      value,
    };
  }

  /**
   * Sets the selection state of the specified rows to true.
   *
   * @param ids - An array of unique row identifiers to set as selected.
   */
  function selectRows(ids: string[]) {
    const tempRowSelection: RowSelection = {};

    ids.forEach((id) => {
      const value = rows.value.find((row: Row) => row[rowKey] === id);
      if (value) {
        tempRowSelection[id] = {
          isSelected: true,
          value,
        };
      }
    });

    // Update row selectionn
    rowSelection.value = { ...rowSelection.value, ...tempRowSelection };
  }

  /**
   * Sets the selection state of the specified rows to false.
   *
   * @param ids - An array of unique row identifiers to set as not selected.
   */
  function unselectRows(ids: string[]) {
    const tempRowSelection: RowSelection = {};

    ids.forEach((id) => {
      const value = rows.value.find((row: Row) => row[rowKey] === id);
      if (value) {
        tempRowSelection[id] = {
          isSelected: false,
          value,
        };
      }
    });

    // Update row selectionn
    rowSelection.value = { ...rowSelection.value, ...tempRowSelection };
  }

  /**
   * Toggles the selection state of multiple rows based on their IDs.
   * If a row is selected, it will be deselected, and vice versa.
   *
   * @param ids - An array of unique row identifiers to toggle.
   */
  function toggleSelectMultipleRows(ids: string[]) {
    const tempRowSelection: RowSelection = {};

    ids.forEach((id) => {
      const value = rows.value.find((row: Row) => row[rowKey] === id);
      if (value) {
        const isCurrentlySelected = rowSelection.value[id]?.isSelected;
        tempRowSelection[id] = {
          isSelected: !isCurrentlySelected,
          value,
        };
      }
    });

    // Update row selection state with the newly toggled selection
    rowSelection.value = { ...rowSelection.value, ...tempRowSelection };
  }

  /**
   * Selects all rows by setting their `isSelected` property to `true`.
   */
  function selectAll() {
    rows?.value.forEach((row: Row) => {
      const id = row[rowKey];
      rowSelection.value[id] = {
        isSelected: true,
        value: row,
      };
    });
  }

  /**
   * Deselects all rows by setting their `isSelected` property to `false`.
   */
  function unselectAll() {
    rows?.value.forEach((row: Row) => {
      const id = row[rowKey];
      rowSelection.value[id] = {
        isSelected: false,
        value: row,
      };
    });
  }

  /**
   * Resets the row selection by clearing the `rowSelection` object.
   */
  function resetRowSelection() {
    rowSelection.value = {};
  }

  // Return the relevant properties and functions to be used in components
  return {
    rowIds,
    rowSelection,
    selectedRowIds,
    selectedRows,
    toggleSelectRow,
    unselectRows,
    selectRows,
    toggleSelectMultipleRows,
    selectAll,
    unselectAll,
    resetRowSelection,
    isAllRowsSelected,
  };
};
