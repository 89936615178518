<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8785 1.8371C10.9652 1.58215 10.1098 1.85776 9.50198 2.41628C8.90355 2.96615 8.5415 3.78792 8.5415 4.66718V10.2459C8.5415 11.3021 9.39772 12.1583 10.4539 12.1583H16.0327C16.9119 12.1583 17.7337 11.7963 18.2836 11.1979C18.8421 10.59 19.1177 9.73467 18.8627 8.82135C17.9193 5.44168 15.2582 2.78051 11.8785 1.8371ZM9.7915 4.66718C9.7915 4.12442 10.0166 3.64098 10.3477 3.33672C10.6694 3.04112 11.0861 2.91369 11.5424 3.04107C14.5009 3.86691 16.8329 6.19895 17.6588 9.15743C17.7862 9.61377 17.6587 10.0304 17.3631 10.3521C17.0589 10.6832 16.5754 10.9083 16.0327 10.9083H10.4539C10.0881 10.9083 9.7915 10.6118 9.7915 10.2459V4.66718Z"
      fill="currentColor"
    />
    <path
      d="M7.22677 4.62962C7.55609 4.52618 7.73921 4.17537 7.63577 3.84605C7.53234 3.51674 7.18153 3.33362 6.85221 3.43705C3.48509 4.4946 1.0415 7.63994 1.0415 11.3576C1.0415 15.942 4.75785 19.6583 9.3422 19.6583C13.0599 19.6583 16.2052 17.2147 17.2628 13.8476C17.3662 13.5183 17.1831 13.1675 16.8538 13.0641C16.5245 12.9606 16.1737 13.1437 16.0702 13.4731C15.1716 16.3342 12.4983 18.4083 9.3422 18.4083C5.44821 18.4083 2.2915 15.2516 2.2915 11.3576C2.2915 8.20153 4.36566 5.52824 7.22677 4.62962Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup>
</script>
