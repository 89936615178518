import axios from 'axios';
import { logger } from '@/store/logger';
import qs from 'qs';
import ValidationException from './request/exceptions/ValidationException';

class Api {
  constructor(env, idJwtToken) {
    if (!env || !idJwtToken) {
      throw new ValidationException(`env and idJwtToken must be set. env: ${!!env}, idJwtToken: ${!!idJwtToken}`);
    }

    this.apiUrl = env.VUE_APP_API_ENDPOINT;
    this.clientId = env.VUE_APP_CLIENT;
    this.idJwtToken = idJwtToken;
  }

  axiosConfig() {
    return {
      headers: {
        Authorization: `Bearer ${this.idJwtToken}`,
      },
    };
  }

  prependClient(path) {
    return `client/${this.clientId}/${path}`;
  }

  prependFullUrl(path) {
    return `${this.apiUrl}/client/${this.clientId}/${path}`;
  }

  prependBackendApiUrl(path) {
    return `${this.apiUrl}/${path}`;
  }

  async get(path, params = null) {
    logger.debug('performing get', path, 'with params', params);
    const fullPath = this.prependFullUrl(path);
    const config = this.axiosConfig();
    if (params) {
      config.params = params;
      config.paramsSerializer = (p) => qs.stringify(p, { arrayFormat: 'repeat' });
    }
    return axios.get(fullPath, config)
      .then((resp) => {
        logger.debug('successful get:', resp);

        return resp.data;
      });
  }

  async delete(path) {
    logger.debug('performing delete', path);
    const fullPath = this.prependFullUrl(path);
    return axios.delete(fullPath, this.axiosConfig())
      .then((resp) => {
        logger.debug('successful delete:', resp);

        return resp.data;
      });
  }

  async patch(path, body) {
    const clientPath = this.prependClient(path);

    return this.rootPatch(clientPath, body);
  }

  async post(path, body) {
    const clientPath = this.prependClient(path);

    return this.rootPost(clientPath, body);
  }

  static async postFile(path, formData, cancelToken) {
    logger.debug('Posting file:', path, 'formData:', formData, 'CToken:', cancelToken);
    const config = {
      method: 'post',
      url: path,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken,
    };
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }

    return axios(config);
  }

  async rootPost(path, body) {
    logger.debug('performing post', path, 'with body', body);
    const fullPath = this.prependBackendApiUrl(path);

    return axios.post(fullPath, body, this.axiosConfig())
      .then((resp) => {
        logger.debug('successful post:', resp);

        return resp.data;
      });
  }

  async rootPatch(path, body) {
    logger.debug('performing patch', path, 'with body', body);
    const fullPath = this.prependBackendApiUrl(path);

    return axios.patch(fullPath, body, this.axiosConfig())
      .then((resp) => {
        logger.debug('successful patch:', resp);

        return resp.data;
      });
  }
}

export default Api;
