import { backendToMetric, metricToBackend } from './transformMetric';

const backendToGrouping = (backendGrouping) => ({
  id: backendGrouping.id,
  groupDisplay: backendGrouping.group_display,
  groups: backendGrouping.groups.map((g) => backendToGrouping(g)),
  metrics: backendGrouping.metrics,
  allowRepeatable: backendGrouping.allow_repeatable,
  // groupIdentifier: backendGrouping.groupIdentifier,
});

const backendToDocumentRequest = (docReq) => ({
  id: String(docReq.DOCUMENT_REQUEST.id),
  verifyStatus: docReq.DOCUMENT_REQUEST.verified === true ? 'DOC_VERIFIED' : 'DOC_UNVERIFIED',
  documentType: docReq.DOCUMENT_TYPE.name,
  metrics: docReq.METRICS_AND_DATAPOINTS.map((m, i) => backendToMetric(m, i)),
  grouping: backendToGrouping(docReq.GROUPING),
});

const documentRequestToBackend = (docReq) => ({
  documentRequestId: docReq.id,
  verified: docReq.verifyStatus === 'DOC_VERIFIED',
  documentType: docReq.documentType,
  metrics: docReq.metrics.map((m, i) => metricToBackend(m, i)),
});

/**
 * Removes asterisk from AKA headers of an uncompressed document request response.
 *
 * Background:
 *  AKA Column headers are a string concatenation of the Entity Name column header
 *  + 'AKA'. When the entity name column header contains an * due to it being a required
 *  field, the AKA column will also contain an *. This is misleading, as AKAs are non-required.
 */
const removeAsteriskFromAKAHeaders = (docReq) => {
  docReq.tables.forEach((table) => {
    const { columnHeaders } = table;
    columnHeaders.forEach((header) => {
      if (header.metricType === 'AKA') {
        // eslint-disable-next-line no-param-reassign
        header.text = header.text.replace('*', '');
      }
    });
  });
};

export { backendToDocumentRequest, documentRequestToBackend, removeAsteriskFromAKAHeaders };
