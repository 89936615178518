import ValidationException from './request/exceptions/ValidationException';

/**
 * Strips negative sign
 * Bounds within precision range (after decimal point)
 *
 * @param num
 * @param maxPrecision
 */
const limitToMaxPrecision = (num, maxPrecision) => String(+num.toFixed(maxPrecision));

const regexpEscape = (s) => s.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');

/**
 * Returns a number string padded to a minimum number of decimal points.
 * Note, always contains a decimal point (including trailing at the end)
 *
 * @param num {string}
 * @param minPrecision
 * @param decimalPointChar
 * @returns {string|*}
 */
const padToMinPrecision = (num, minPrecision, decimalPointChar) => {
  const dpId = num.lastIndexOf(decimalPointChar);

  const getNumStrWithDecimal = (n, _dpId, _dpChar) => ((_dpId > -1) ? n : `${n}${_dpChar}`);
  const numStrWithDecimal = getNumStrWithDecimal(num, dpId, decimalPointChar);

  if (minPrecision > 0) {
    const fractionalLength = numStrWithDecimal.length - dpId - 1;
    let paddingNeeded;
    if (dpId > -1) {
      paddingNeeded = minPrecision - fractionalLength;
      paddingNeeded = paddingNeeded >= 0 ? paddingNeeded : 0;
    } else {
      paddingNeeded = minPrecision;
    }
    if (paddingNeeded > 0) {
      return numStrWithDecimal + '0'.repeat(paddingNeeded);
    }
  }

  return numStrWithDecimal;
};

const formatNumber = (num, {
  minPrecision = 0, maxPrecision = 10, thousandSeparator = ',', decimalPoint = '.',
} = {}) => {
  if (typeof num !== 'number') {
    throw new ValidationException(`${num} is not a number`);
  }
  if (minPrecision < 0 || minPrecision > 10 || minPrecision > maxPrecision) {
    throw new ValidationException('Min precision must be > 0, < 10 and < maxPrecision');
  }
  if (maxPrecision < 0 || maxPrecision > 10) {
    throw new ValidationException('Max precision must be > 0, < 10 and < maxPrecision');
  }
  const nStr = limitToMaxPrecision(num, maxPrecision);
  const nLimited = padToMinPrecision(nStr, minPrecision, decimalPoint);

  const regexpDecimalMark = regexpEscape(decimalPoint); // e.g. \.
  const thousandSeparatorRegexp = new RegExp(`\\d(?=(\\d{3})+${regexpDecimalMark})`, 'g');
  const trimRegexp = new RegExp(`${regexpDecimalMark}$`); // e.g. /\.$/
  const thousandSepApplied = nLimited.replace(thousandSeparatorRegexp, `$&${thousandSeparator}`);

  return thousandSepApplied.replace(trimRegexp, '');
};

export default formatNumber;
