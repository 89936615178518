import { logger } from '@/store/logger';
import {
  allowedStates, storeState, storeStateActions, storeStateGetters, storeStateMutations,
} from './helpers/storeState';
import Api from './helpers/api';

const store = {
  namespaced: true,
  state: {
    ...storeState,
    navigation: '',
  },

  getters: {
    ...storeStateGetters,
    navigation: (state) => state.navigation,
  },

  mutations: {
    ...storeStateMutations,
    SET_NAVIGATION(state, navigation) {
      state.navigation = navigation;
    },
  },

  actions: {
    ...storeStateActions,
    getNavigation: async ({ rootGetters }) => {
      const url = 'navigation';
      return (new Api(process.env, rootGetters['authenticate/idToken'])).get(url);
    },
    lazyInit: async ({ dispatch, commit, getters }, { disableCache = false } = {}) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.navigation !== '' && disableCache === false) {
        logger.debug('Reusing cached navigation: ', getters.navigation);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return getters.navigation;
      }
      try {
        logger.debug('Getting new navigation');
        const navigation = await dispatch('getNavigation');
        commit('SET_NAVIGATION', navigation);
        logger.debug('Got navigation: ', navigation);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return navigation;
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
  },
};

export default store;
