<template>
  <div
    :class="`checkbox-wrapper ${isDisabled ? 'checkbox-disabled' : ''}`"
    @click="input"
  >
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="isDisabled"
    >
    <label
      :for="id"
    />
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    input() {
      if (!this.isDisabled) {
        this.$emit('update:modelValue', !this.modelValue);
      }
    },
  },
};
</script>
