/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext, Commit, Dispatch } from 'vuex';
import { InterDocumentEntity } from '@/components/entities/types';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import {
  EntityRelationshipSummary,
  EntityRelationshipFilters,
  GetRelatedEntitiesResponse,
  Getters, State,
  EntityRelationshipsPage,
} from './types';
import Api from '../helpers/api';
import { DEFAULT_MAX_PAGE_SIZE } from './constants';
import transformBackendResponse from './helpers';

const initialState = (): State => ({
  ...storeState,
  filters: {},
  orderBy: null,
  data: null,
  maxPageSize: DEFAULT_MAX_PAGE_SIZE,
  offset: 0,
  entity: null,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  relationships: (state: State): EntityRelationshipSummary[] => state.data?.items ?? [],
  pageIndex: (state: State) => (state.offset > 0
    ? (state.offset / state.maxPageSize) : 0),
  totalPages: (state: State) => (state.data
    ? Math.ceil(state.data.totalCount / state.maxPageSize)
    : null),
  totalCount: (state: State) => state.data?.totalCount ?? 0,
  isCurrentPageLastPage: (state: State) => {
    if (!state.data) {
      return false;
    }
    const pageIndex = state.offset > 0 ? (state.offset / state.maxPageSize) : 0;
    const totalPages = Math.ceil(
      state.data.totalCount / state.maxPageSize,
    );
    return totalPages === 0 ? true : pageIndex + 1 === totalPages;
  },
  totalRelationships: (state: State) => state.data?.totalCount ?? 0,
  filters: (state: State) => state.filters,
  offset: (state: State) => state.offset,
  orderBy: (state: State) => state.orderBy,
  maxPageSize: (state: State) => state.maxPageSize,
  entityId: (state: State) => state.entity?.interDocumentEntityId ?? null,
  entity: (state: State) => state.entity,
  entityDisplayNames: (state: State) => state.entity?.fins.map((f) => f.display) ?? [],
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_DATA(state: State, data: EntityRelationshipsPage) {
      state.data = data;
    },
    SET_FILTERS(state: State, filters: EntityRelationshipFilters) {
      state.filters = filters;
    },
    SET_OFFSET(state: State, offset: number) {
      state.offset = Math.floor(
        offset / state.maxPageSize,
      ) * state.maxPageSize;
    },
    SET_ORDER_BY(state: State, orderBy: string) {
      state.orderBy = orderBy;
    },
    SET_ENTITY(state: State, entity: InterDocumentEntity) {
      state.entity = entity;
      logger.debug('entity has been set to ', entity);
    },
    SET_MAX_PAGE_SIZE(state: State, maxPageSize: number) {
      state.maxPageSize = maxPageSize;
    },
    RESET(state: State) {
      logger.debug('Reseting state of relationships store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ commit, dispatch }: {
      commit: Commit, getters: Getters, dispatch: Dispatch
    }, {
      entity, filters = {}, orderBy = null, page = 1, maxPageSize = DEFAULT_MAX_PAGE_SIZE,
    }: {
      entity: InterDocumentEntity,
      filters: EntityRelationshipFilters,
      orderBy: string | null,
      page?: number,
      maxPageSize?: number,
    }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing entity relationships store', entity, filters);
        commit('SET_MAX_PAGE_SIZE', maxPageSize);
        commit('SET_OFFSET', (page - 1) * maxPageSize);
        commit('SET_FILTERS', filters);
        commit('SET_ORDER_BY', orderBy);
        commit('SET_ENTITY', entity);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    // NOTE: Relationships are PAGINATED.
    getRelatedEntities: async (
      { rootGetters }: any,
      {
        entityId, offset, filters, orderBy = null, maxPageSize = DEFAULT_MAX_PAGE_SIZE,
      }: any,
    ): Promise<GetRelatedEntitiesResponse> => {
      logger.debug('Fetching relationships to entity id:', entityId, filters);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get(`entities/${entityId}/related-entities`, {
          offset, ...orderBy && { orderBy }, maxPageSize, ...filters,
        } as any);
    },

    sortRelatedEntities: async (
      { dispatch, commit }: ActionContext<State, any>,
      { orderBy = null }: { orderBy: string | null },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Sorting relationships - ', orderBy);
      try {
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    refreshCurrentPage: async (
      {
        rootGetters, getters, commit, dispatch,
      }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Re-fetching the current page of relationships');
      try {
        // Fetch related entity data
        const response: GetRelatedEntitiesResponse = await dispatch('getRelatedEntities', {
          entityId: getters.entityId,
          offset: getters.offset,
          filters: getters.filters,
          ...getters.orderBy && { orderBy: getters.orderBy },
          maxPageSize: getters.maxPageSize,
        });

        // Fetch entity type data
        await dispatch('entityTypes/lazyInit', {}, { root: true });
        const displayNameByFin: { [key: string]: string } = rootGetters['entityTypes/displayNameByFin'];

        // Combine and transform backend responses
        const transformedResponse = transformBackendResponse({
          entityName: getters.entity?.name ?? '', response, displayNameByFin,
        });

        // Update store
        commit('SET_DATA', transformedResponse);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    filterRelatedEntities: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      filters: EntityRelationshipFilters,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Filtering entities - the offset is set to 0');
      try {
        commit('SET_FILTERS', { ...getters.filters, ...filters });
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to filters relationships', e);
        throw e;
      }
    },

    updateCurrentPageAndMaxPageSize: async (
      { dispatch, commit }: ActionContext<State, any>,
      { page, maxPageSize }: { page: number, maxPageSize: number },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_MAX_PAGE_SIZE', maxPageSize);
      commit('SET_OFFSET', (page - 1) * maxPageSize);
      try {
        await dispatch('refreshCurrentPage');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to update page and maxPageSize for entity relationships', e);
        throw e;
      }
    },

    nextPage: async (
      { getters, dispatch, commit }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const disableNext = getters.isCurrentPageLastPage;
      // A null token is the token of the first page - prevent execution to avoid a loop.
      if (disableNext) {
        logger.debug('The current page is the last page, no update will be made as next page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset + getters.maxPageSize);
      logger.debug('Fetching the next page of relationships - offset has been updated to ', getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    prevPage: async ({ getters, dispatch, commit }: ActionContext<State, any>) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.pageIndex === 0) {
        logger.debug('The current page is the first page, no update will be made as previous page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset - getters.maxPageSize);
      logger.debug('Fetching the previous page of relationships - offset has been updated to ', getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    jumpToPage: async ({ getters, dispatch, commit }: ActionContext<State, any>, pageNumber: number) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const newOffset = getters.maxPageSize * (pageNumber - 1);
      commit('SET_OFFSET', newOffset);
      logger.debug(`Fetching page ${pageNumber} of relationships - offset has been updated to `, getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    deleteRelationships: async (
      {
        rootGetters, getters, dispatch, commit,
      }: ActionContext<State, any>,
      relationshipsIds: string[],
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Deleting entities with the following interdocument ids: ', relationshipsIds);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('interdocumentrelationship/delete', { uuids: relationshipsIds })
        .then(async () => {
          logger.debug(`Entity relationships ${relationshipsIds} deleted successfully`);
          const newOffset = getters.offset - relationshipsIds.length;
          commit('SET_OFFSET', newOffset < 0 ? 0 : newOffset);
          await dispatch('refreshCurrentPage');
        })
        .catch((e) => {
          logger.error('Failed to delete entity relationships: ', relationshipsIds);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
  },
};

export default store;
