import { getMappingFor, getMappingFrom } from './mappings';

const metricMapping = {
  MET_TEXT: {
    backend: 'STRING',
    display: 'Text',
    valueIsNumeric: false,
  },
  MET_DATE: {
    backend: 'DATE',
    display: 'Date',
    valueIsNumeric: false,
  },
  MET_NUMBER: {
    backend: 'NUMERIC',
    display: 'Number',
    valueIsNumeric: true,
  },
  MET_CURRENCY: {
    backend: 'CURRENCY',
    display: 'Currency',
    valueIsNumeric: true,
  },
  MET_PERCENTAGE: {
    backend: 'PERCENTAGE',
    display: 'Percentage',
    valueIsNumeric: true,
  },
  MET_ENTITY: {
    backend: 'ENTITY_NAME',
    display: 'Entity',
    valueIsNumeric: false,
  },
};

const metricMappingFromBackend = getMappingFrom(metricMapping, 'backend');
const metricMappingToBackend = getMappingFor(metricMapping, 'backend');

export {
  metricMapping,
  metricMappingFromBackend,
  metricMappingToBackend,
};
