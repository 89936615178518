<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99986 1.99023C7.18445 1.99023 6.48909 2.51109 6.23139 3.24018C6.11636 3.56563 5.75929 3.73621 5.43384 3.62118C5.10839 3.50615 4.93781 3.14907 5.05284 2.82362C5.48154 1.61072 6.63824 0.740234 7.99986 0.740234C9.36148 0.740234 10.5182 1.61072 10.9469 2.82362C11.0619 3.14907 10.8913 3.50615 10.5659 3.62118C10.2404 3.73621 9.88336 3.56563 9.76833 3.24018C9.51064 2.51109 8.81527 1.99023 7.99986 1.99023Z"
      fill="currentColor"
    />
    <path
      d="M0.291504 4.69857C0.291504 4.35339 0.571326 4.07357 0.916504 4.07357H15.0832C15.4284 4.07357 15.7082 4.35339 15.7082 4.69857C15.7082 5.04375 15.4284 5.32357 15.0832 5.32357H0.916504C0.571326 5.32357 0.291504 5.04375 0.291504 4.69857Z"
      fill="currentColor"
    />
    <path
      d="M2.92907 6.74032C2.90611 6.39591 2.6083 6.13532 2.26388 6.15828C1.91947 6.18124 1.65888 6.47906 1.68184 6.82347L2.06805 12.6166C2.1393 13.6855 2.19685 14.549 2.33182 15.2265C2.47216 15.931 2.71084 16.5194 3.20384 16.9806C3.69683 17.4418 4.29979 17.6408 5.012 17.734C5.69703 17.8236 6.5624 17.8236 7.63372 17.8236H8.36608C9.43741 17.8236 10.3028 17.8236 10.9878 17.734C11.7 17.6408 12.303 17.4418 12.796 16.9806C13.289 16.5194 13.5276 15.931 13.668 15.2265C13.803 14.549 13.8605 13.6855 13.9318 12.6166L14.318 6.82347C14.3409 6.47906 14.0803 6.18124 13.7359 6.15828C13.3915 6.13532 13.0937 6.39591 13.0707 6.74032L12.6874 12.4896C12.6126 13.6128 12.5592 14.3943 12.4421 14.9823C12.3284 15.5527 12.1698 15.8546 11.942 16.0678C11.7141 16.2809 11.4023 16.4191 10.8257 16.4945C10.2312 16.5723 9.44784 16.5736 8.32214 16.5736H7.67767C6.55197 16.5736 5.76863 16.5723 5.17413 16.4945C4.59746 16.4191 4.28566 16.2809 4.05782 16.0678C3.82997 15.8546 3.67136 15.5527 3.55774 14.9823C3.4406 14.3943 3.38724 13.6128 3.31236 12.4896L2.92907 6.74032Z"
      fill="currentColor"
    />
    <path
      d="M5.85439 8.24334C6.19785 8.20899 6.50413 8.45958 6.53847 8.80304L6.95514 12.9697C6.98949 13.3132 6.7389 13.6195 6.39543 13.6538C6.05197 13.6881 5.74569 13.4376 5.71134 13.0941L5.29468 8.92742C5.26033 8.58396 5.51092 8.27768 5.85439 8.24334Z"
      fill="currentColor"
    />
    <path
      d="M10.7051 8.92742C10.7395 8.58396 10.4889 8.27768 10.1454 8.24334C9.80197 8.20899 9.49569 8.45958 9.46134 8.80304L9.04468 12.9697C9.01033 13.3132 9.26092 13.6195 9.60439 13.6538C9.94785 13.6881 10.2541 13.4376 10.2885 13.0941L10.7051 8.92742Z"
      fill="currentColor"
    />
  </svg>
</template>
