import RBush from 'rbush';
import { logger } from '@/store/logger';

const annotationsToRTrees = (annotations) => {
  logger.debug('annotations to R Trees...');

  const rTrees = {};
  Object.entries(annotations).forEach(([pageNum, nodes]) => {
    const pageItems = Object.entries(nodes).map(([nodeId, node]) => ({
      minX: node.l[0],
      minY: node.l[1],
      maxX: node.l[0] + node.l[2],
      maxY: node.l[1] + node.l[3],
      id: nodeId,
    }));
    const pageTree = new RBush();
    pageTree.load(pageItems);

    rTrees[pageNum] = pageTree;
  });

  return rTrees;
};

export default annotationsToRTrees;
