export default () => ([
  {
    id: 2,
    document_type_name: 'am_performance',
    extraction_progress_stage_name: 'DOC_UPLOADED',
    file_name: '2019_q1_yc_i.pdf',
    reporting_date: null,
    fund: null,
    fund_manager: null,
    upload_date: '2021-05-25T11:17:17.620623+00:00',
    uploaded_by: 'John',
    is_verified: false,
    verified_by: null,
  },
  {
    id: 3,
    document_type_name: 'am_performance',
    extraction_progress_stage_name: 'DOC_UPLOADED',
    file_name: '2020_q4_insight_XI.pdf',
    reporting_date: null,
    fund: null,
    fund_manager: null,
    upload_date: '2021-05-25T11:17:17.629868+00:00',
    uploaded_by: 'John',
    is_verified: false,
    verified_by: null,
  },
  {
    id: 4,
    document_type_name: 'am_performance',
    extraction_progress_stage_name: 'EXT_START_DOC_TO_GRAPH',
    file_name: 'Basic SOI Example.pdf',
    reporting_date: null,
    fund: null,
    fund_manager: null,
    upload_date: '2021-05-25T11:17:21.068862+00:00',
    uploaded_by: 'John',
    is_verified: false,
    verified_by: null,
  },
  {
    id: 5,
    document_type_name: 'am_performance',
    extraction_progress_stage_name: 'DOC_UPLOADED',
    file_name: 'georgian_partners Q3.pdf',
    reporting_date: null,
    fund: null,
    fund_manager: null,
    upload_date: '2021-05-25T11:17:21.080170+00:00',
    uploaded_by: 'John',
    is_verified: false,
    verified_by: null,
  },
]);
