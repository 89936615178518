<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_5748_99219)">
      <path
        d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0284 2.26495L6.11844 8.17495C5.89344 8.39995 5.66844 8.84245 5.62344 9.16495L5.30094 11.4224C5.18094 12.2399 5.75844 12.8099 6.57594 12.6974L8.83344 12.3749C9.14844 12.3299 9.59094 12.1049 9.82344 11.8799L15.7334 5.96995C16.7534 4.94995 17.2334 3.76495 15.7334 2.26495C14.2334 0.764945 13.0484 1.24495 12.0284 2.26495Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1836 3.1123C11.4323 3.99541 11.9036 4.79985 12.5523 5.44859C13.201 6.09732 14.0055 6.56861 14.8886 6.8173"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5748_99219">
        <rect
          width="18"
          height="18"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
