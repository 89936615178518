/* eslint-disable */
export default () => ({
  "DOCUMENT_REQUEST": {
    "id": 3,
    "file_name": "cap_account_ilpa.pdf",
    "file_hash": "nothin",
    "mime_type": "application/pdf",
    "s3_key": "1.pdf",
    "archived": false,
    "verified": true,
    "time_created": "2021-06-02 17:18:44.173526+00:00",
    "time_updated": "2021-06-02 18:37:19.089644+00:00",
    "fk_versioned_document_type_id": 1,
    "fk_extraction_progress_id": 1,
    "fk_user_id": 3,
    "fk_verified_by_id": 3
  },
  "DOCUMENT_TYPE": {
    "type": "DOCUMENT_TYPE",
    "id": 1,
    "name": "am_capital_account",
    "display_name": "Capital Account",
    "available": true,
    "fk_document_category_id": 2,
    "fk_user_id": 1,
    "time_created": "2021-06-02 16:02:46.848043+00:00",
    "time_updated": "None"
  },
  "GROUPING": {
    "allow_repeatable": false,
    "group_identifier": null,
    "index_in_repeating_group": null,
    "group_display": "",
    "groups": [
      {
        "allow_repeatable": false,
        "group_identifier": null,
        "index_in_repeating_group": null,
        "group_display": "Fund",
        "groups": [],
        "metrics": [
          7,
          22,
          0,
          18,
          6,
          3,
          8,
          9,
          20,
          13,
          15,
          17,
          24,
          11
        ]
      },
      {
        "allow_repeatable": false,
        "group_identifier": null,
        "index_in_repeating_group": null,
        "group_display": "Limited Partner",
        "groups": [],
        "metrics": [
          4,
          5,
          2,
          1,
          21,
          19,
          12,
          14,
          16,
          23,
          10
        ]
      }
    ],
    "metrics": []
  },
  "METRICS_AND_DATAPOINTS": [
    {
      "METRIC": {
        "type": "METRIC",
        "id": 3,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 3,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "DATE",
        "metric_identifier_value": "reporting_date",
        "metric_display": "Reporting Date"
      },
      "DATAPOINTS": [
        {
          "id": 121,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Oct 15 – Dec 15)",
          "node_id": 474,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        },
        {
          "id": 122,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Feb 07 - Dec 15)",
          "node_id": 488,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        },
        {
          "id": 123,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Oct 15 – Dec 15)",
          "node_id": 494,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        },
        {
          "id": 124,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Feb 07 - Dec 15)",
          "node_id": 509,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        },
        {
          "id": 125,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Oct 15 – Dec 15)",
          "node_id": 516,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        },
        {
          "id": 126,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Jan 15 – Dec 15)",
          "node_id": 523,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        },
        {
          "id": 127,
          "effectors": {},
          "confidence": 0.9,
          "fk_metric_id": 3,
          "value_in_document": "(Feb 07 - Dec 15)",
          "node_id": 530,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "2015-12-31 00:00:00"
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 18,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 18,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:period_contributions",
        "metric_display": "Contributions"
      },
      "DATAPOINTS": [
        {
          "id": 128,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 18,
          "value_in_document": "0",
          "node_id": 324,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 0
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 17,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 17,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:net_asset_value",
        "metric_display": "Net Asset Value"
      },
      "DATAPOINTS": [
        {
          "id": 129,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 17,
          "value_in_document": "$45,673,600",
          "node_id": 425,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 45673600
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 6,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 6,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:total_net_asset_value",
        "metric_display": "Fund NAV"
      },
      "DATAPOINTS": [
        {
          "id": 130,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 6,
          "value_in_document": "$2,538,519,350",
          "node_id": 428,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 2538519350
        },
        {
          "id": 131,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 6,
          "value_in_document": "$2,538,519,350",
          "node_id": 453,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 2538519350
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 15,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 15,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:total_commitment",
        "metric_display": "Total Commitment"
      },
      "DATAPOINTS": [
        {
          "id": 132,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 15,
          "value_in_document": "$50,000,000",
          "node_id": 460,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 50000000
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 16,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 16,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:unfunded_commitment",
        "metric_display": "Unfunded Commitment"
      },
      "DATAPOINTS": [
        {
          "id": 133,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 16,
          "value_in_document": "$18,500,000",
          "node_id": 492,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 18500000
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 5,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 5,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:total_unfunded_commitments",
        "metric_display": "Fund Unfunded Commitment"
      },
      "DATAPOINTS": [
        {
          "id": 134,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 5,
          "value_in_document": "$926,387,500",
          "node_id": 514,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 926387500
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 1,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 1,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "STRING",
        "metric_identifier_value": "am:fund:general:fund_name",
        "metric_display": "Fund Name"
      },
      "DATAPOINTS": [
        {
          "id": 135,
          "effectors": {},
          "confidence": 1,
          "fk_metric_id": 1,
          "value_in_document": "Best Practices Fund II, L.P.",
          "node_id": 574,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": "Best Practices Fund II, L.P."
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 7,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 7,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:period_contributions",
        "metric_display": "Fund Contributions (for the period)"
      },
      "DATAPOINTS": [
        {
          "id": 136,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 7,
          "value_in_document": "0",
          "node_id": 327,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 0
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 8,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 8,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:period_distributions",
        "metric_display": "Fund Distributions (for the period)"
      },
      "DATAPOINTS": [
        {
          "id": 137,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 8,
          "value_in_document": "62,593,750",
          "node_id": 337,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 62593750
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 25,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 25,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:management_fees",
        "metric_display": "Management Fees"
      },
      "DATAPOINTS": [
        {
          "id": 138,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 25,
          "value_in_document": "(152,900)",
          "node_id": 549,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": -152900
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 14,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 14,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:management_fees",
        "metric_display": "Fund Management Fees & Partnership Expenses"
      },
      "DATAPOINTS": [
        {
          "id": 139,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 14,
          "value_in_document": "(7,563,150)",
          "node_id": 564,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": -7563150
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 21,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 21,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:placement_fees",
        "metric_display": "Placement Fees"
      },
      "DATAPOINTS": [
        {
          "id": 140,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 21,
          "value_in_document": "0",
          "node_id": 393,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 0
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 10,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 10,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:total_placement_fees",
        "metric_display": "Fund Placement Fees"
      },
      "DATAPOINTS": [
        {
          "id": 141,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 10,
          "value_in_document": "0",
          "node_id": 396,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 0
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 22,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 22,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:realised_portfolio_gain_loss",
        "metric_display": "Realised Gain/Loss"
      },
      "DATAPOINTS": [
        {
          "id": 142,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 22,
          "value_in_document": "1,000,000",
          "node_id": 403,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 1000000
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 11,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 11,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:total_realised_gain_loss",
        "metric_display": "Fund Realised Gain / Loss"
      },
      "DATAPOINTS": [
        {
          "id": 143,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 11,
          "value_in_document": "50,075,000",
          "node_id": 406,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 50075000
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 23,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 23,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:change_unrealised_gain_loss",
        "metric_display": "Change in Unrealised Gain/Loss"
      },
      "DATAPOINTS": [
        {
          "id": 144,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 23,
          "value_in_document": "1,000,000",
          "node_id": 413,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 1000000
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 12,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 12,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:change_unrealised_gain_loss",
        "metric_display": "Fund Change in Unrealised Gain / Loss"
      },
      "DATAPOINTS": [
        {
          "id": 145,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 12,
          "value_in_document": "62,593,750",
          "node_id": 416,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 62593750
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 4,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 4,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:total_commitments",
        "metric_display": "Total Commitments"
      },
      "DATAPOINTS": [
        {
          "id": 146,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 4,
          "value_in_document": "$2,503,750,000",
          "node_id": 462,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 2503750000
        },
        {
          "id": 147,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 4,
          "value_in_document": "$2,503,750,000",
          "node_id": 513,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": 2503750000
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 20,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 20,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:total_net_operating_income_expense",
        "metric_display": "Net Operating Income/Expense"
      },
      "DATAPOINTS": [
        {
          "id": 148,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 20,
          "value_in_document": "(143,400)",
          "node_id": 477,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": -143400
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 9,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 9,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:total_net_operating_income_expense",
        "metric_display": "Fund Net Operating Income / Expense"
      },
      "DATAPOINTS": [
        {
          "id": 149,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0.9,
          "fk_metric_id": 9,
          "value_in_document": "(7,087,438)",
          "node_id": 496,
          "page": 1,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": -7087438
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 19,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 19,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:period_distribtutions",
        "metric_display": "Distributions"
      },
      "DATAPOINTS": [
        {
          "id": 150,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0,
          "fk_metric_id": 19,
          "value_in_document": null,
          "node_id": null,
          "page": null,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": null
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 2,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 2,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "STRING",
        "metric_identifier_value": "am:fund:general:fund_manager",
        "metric_display": "Fund Manager"
      },
      "DATAPOINTS": [
        {
          "id": 151,
          "effectors": {},
          "confidence": 0,
          "fk_metric_id": 2,
          "value_in_document": null,
          "node_id": null,
          "page": null,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": null
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 24,
        "validated": false,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 24,
        "fk_document_request_id": 1,
        "fk_datapoint_id": null,
        "fk_metric_group_id": 3,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "None",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:lp:performance:recallable_distributions",
        "metric_display": "Recallable Distributions"
      },
      "DATAPOINTS": [
        {
          "id": 152,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 0,
          "fk_metric_id": 24,
          "value_in_document": null,
          "node_id": null,
          "page": null,
          "fk_modified_by_id": 1,
          "time_created": "2021-06-02 17:20:43.322684+00:00",
          "time_updated": null,
          "value": null
        }
      ]
    },
    {
      "METRIC": {
        "type": "METRIC",
        "id": 13,
        "validated": true,
        "required": true,
        "available": true,
        "order_position": 0,
        "fk_client_defined_metric_id": 13,
        "fk_document_request_id": 1,
        "fk_datapoint_id": 178,
        "fk_metric_group_id": 2,
        "time_created": "2021-06-02 17:18:44.173526+00:00",
        "time_updated": "2021-06-02 18:37:18.549043+00:00",
        "metric_identifier_type": "CURRENCY",
        "metric_identifier_value": "am:fund:performance:recallable_distributions",
        "metric_display": "Fund Recallable Distributions"
      },
      "DATAPOINTS": [
        {
          "id": 178,
          "effectors": {
            "currency": "USD"
          },
          "confidence": 1,
          "fk_metric_id": 13,
          "value_in_document": "0 0 0 0",
          "node_id": 468,
          "page": 1,
          "fk_modified_by_id": 3,
          "time_created": "2021-06-02 18:37:18.549043+00:00",
          "time_updated": null,
          "value": 0
        }
      ]
    }
  ],
  "STAGE": "ExtractionStage.EXT_DONE"
});
