import { logger } from '@/store/logger';
import ValidationException from '../exceptions/ValidationException';
import isSet from '../../isSet';
import dateFormats from '../../mapping/dateFormats';

const getDefaultSettings = () => ({
  defaultLanguage: 'en-GB',
});

const backendToSettings = (settings) => {
  const s = settings;
  logger.debug('transforming settings:', s);
  if (!isSet(s)) {
    throw new ValidationException('Failed to retrieve user settings');
  }

  if (!isSet(s.default_currency)) {
    throw new ValidationException('Default currency not provided');
  }
  if (!isSet(s.default_language)) {
    throw new ValidationException('Default language not provided');
  }
  if (!isSet(s.is_trial)) {
    throw new ValidationException('Trial usage not provided');
  }
  if (!isSet(s.limited_uploads)) {
    throw new ValidationException('Limited uploads not provided');
  }
  if (!(s.default_language in dateFormats)) {
    throw new ValidationException('Invalid date format');
  }
  if (!isSet(s.user)) {
    throw new ValidationException('User not provided');
  }

  return {
    defaultCurrency: s.default_currency,
    defaultLanguage: s.default_language,
    isTrial: s.is_trial,
    limitedUploads: s.limited_uploads,
    user: s.user,
  };
};

export { backendToSettings, getDefaultSettings };
