/**
 * --- Information ---
 *
 * The backend sends us a permission level for the logged in user.
 * Given this permission level, we can work out whether or not a user
 * has a permission using bitwise addition.
 *
 * --- Usage ---
 *
 * We need to make sure that we have cached the user settings. The
 * 'checkPermission' function in the store 'localisation.js' does it
 * automatically.
 *
 * Use in vue component following this example:
 *     data() {
 *       return {
 *         canSeeUsersSettings: false,
 *       };
 *     ...
 *     async created() {
 *       this.canSeeUsersSettings = await (this.checkPermission('CAN_ADD_USERS'));
 *     },
 *     ...
 *     methods: {
 *       ...mapActions({
 *         checkPermission: 'localisation/checkPermission',
 *       }),
 */

const permissionGroups = {
  Users: [
    {
      display: 'List / View',
      permission: 'CAN_LIST_USERS',
      level: 4,
      info: 'User has permission to view all system users',
    },
    {
      display: 'Create',
      permission: 'CAN_ADD_USERS',
      info: 'User has permission to create new users',
      level: 1,
    },
    {
      display: 'Update',
      permission: 'CAN_UPDATE_USERS',
      info: 'User has permission to update permissions of other users',
      level: 8,
    },
    {
      display: 'Activate / Deactivate',
      permission: 'CAN_UPDATE_USER_ACCOUNT_STATUS',
      info: 'User has permission to Activate/Deactivate other users',
      level: 16,
    },
  ],
  Documents: [
    {
      display: 'Verify',
      permission: 'CAN_VERIFY_DOCUMENTS',
      info: 'User has permission to verify extracted data',
      level: 32,
    },
    {
      display: 'Edit',
      permission: 'CAN_EDIT_DOCUMENTS',
      info: 'User has permission to edit extracted data',
      level: 1024,
    },
    {
      display: 'Re-Process',
      permission: 'CAN_REQUEUE_DOCUMENTS',
      info: 'User has permission to re-process documents',
      level: 2048,
    },
    {
      display: 'Delete',
      permission: 'CAN_DELETE_DOCUMENTS',
      info: 'User has permission to delete documents',
      level: 128,
    },
    {
      display: 'Train',
      permission: 'CAN_RETRAIN_MODELS',
      info: 'User is a trusted SME whose verified documents should be used to directly influence platform training',
      level: 512,
    },
    {
      display: 'Approve',
      permission: 'CAN_APPROVE_DOCUMENTS',
      info: 'User has permission to Approve Documents',
      level: 4096,
    },
  ],
  Entities: [
    {
      display: 'Create & Update',
      permission: 'CAN_CREATE_UPDATE_ENTITIES',
      info: 'User has permission to create and update entities',
      level: 2,
    },
    {
      display: 'Delete',
      permission: 'CAN_DELETE_ENTITIES',
      info: 'User has permission to delete existing entities',
      level: 64,
    },
  ],
  Metrics: [
    {
      display: 'Create',
      permission: 'CAN_CREATE_METRIC_IDENTIFIERS',
      info: 'User has permission to create metrics',
      level: 16384,
    },
    {
      display: 'Update',
      permission: 'CAN_UPDATE_METRIC_IDENTIFIERS',
      info: 'User has permission to update metrics',
      level: 32768,
    },
    {
      display: 'Delete',
      permission: 'CAN_DELETE_METRIC_IDENTIFIERS',
      info: 'User has permission to delete metrics',
      level: 256,
    },
  ],
  Schemas: [
    {
      display: 'Create',
      permission: 'CAN_CREATE_DOCUMENT_SCHEMAS',
      info: 'User has permission to create a schema',
      level: 65536,
    },
    {
      display: 'Update',
      permission: 'CAN_UPDATE_DOCUMENT_SCHEMAS',
      info: 'User has permission to update a schema',
      level: 131072,
    },
    {
      display: 'Delete',
      permission: 'CAN_DELETE_DOCUMENT_SCHEMAS',
      info: 'User has permission to delete a schema',
      level: 262144,
    },
    {
      display: 'Assign',
      permission: 'CAN_CREATE_VERSIONED_DOCUMENT_TYPES',
      info: 'User has permission to assign a schema to a document type',
      level: 524288,
    },
    {
      display: 'Publish',
      permission: 'CAN_PUBLISH_DOCUMENT_SCHEMAS',
      info: 'User has permission to publish a schema',
      level: 1048576,
    },
  ],
};

const accountStatus = {
  1: 'Creating',
  2: 'Active',
  3: 'Deactivated',
};

/**
 * Returns dict of { permissionName: permissionLevel }.
 */
const getPermissionsFromGroups = (groups) => {
  const result = {};
  Object.values(groups).forEach(
    (group) => {
      group.forEach((item) => {
        const { permission } = item;
        result[permission] = item.level;
      });
    },
  );
  return result;
};

/**
 * Returns dict of { permission: permissionLevel }.
 */
const getPermissionLevels = () => {
  const allPermissionObjects = Object.values(permissionGroups).flat(1);
  const permissionLevelsArr = allPermissionObjects.map((item) => ({ [item.permission]: item.level }));
  return Object.assign({}, ...permissionLevelsArr);
};

// eslint-disable-next-line no-bitwise
const bitwisePermissionCheck = (permission, userPermissionLevel) => (userPermissionLevel & permission) === permission;

/**
 * Check if a user has a certain permission.
 * @param {String} permission permission to check.
 * @param {Integer} userPermissionLevel current permission level of the user.
 * @returns {Boolean} true if user has the given permission
 */
const userHasPermission = (permission, userPermissionLevel) => {
  const permissions = getPermissionsFromGroups(permissionGroups);
  return bitwisePermissionCheck(permissions[permission], userPermissionLevel);
};

const getAccountStatusDisplay = (status) => accountStatus[status];

export {
  permissionGroups,
  userHasPermission,
  bitwisePermissionCheck,
  getAccountStatusDisplay,
  getPermissionsFromGroups,
  getPermissionLevels,
};
