import { z } from 'zod';
import { validateEntityName } from '@/store/helpers/request/validators/validateMetrics';
import ValidationException from '@/store/helpers/request/exceptions/ValidationException';
import { EntityRelationship, EntityType, MetricValidator } from '../types';

export type EntityMetadataFormInputSchema = {
  name: string,
  clientIdentifier: string,
  akas: string[],
  types: EntityType[],
};

export const entityMetadataFormValidationSchema = (validators: MetricValidator[]) => z.object({
  name: z.string({
    required_error: 'Entity name is required',
  }).superRefine((val, ctx) => {
    try {
      validateEntityName(val, validators);
    } catch (error) {
      if (error instanceof ValidationException) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          // Error messages set in backend - changing this common prefix for user experience
          message: error.message.replace('Validation failed:', 'Invalid Entity Name: '),
        });
      }
    }
  }),
  clientIdentifier: z.nullable(z.string()),
  akas: z.array(z.string()).superRefine((val, ctx) => {
    if (val.length !== new Set(val).size) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'No duplicates allowed.',
      });
    }

    val.forEach((aka) => {
      try {
        validateEntityName(aka, validators);
      } catch (error) {
        if (error instanceof ValidationException) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error.message
              .replace('Validation failed:', 'Invalid AKA(s): ')
              .replace('entity', 'AKA'), // Backend error messages may refer to akas as entity names
          });
        }
      }
    });
  }),
  types: z.array(z.any()).nonempty({
    message: 'Must select at least 1 entity type',
  }),
});

export type EntityRelationshipsFormInputSchema = EntityRelationship[];
