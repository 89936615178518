export default () => ({
  colours: [
    '#FB4E4E',
    '#AFCDD0',
  ],
  edges: [
    {
      source: 0,
      target: 1,
      value: 2,
    },
    {
      source: 1,
      target: 2,
      value: 1,
    },
    {
      source: 1,
      target: 3,
      value: 1,
    },
    {
      source: 1,
      target: 4,
      value: 1,
    },
    {
      source: 1,
      target: 5,
      value: 1,
    },
    {
      source: 1,
      target: 6,
      value: 1,
    },
  ],
  nodes: [
    {
      id: 0,
      size: 4,
      label: 'Treadstone LLC',
      info: ['One fund contained potential references to COVID-19 related activity, sentiment is aggregate across all funds.'],
      colourProportions: [
        0.34,
        0.54,
      ],
    },
    {
      id: 1,
      size: 3,
      label: 'Treadstone Equity Partners VI, LP.',
      info: ['Five companies were reported with potential references to COVID, overall sentiment is aggregated across all companies',
        'Company with most positive COVID related reference was Jar Tech Ltd',
        'Company with most negative COVID related reference was Hagrid Dental'],
      colourProportions: [
        0.34,
        0.54,
      ],
    },
    {
      id: 2,
      size: 1,
      label: 'Petronus Limited',
      info: ["Most positive: 30 June 2021 [Page 6] - 'Petronus Limited has performed particularly well through this period due to it's "
      + "expansion to online.'",
      "Most negative: 30 June 2021 [Page 6] - 'The pharma industry has been impacted by Covid, with increased demand and challenges raised due "
        + "to the global supply chain.'"],
      colourProportions: [
        0.2,
        0.5,
      ],
    },
    {
      id: 3,
      size: 1,
      label: 'Jar Tech Ltd',
      info: ["Most positive: 30 June 2021 [Page 7] - 'Online sales have grown significantly through the COVID pandemic and Jar Tech Limited has "
      + "been well placed to take advantage of this. '"],
      colourProportions: [
        0.0,
        0.8,
      ],
    },
    {
      id: 4,
      size: 1,
      label: 'Hogwarz Express',
      info: ["Most positive: 30 June 2021 [Page 8] - 'HGX expects a sales increase from 2022 onwards as vaccination and other factors continue "
      + "to allow high street and retail venue re-opening.'", "Most negative: 30 June 2021 [Page 8] - 'Hogwarz Express has struggled during the "
      + '21/22 FY through the COVID period due to a reduction in the availability of venues that have been able to support the adoption of VR '
      + "experiences.'"],
      colourProportions: [
        0.8,
        0.2,
      ],
    },
    {
      id: 5,
      size: 1,
      label: 'Hagrid Dental',
      info: ["Most positive: 30 June 2021 [Page 9] - 'HGX have innovated through this period to provide supporting dental healthcare for COVID "
      + "patients in outdoor locations, reducing the risk of infection and has seen revenues increase by 8% overall.'", 'Most negative: 30 June '
      + "2021 [Page 9] - 'Hagrid Dental has been marginally hit by the COVID pandemic with a reduction in the ability to provide workplace services "
      + "in line with the reduction of employees heading to a central office location.'"],
      colourProportions: [
        0.5,
        0.4,
      ],
    },
    {
      id: 6,
      size: 1,
      label: 'SnapeTik',
      info: ["Most positive: 30 June 2021 [Page 10] - 'Sales have increased dramatically for SnapeTik during the 20-21 FY.'"],
      colourProportions: [
        0.2,
        0.8,
      ],
    },
  ],
});
