import { z } from 'zod';

export type ChangePasswordFormSchema = {
  name: string,
  email: string
};
const containsNumberAndLetter = /^(?=.*[0-9])(?=.*[a-zA-Z])/;
export const changePasswordFormValidationSchema = z.object({
  previousPassword: z.string({
    required_error: 'Required',
  }).min(1, 'Required'),
  proposedPassword: z.string({
    required_error: 'Required',
  })
    .regex(containsNumberAndLetter, 'Must contain at least one letter and a number')
    .max(256, 'Must be less than 256 characters long')
    .min(8, 'Must be at least 8 characters long'),
  proposedPasswordConfirm: z.string({
    required_error: 'Required',
  }).min(1, 'Required'),
}).refine((data) => data.proposedPassword === data.proposedPasswordConfirm, {
  message: "Passwords don't match",
  path: ['proposedPasswordConfirm'],
});
