<template>
  <modal
    :show="show"
    :title="deactivateOption ? 'Deactivate user' : 'Activate user'"
    :custom-classes="['modal-bg-side-nav']"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <p class="">
        Are you sure you want to {{ deactivateOption ? 'deactivate' : 're-activate' }} this user (<b> {{ selectedUser.name }} </b>)?
      </p>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_PRIMARY"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_NEG"
          message="CONFIRM"
          :custom-classes="{'top-nav-item': true}"
          @onClick="confirm"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  components: {
    ActionButton,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: () => ({
        account_status: 1,
        name: '',
      }),
    },
  },
  emits: ['close', 'confirm'],
  computed: {
    deactivateOption() {
      return this.selectedUser.account_status === 2;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
