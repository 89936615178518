import { logger } from '@/store/logger';

const allowedStates = {
  IS_BLANK: 'is-blank',
  IS_LOADING: 'is-loading',
  IS_READY: 'is-ready',
  IS_ERRORING: 'is-erroring',
  IS_PENDING: 'is-pending',
};

/**
 * This function is being phased out
 *
 * @param state
 * @param commit
 * @param stateResourceName     Key of resource state
 * @param fetchActionPromise    Promise that fetches resource
 * @returns {Promise<void>}
 */
const lazyGetAction = async ({ state, commit }, { stateResourceName, fetchActionPromise }) => {
  commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
  logger.debug(`ACTION: init ${stateResourceName} with action ${fetchActionPromise}`);
  if (Object.keys(state[stateResourceName]).length !== 0) {
    logger.debug('Store already initialized:', state[stateResourceName]);
    commit('SET_STORE_STATUS', allowedStates.IS_READY);
    return Promise.resolve(state[stateResourceName]);
  }
  logger.debug(`Fetching ${stateResourceName}:`);
  commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
  const fetchedDocuments = await fetchActionPromise;
  commit('SET_STORE_STATUS', allowedStates.IS_READY);
  logger.debug('fetched:', fetchedDocuments);

  return fetchedDocuments;
};

const storeStateActions = {
  lazyInit: lazyGetAction,
};

const storeState = {
  storeStatus: allowedStates.IS_BLANK,
};

const storeStateGetters = {
  storeStatus: (state) => state.storeStatus,
  isReady: (state) => state.storeStatus === allowedStates.IS_READY,
};

const storeStateMutations = {
  SET_STORE_STATUS: (state, status) => {
    state.storeStatus = status;
  },
  SET_PROP: (state, { key, value }) => {
    state[key] = value;
  },
};

export {
  allowedStates, storeState, storeStateGetters, storeStateMutations, storeStateActions, lazyGetAction,
};
