import { logger } from '@/store/logger';
import { currencies } from '../mapping/currencies';
import numberToCurrencyString from '../numberToCurrencyString';

const currencyDpToExportValue = (dp, toNumberConfig, numToCurrencyOverrides) => {
  if (!(dp.effectors.currency in currencies)) {
    return `[UNKNOWN] ${dp.value}`;
  }

  const curObj = currencies[dp.effectors.currency];
  const numToCurrencyConfig = ({
    currencySymbol: curObj.displayInline,
    currencyPosition: curObj.position,
    countryShortcode: curObj.displayCountry,
  });
  Object.assign(numToCurrencyConfig, numToCurrencyOverrides);
  logger.debug('currencyDpToExportValue:', dp.value, numToCurrencyConfig, toNumberConfig);

  return numberToCurrencyString(dp.value, numToCurrencyConfig, toNumberConfig);
};
const dateComponentsToFormattedDMY = (dd, mm, yyyy, dateFormat) => {
  if (dateFormat.format === 'MM/DD/YYYY') {
    return `${mm}/${dd}/${yyyy}`;
  }
  return `${dd}/${mm}/${yyyy}`;
};
const dateDpToExportValue = (dp, dateFormat) => {
  const exportDate = dp.value;
  const ds = exportDate.split('-');
  if (ds.length > 2) {
    const dd = ds[2];
    const mm = ds[1];
    const yyyy = ds[0];

    return dateComponentsToFormattedDMY(dd, mm, yyyy, dateFormat);
  }
  return '';
};
const exportNumericValue = (dp, metricDataType, toNumberConfig, numToCurrencyOverrides, dateFormat) => {
  if (dp.value === null) {
    return '';
  }
  if ((dp.value === '')) {
    return '';
  }
  if (metricDataType === 'DATE') {
    return dateDpToExportValue(dp, dateFormat);
  }
  if (metricDataType === 'PERCENTAGE') {
    return `${dp.value}%`;
  }
  if (!('currency' in dp.effectors)) {
    return dp.value;
  }
  logger.debug('export:', dp, toNumberConfig, numToCurrencyOverrides);
  return currencyDpToExportValue(dp, toNumberConfig, numToCurrencyOverrides);
};

// Used for display values on verifyDocument modal
const getVerifyExportValueFromDp = (dp, metricDataType, dateFormat) => {
  const toNumberConfig = {};
  const numToCurrencyOverrides = { countryShortcode: '' };

  return exportNumericValue(dp, metricDataType, toNumberConfig, numToCurrencyOverrides, dateFormat);
};

// Used for exporting values to a csv
const getExportValueFromDp = (dp, metricDataType, dateFormat) => {
  const toNumberConfig = { thousandSeparator: '' };
  const numToCurrencyOverrides = {};

  return exportNumericValue(dp, metricDataType, toNumberConfig, numToCurrencyOverrides, dateFormat);
};

export { getExportValueFromDp, getVerifyExportValueFromDp };
