<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.09326 4.19149C6.83118 4.41613 6.80083 4.81069 7.02547 5.07277L11.6768 10.4994L7.02547 15.926C6.80083 16.188 6.83118 16.5826 7.09326 16.8072C7.35533 17.0319 7.7499 17.0015 7.97454 16.7394L12.9745 10.9061C13.1752 10.672 13.1752 10.3267 12.9745 10.0926L7.97454 4.25928C7.7499 3.9972 7.35533 3.96685 7.09326 4.19149Z"
      fill="currentColor"
    />
  </svg>
</template>
