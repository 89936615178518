<template>
  <img
    class="freyda-logo-loader"
    :class="customClasses"
    alt="Content is loading"
    src="@/assets/freyda_logo_loading.svg"
  >
</template>
<script>
export default {
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
