<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.59323 7.02546C9.82728 6.82485 10.1727 6.82485 10.4067 7.02546L16.24 12.0255C16.5021 12.2501 16.5325 12.6447 16.3078 12.9067C16.0832 13.1688 15.6886 13.1992 15.4266 12.9745L9.99997 8.32317L4.57338 12.9745C4.3113 13.1992 3.91674 13.1688 3.6921 12.9067C3.46746 12.6447 3.49781 12.2501 3.75989 12.0255L9.59323 7.02546Z"
      fill="currentColor"
    />
  </svg>
</template>
