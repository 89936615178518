import { format, parseISO, parse } from 'date-fns';
import { logger } from '@/store/logger';
import { metricMapping, metricMappingFromBackend, metricMappingToBackend } from '../../mapping/metrics';
import ValidationException from '../exceptions/ValidationException';
import isSet from '../../isSet';

const parseValue = (value, metricDataType) => {
  if (metricMapping[metricDataType].valueIsNumeric) {
    return isSet(value) ? Number(value) : '';
  } if (metricDataType === 'MET_DATE') {
    return isSet(value) ? format(parseISO(value), 'dd/MM/yyyy') : null;
  }

  return value;
};

const sanitiseDatapoint = (rawDatapoint, metricDataType, dpId) => {
  const dp = rawDatapoint;

  // This will need to change once we store who alters datapoints
  if (dp.fk_modified_by_id === undefined) {
    logger.error(`Datapoint ${dp} fk_modified_by_id is undefined`);
  }
  const userModified = dp.fk_modified_by_id !== 1;
  const value = parseValue(dp.value, metricDataType);

  return {
    confidence: dp.confidence,
    dpId,
    effectors: dp.effectors,
    fk_metric_id: dp.fk_metric_id,
    id: dp.id,
    node_id: dp.node_id,
    page: dp.page,
    text: dp.value_in_document,
    userModified,
    value,
  };
};

const backendToDatapoints = (datapoints, metricDataType) => datapoints.reduceRight((acc, datapoint, idx) => {
  const transformed = sanitiseDatapoint(datapoint, metricDataType, idx);
  acc.unshift(transformed);

  return acc;
}, []);

const backendToMetric = (backendMetricHolder, index) => {
  const backendMetric = backendMetricHolder.METRIC;
  const backendDatapoints = backendMetricHolder.DATAPOINTS;

  if (!(backendMetric.metric_identifier_type in metricMappingFromBackend)) {
    throw new ValidationException(`Unrecognised metric data type ${backendMetric.metric_identifier_type}`);
  }
  const metricDataType = metricMappingFromBackend[backendMetric.metric_identifier_type];
  if (backendDatapoints.length === 0) {
    throw new ValidationException(`Metric has no datapoints ${backendMetric}`);
  }
  const datapoints = backendToDatapoints(backendDatapoints, metricDataType);
  if (index === 101) {
    logger.debug('datapoints for 101:', datapoints);

    let value;
    if (metricMapping[metricDataType].valueIsNumeric) {
      value = isSet(backendDatapoints[0].value) ? Number(backendDatapoints[0].value) : '';
    } else if (metricDataType === 'MET_DATE') {
      value = isSet(backendDatapoints[0].value) ? format(parseISO(backendDatapoints[0].value), 'dd/MM/yyyy') : null;
    }
    logger.debug('datapoints for 101 value:', value);
  }

  return {
    id: backendMetric.id,
    index,
    dataType: metricDataType,
    verified: backendMetric.validated,
    editedSinceSave: false,
    displayLabel: backendMetric.metric_display,
    hasBackendValueSaved: datapoints.length,
    metric: backendMetric.metric_identifier_value,
    datapoints,
    selected: false,
  };
};

const metricToBackend = (clientSideMetric) => {
  const metric = clientSideMetric;
  const metricTypeIsDate = metric.dataType === 'MET_DATE';
  if (metric.editedSinceSave) {
    if (!metric.datapoints.length) {
      throw new ValidationException('Metric has been edited since save, but without datapoints');
    }

    const dpForBackend = metric.datapoints[0];
    if (metricTypeIsDate) {
      logger.debug('parsing date:', dpForBackend.value);
      if (dpForBackend.value !== null) {
        dpForBackend.value = format(parse(dpForBackend.value, 'dd/MM/yyyy', new Date()), 'yyyy/MM/dd');
      }
    }
    if (metricMapping[metric.dataType].valueIsNumeric) {
      dpForBackend.value = dpForBackend.value === '' ? null : dpForBackend.value;
    }
    dpForBackend.valueInDocument = dpForBackend.text;
    metric.datapoints = [dpForBackend];
  } else {
    metric.datapoints = [];
  }

  metric.dataType = metricMappingToBackend[metric.dataType];
  metric.fin = metric.metric;

  return metric;
};

export { backendToMetric, metricToBackend };
