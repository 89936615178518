function ValidationException(message) {
  const error = Error.call(this, message);

  this.name = 'ValidationException';
  this.message = error.message;
  this.stack = error.stack;
}

ValidationException.prototype = Object.create(Error.prototype);
ValidationException.prototype.constructor = ValidationException;

export default ValidationException;
