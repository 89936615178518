<template>
  <aside>
    <div
      class="user-role"
    >
      <p>ROLE</p>
      <user-role-selection-dropdown
        :is-disabled="!canUpdateUser"
        :selected-role="selectedUserRoleValue"
        @update-selected-role="onUpdateUserRoleValue"
      />
    </div>
    <div class="user-status">
      <p>STATUS</p>
      <div class="status-info">
        <p>{{ getAccountStatusDisplay(user.account_status) }}</p>
        <action-button
          v-if="canDeactivateUser"
          button-display="BTN_SECONDARY"
          :message="deactivateOption ? 'Deactivate' : 'Activate'"
          @onClick="deactivateUser"
        />
      </div>
    </div>
    <ul class="permission-groups-container">
      <li
        v-for="(group, groupName) in permissionGroups"
        :key="groupName"
        class="permission-group"
      >
        <hr>
        <p class="permission-groups-header">
          {{ groupName }}
        </p>
        <ul class="permission-list-container">
          <li
            v-for="permission in group"
            :key="permission.permission"
            class="permissions-container"
          >
            <checkbox-input
              :id="`permission-${permission.permission}`"
              :is-disabled="!canUpdateUser"
              :model-value="bitwisePermissionCheck(permission.level, updatedUser.permissions)"
              @update:modelValue="onUpdatePermission(permission.level)"
            />
            <p
              :class="{
                'permission': true,
              }"
            >
              {{ permission.display }}
              <span>
                <info-icon
                  v-if="permission.info"
                  v-tooltip="permission.info"
                  :style="{display: 'flex'}"
                />
              </span>
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <div class="action-buttons side-panel-action-buttons">
      <action-button
        message="SAVE"
        button-display="BTN_PRIMARY_FILLED"
        :style="{ 'width': '120px' }"
        :is-disabled="!userUpdate"
        @onClick="updateUser"
      />
      <action-button
        message="CANCEL"
        button-display="BTN_PRIMARY"
        :style="{ 'width': '120px' }"
        @click="$emit('cancel')"
      />
    </div>
  </aside>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  permissionGroups, bitwisePermissionCheck, getAccountStatusDisplay, getPermissionLevels,
} from '@/store/helpers/mapping/permissions';
import { userRole, ROLES } from '@/store/helpers/mapping/roles';
import CheckboxInput from '@/components/forms/CheckboxInput.vue';
import clonedeep from 'lodash.clonedeep';
import ActionButton from '@/components/general/buttons/ActionButton.vue';
import { InfoIcon } from '@zhuowenli/vue-feather-icons';
import UserRoleSelectionDropdown from './UserRoleSelectionDropdown.vue';

export default {
  components: {
    CheckboxInput,
    ActionButton,
    UserRoleSelectionDropdown,
    InfoIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'updateUser',
    'deactivateUser',
    'cancel',
  ],
  data() {
    return {
      permissionGroups,
      updatedUser: clonedeep(this.user),
      selectedUserRoleValue: null,
      canUpdateUser: false,
      canDeactivateUser: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'localisation/user',
    }),
    /**
     * True if there was an update made to the user.
     */
    userUpdate() {
      return this.user.permissions !== this.updatedUser.permissions;
    },
    /**
     * Indicates if the user can be deactivated or activated.
     */
    deactivateOption() {
      return this.user.account_status === 2;
    },
  },
  watch: {
    // Must recalculate permissions when another user is clicked.
    user: {
      handler(val) {
        this.updatedUser = clonedeep(val);
        this.selectedUserRoleValue = userRole(this.user.permissions).value;
        this.userCanBeUpdated()
          .then((canUpdateUser) => {
            this.canUpdateUser = canUpdateUser && this.deactivateOption;
            this.userCanBeDeactivated(canUpdateUser)
              .then((canDeactivateUser) => {
                this.canDeactivateUser = canDeactivateUser;
              });
          });
      },
      deep: true,
    },
    updatedUser: {
      handler(val) {
        // If we update a user's permissions, update their role accordingly
        const updatedUserRole = userRole(val.permissions).value;
        if (updatedUserRole !== this.selectedUserRoleValue) {
          this.selectedUserRoleValue = updatedUserRole;
        }
      },
      deep: true,
    },
  },
  async created() {
    /**
     * Permission constraints:
     * - A user must have permission 'CAN_UPDATE_USERS' to update users.
     * - A user must have both permissions 'CAN_UPDATE_USERS' and 'CAN_UPDATE_USER_ACCOUNT_STATUS'
     *   to activate/deactivate another user.
     * - A user cannot update or activate/deactivate themselves.
     * - A user cannot be updated while it is deactivated.
     *
     * Note: In code, 'deactivate' can also be interpreted as 'activate' (they are opposites).
     */
    const softUserUpdate = await this.userCanBeUpdated();
    this.canUpdateUser = softUserUpdate && this.deactivateOption;
    this.canDeactivateUser = await this.userCanBeDeactivated(softUserUpdate);
  },
  beforeMount() {
    this.selectedUserRoleValue = userRole(this.user.permissions).value;
  },
  methods: {
    ...mapActions({
      checkPermission: 'localisation/checkPermission',
    }),
    getAccountStatusDisplay,
    bitwisePermissionCheck,
    /**
     * Updating permission increases/decreases the permission level
     * correspondingly.
     */
    onUpdatePermission(permission) {
      if (bitwisePermissionCheck(permission, this.updatedUser.permissions)) {
        this.updatedUser.permissions -= permission;
      } else {
        this.updatedUser.permissions += permission;
      }
      this.$log.debug('User permission updated by:', permission, '; new level:', this.updatedUser.permissions);
    },
    addPermission(permissionLevel) {
      if (!bitwisePermissionCheck(permissionLevel, this.updatedUser.permissions)) {
        this.updatedUser.permissions += permissionLevel;
      }
    },
    removePermission(permissionLevel) {
      if (bitwisePermissionCheck(permissionLevel, this.updatedUser.permissions)) {
        this.updatedUser.permissions -= permissionLevel;
      }
    },
    /**
     * Takes a list of permissions (e.g., CAN_LIST_USERS, CAN_ADD_USERS, etc...)
     * and resets them to their original value
     * @param { string[] } permissions
     */
    resetPermissions(permissions) {
      const permissionLevels = getPermissionLevels();
      permissions.forEach((p) => {
        const pL = permissionLevels[p];
        if (bitwisePermissionCheck(pL, this.user.permissions)) {
          this.addPermission(pL);
        } else {
          this.removePermission(pL);
        }
      });
    },
    onUpdateUserRoleValue(newRole) {
      if (newRole === userRole(this.user.permissions).value) {
        const rolePermissions = ROLES[this.selectedUserRoleValue].permissions;
        const roleNotPermitted = ROLES[this.selectedUserRoleValue].notPermitted;
        this.resetPermissions([...rolePermissions, ...roleNotPermitted]);
        this.selectedUserRoleValue = newRole;
        return;
      }

      const rolePermissions = ROLES[newRole].permissions;
      const roleNotPermitted = ROLES[newRole].notPermitted;
      const permissionLevels = getPermissionLevels();

      rolePermissions.forEach((p) => {
        this.addPermission(permissionLevels[p]);
      });

      roleNotPermitted.forEach((p) => {
        this.removePermission(permissionLevels[p]);
      });

      this.selectedUserRoleValue = newRole;
    },
    updateUser() {
      this.$emit('updateUser', this.updatedUser);
    },
    isSelfUser(user) {
      return this.loggedInUser.id === user.id;
    },
    deactivateUser() {
      this.$emit('deactivateUser');
    },
    async userCanBeUpdated() {
      return await (this.checkPermission('CAN_UPDATE_USERS')) && !this.isSelfUser(this.user);
    },
    async userCanBeDeactivated(userCanBeUpdated) {
      return await (this.checkPermission('CAN_UPDATE_USER_ACCOUNT_STATUS')) && userCanBeUpdated;
    },
  },
};
</script>
<style lang="scss" scoped>
aside {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-status, .user-role {
  align-items: center;
  display: flex;
  gap: 96px;

  p {
    margin: 0;
  }

  .status-info {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 16px;
    justify-content: space-between;
  }
}

.user-status {
  gap: 84px;
}

.permission-groups-header {
  align-items: center;
  display: flex;
  gap: 4px;
  margin: 12px 0px;
  text-transform: uppercase;
}

.permissions-container {
  align-items: center;
  display: flex;
  gap: 8px;
  margin: 0;
  p {
    margin: 4px 0 4px 4px;
  }
}

.permission-list-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.permission-groups-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 45px 0;
}

.permission {
  align-items: center;
  display: flex;
  gap: 4px;
}
</style>
