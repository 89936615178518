/**
 * This function outputs a deterministic, collision-free, pastel colour
 * based on a hash generated from the provided input label.
 */
function stringToUniqueColour(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Increase the difference between colours generated for similar strings
  hash += (str.length * 20);
  // Convert hash to hsl colour, using 100% saturation and 90% lightness to achieve theme
  const color = `hsl(${hash}deg, 100%, 90%)`;

  return color;
}

export default stringToUniqueColour;
