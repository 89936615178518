import getEntityNameMetrics from '@/store/helpers/metrics/getEntityNameMetrics';
import { logger } from '@/store/logger';

export default (grouping, tables) => {
  logger.debug('tdrToEntityNameMetrics: grouping, tables', grouping, tables);

  return grouping.groups
    .filter((g) => g.groupIdentifier)
    .map((g) => {
      const { rows, metrics } = tables[g.tableIndex];
      return {
        groupIdentifier: g.groupIdentifier,
        groupDisplay: g.groupDisplay,
        metrics: getEntityNameMetrics(rows, metrics),
      };
    });
};
