export default () => ({
  DOCUMENT_REQUEST: {
    id: 1,
    file_name: 'New-Document.pdf',
    file_hash: '1234',
    mime_type: '.pdf',
    s3_key: '/somefile/s3',
    archived: false,
    verified: false,
    time_created: '2021-02-17 17:27:02.501836+00:00',
    time_updated: 'None',
    fk_versioned_document_type_id: 1,
    fk_extraction_progress_id: 1,
    fk_user_id: 1,
  },
  DOCUMENT_TYPE: {
    type: 'DOCUMENT_TYPE',
    id: 2,
    name: 'am_capital_call',
    display_name: 'Capital Call',
    available: true,
    fk_document_category_id: 1,
    fk_user_id: 1,
    time_created: '2021-02-17 17:27:02.501836+00:00',
    time_updated: 'None',
  },
  METRICS_AND_DATAPOINTS: [
    {
      METRIC: {
        type: 'METRIC',
        id: 1,
        validated: false,
        required: true,
        available: true,
        order_position: 371360,
        fk_client_defined_metric_id: 109,
        fk_document_request_id: 3,
        fk_datapoint_id: null,
        fk_metric_group_id: null,
        time_created: '2021-02-17 17:27:02.501836+00:00',
        time_updated: 'None',
        metric_identifier_type: 'STRING',
        metric_identifier_value: 'investment_asset_class_name',
        metric_display: 'Center.',
      },
      DATAPOINTS: [
        {
          id: 1,
          effectors: '{}',
          confidence: 0.5934676146105,
          fk_metric_id: 1,
          x: '0.53880146890634',
          y: '0.349704015',
          w: '0.23059926554683',
          h: '0.3251479925',
          page: '3',
          fk_modified_by_id: null,
          time_created: '2021-02-17 17:27:02.501836+00:00',
          time_updated: 'None',
          value: 'Concern something traditional could drug allow magazine.',
        },
        {
          id: 2,
          effectors: '{}',
          confidence: 0.5044,
          fk_metric_id: 1,
          x: '0.722',
          y: '0.98707072957',
          w: '0.139',
          h: '0.006464635215',
          page: '22',
          fk_modified_by_id: null,
          time_created: '2021-02-17 17:27:02.501836+00:00',
          time_updated: 'None',
          value: 'Suggest training unit already cut.',
        },
      ],
    },
  ],
  STAGE: 'ExtractionStage.DOC_REQUESTED',
});
