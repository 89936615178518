import formatNumber from './formatNumber';
import { currencyPositions } from './mapping/currencies';

const numberToCurrencyString = (amount,
  { currencySymbol, currencyPosition = currencyPositions.PREFIX, countryShortcode = '' },
  {
    minPrecision = 0, maxPrecision = 10, thousandSeparator = ',', decimalPoint = '.',
  } = {}) => {
  const absAmount = Math.abs(amount);
  const negativeSignStr = amount < 0 ? '-' : '';
  const formattedAmount = formatNumber(absAmount, {
    minPrecision, maxPrecision, thousandSeparator, decimalPoint,
  });

  let paddedCountryShortcodeStr = '';
  if (countryShortcode.length) {
    paddedCountryShortcodeStr = `${countryShortcode} `;
  }

  if (currencyPosition === currencyPositions.PREFIX) {
    return `${paddedCountryShortcodeStr}${negativeSignStr}${currencySymbol}${formattedAmount}`;
  }

  return `${paddedCountryShortcodeStr}${negativeSignStr}${formattedAmount}${currencySymbol}`;
};

export default numberToCurrencyString;
