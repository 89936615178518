/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import Api from '../helpers/api';
import {
  EntityMetricsFilters,
  EntityMetricsPage, GetLatestEntityDatapointsResponse, GetMetricsResponse, Getters, State,
} from './types';

const DEFAULT_MAX_PAGE_SIZE = 20;

const initialState = (): State => ({
  ...storeState,
  entityId: null,
  data: null,
  entityType: null,
  filters: {},
  maxPageSize: DEFAULT_MAX_PAGE_SIZE,
  offset: 0,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  entityId: (state: State) => state.entityId,
  entityType: (state: State) => state.entityType ?? null,
  entityMetrics: (state: State) => state.data?.items ?? [],
  filters: (state: State) => state.filters,
  paginationInfo: (state: State) => ({
    count: state.data?.count ?? 0,
    totalCount: state.data?.totalCount ?? 0,
    maxPageSize: state.maxPageSize,
    page: (state.offset > 0
      ? (state.offset / state.maxPageSize) : 0) + 1,
    offset: state.offset,
    totalPages: state.data
      ? Math.ceil(state.data?.totalCount / state.maxPageSize) : 0,
  }),
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_ENTITY_ID(state: State, entityId: string) {
      state.entityId = entityId;
    },
    SET_DATA(state: State, data: EntityMetricsPage) {
      state.data = data;
      logger.debug('Data has been updated', state.data);
    },
    SET_ENTITY_TYPE(state: State, entityType: string) {
      state.entityType = entityType;
    },
    SET_FILTERS(state: State, filters: EntityMetricsFilters) {
      state.filters = filters;
    },
    SET_OFFSET(state: State, offset: number) {
      state.offset = Math.floor(
        offset / state.maxPageSize,
      ) * state.maxPageSize;
    },
    SET_MAX_PAGE_SIZE(state: State, maxPageSize: number) {
      state.maxPageSize = maxPageSize;
    },
    RESET(state: State) {
      logger.debug('Resetting state of entity datapoints store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,

    init: async (
      { commit, dispatch, getters }: ActionContext<State, any>,
      {
        entityId, entityType, page, maxPageSize, filters = {},
      }: { entityId: string, entityType: string, page?: number, maxPageSize?: number, filters?: EntityMetricsFilters },
    ): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing entity datapoints for entity id:', entityId);
        commit('SET_ENTITY_ID', entityId);
        commit('SET_ENTITY_TYPE', entityType);
        commit('SET_FILTERS', filters);
        if (maxPageSize) {
          commit('SET_MAX_PAGE_SIZE', maxPageSize);
        }
        if (page) {
          commit('SET_OFFSET', (page - 1) * getters.paginationInfo.maxPageSize);
        }
        await dispatch('refresh');
        logger.debug('Entity datapoints store initialised');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    reset: async (
      { commit }: ActionContext<State, any>,
    ) => {
      logger.debug('Resetting entity metrics store');
      commit('RESET');
    },

    getEntityMetrics: async (
      { dispatch }: any, {
        entityId, entityType, offset, maxPageSize, filters = {},
      }: { entityId: string, entityType: string, offset: number, maxPageSize: number, filters?: EntityMetricsFilters },
    ): Promise<EntityMetricsPage> => {
      const metricsResponse: GetMetricsResponse = await dispatch('getMetrics', {
        entityType, maxPageSize, offset, filters,
      });
      if (metricsResponse.metricCount === 0) {
        return {
          count: 0,
          totalCount: metricsResponse.totalMetricCount,
          items: [],
        };
      }
      const fins = metricsResponse.metrics.map((metric) => metric.fin);
      const datapointsResponse: GetLatestEntityDatapointsResponse = await dispatch('getEntityDatapoints', {
        entityId,
        fins,
        offset: 0,
        maxPageSize: fins.length,
      });

      const entityMetricsPage = {
        count: metricsResponse.metricCount,
        totalCount: metricsResponse.totalMetricCount,
        items: metricsResponse.metrics.map((metric) => ({
          fin: metric.fin,
          name: metric.name,
          validators: metric.validators,
          latestDatapoint: datapointsResponse.items.find((dp) => dp.metric.fin === metric.fin) ?? null,
        })),
      };

      return entityMetricsPage;
    },

    getEntityDatapoints: async (
      { rootGetters }: any, {
        entityId, fins, offset, maxPageSize,
      }: { entityId: string, fins: string[], offset: number, maxPageSize: number },
    ): Promise<GetLatestEntityDatapointsResponse> => (new Api(process.env, rootGetters['authenticate/idToken']))
      .get('entities/datapoints/latest', {
        entityId,
        fin: fins,
        maxPageSize,
        offset,
      } as any),

    getMetrics: async (
      { rootGetters }: any, {
        entityType,
        maxPageSize,
        offset,
        filters = {},
      }: { entityType: string, maxPageSize: number, offset: number, filters: EntityMetricsFilters },
    ): Promise<GetMetricsResponse> => (new Api(process.env, rootGetters['authenticate/idToken']))
      .get('schemas/metric-identifiers/summaries', {
        orderBy: 'name:asc',
        offset,
        maxPageSize,
        entityAssociation: entityType,
        ...filters,
      } as any),

    updateEntityType: async (
      { dispatch, commit }: ActionContext<State, any>,
      entityType: string,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Updating entity type - the offset is set to 0');
      try {
        commit('SET_ENTITY_TYPE', entityType);
        await dispatch('refresh');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to filter related entities', e);
        throw e;
      }
    },

    refresh: async (
      { getters, commit, dispatch }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Re-fetching the current page of entity metrics');
      try {
        const response: EntityMetricsPage = await dispatch('getEntityMetrics', {
          entityId: getters.entityId,
          entityType: getters.entityType,
          offset: getters.paginationInfo.offset,
          maxPageSize: getters.paginationInfo.maxPageSize,
          filters: getters.filters,
        });
        commit('SET_DATA', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    filterEntityMetrics: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      filters: EntityMetricsFilters,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Filtering entity metrics - the offset is set to 0');
      const currentFilters = cloneDeep(getters.filters);
      try {
        commit('SET_FILTERS', { ...getters.filters, ...filters });
        await dispatch('refresh');
      } catch (e) {
        commit('SET_FILTERS', currentFilters);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to filter entity metrics', e);
        throw e;
      }
    },

    updateCurrentPageAndMaxPageSize: async (
      { dispatch, commit }: ActionContext<State, any>,
      { page, maxPageSize }: { page: number, maxPageSize: number },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_MAX_PAGE_SIZE', maxPageSize);
      commit('SET_OFFSET', (page - 1) * maxPageSize);
      try {
        await dispatch('refresh');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to update page and maxPageSize for entity metrics', e);
        throw e;
      }
    },
  },
};

export default store;
