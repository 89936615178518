<template>
  <transition name="modal">
    <div
      v-show="show"
      class="modal-bg"
      :class="customClasses"
      data-testid="modal"
      @click="close"
    >
      <div
        class="modal-container"
        @click.stop
      >
        <div
          v-if="title"
          class="modal-header"
        >
          <slot name="extra-header-content" />
          <h2>{{ title }}</h2>
          <div
            class="icon-container modal-exit-btn"
            @click="close"
          >
            <x-icon size="1.75x" />
          </div>
        </div>

        <div class="modal-body">
          <slot />
        </div>
        <!-- <hr class="mt-medium"> -->
        <slot name="footer" />
      </div>
    </div>
  </transition>
</template>
<script>
import { XIcon } from '@zhuowenli/vue-feather-icons';

export default {
  components: {
    XIcon,
  },
  props: {
    customClasses: {
      type: [Array, Object],
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  mounted() {
    document.addEventListener('keydown', this.handleEscapePressed);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleEscapePressed);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleEscapePressed(e) {
      if (this.show && e.code === 'Escape') {
        this.close();
      }
    },
  },
};
</script>
