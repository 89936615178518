/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext } from 'vuex';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import {
  MetricsFilter, MetricsPage, Getters, State,
} from './types';
import Api from '../helpers/api';
import { DEFAULT_MAX_PAGE_SIZE } from './constants';
import { CreateMetricRequest, PatchMetricRequest } from '../metric/types';
import toArray from '../helpers/toArray';

const initialState = (): State => ({
  ...storeState,
  filter: {},
  orderBy: null,
  metricsPage: null,
  offset: 0,
  maxMetricsPerPage: DEFAULT_MAX_PAGE_SIZE,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  metricsPage: (state: State) => state.metricsPage,
  metricsPageIndex: (state: State) => (state.offset > 0
    ? (state.offset / state.maxMetricsPerPage) : 0),
  totalPages: (state: State) => (state.metricsPage
    ? Math.ceil(state.metricsPage.totalMetricCount / state.maxMetricsPerPage)
    : null),
  isCurrentPageLastPage: (state: State) => {
    if (!state.metricsPage) {
      return false;
    }
    const pageIndex = state.offset > 0 ? (state.offset / state.maxMetricsPerPage) : 0;
    const totalPages = Math.ceil(
      state.metricsPage.totalMetricCount / state.maxMetricsPerPage,
    );
    return totalPages === 0 ? true : pageIndex + 1 === totalPages;
  },
  filter: (state: State) => state.filter,
  filterTimePeriods: (state: State) => toArray(state.filter.timePeriod),
  filterDataTypes: (state: State) => toArray(state.filter.type),
  filterAssociations: (state: State) => toArray(state.filter.association),
  offset: (state: State) => state.offset,
  orderBy: (state: State) => state.orderBy,
  maxMetricsPerPage: (state: State) => state.maxMetricsPerPage,
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_METRICS_PAGE(state: State, page: MetricsPage) {
      state.metricsPage = page;
      logger.debug('metricsPage has been updated', state.metricsPage);
    },
    SET_MAX_METRICS_PER_PAGE(state: State, maxMetricsPerPage: number) {
      state.maxMetricsPerPage = maxMetricsPerPage;
    },
    SET_FILTER(state: State, filter: MetricsFilter) {
      state.filter = filter;
      logger.debug('metrics filter has been updated', state.filter);
    },
    SET_OFFSET(state: State, offset: number) {
      state.offset = Math.floor(
        offset / state.maxMetricsPerPage,
      ) * state.maxMetricsPerPage;
    },
    SET_ORDER_BY(state: State, orderBy: string) {
      state.orderBy = orderBy;
    },
    RESET(state: State) {
      logger.debug('Reseting state of metrics store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ commit, dispatch }: ActionContext<State, any>,
      {
        filter = {}, orderBy = null, page = 1, maxPageSize = DEFAULT_MAX_PAGE_SIZE,
      }: {
        filter?: MetricsFilter,
        orderBy?: string | null,
        page?: number,
        maxPageSize?: number,
      }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing metrics store - filter, orderBy, page, page size:', filter, orderBy, page, maxPageSize);
        commit('SET_MAX_METRICS_PER_PAGE', maxPageSize);
        commit('SET_OFFSET', (page - 1) * maxPageSize);
        commit('SET_FILTER', filter);
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    reset({ commit }: ActionContext<State, any>) {
      commit('RESET');
    },

    getMetrics: async (
      { rootGetters, getters }: ActionContext<State, any>,
      {
        offset, filter = {}, orderBy = null, maxPageSize = getters.maxMetricsPerPage,
      }: { offset: number, filter?: MetricsFilter, maxPageSize?: number, orderBy?: string | null },
    ) => {
      logger.debug(
        `Fetching metrics -- offset ${offset} -- filter: `, filter,
        ` -- orderBy: ${orderBy} -- `, `-- maxPageSize: ${maxPageSize}`,
      );
      const orderByVal = orderBy?.length ? orderBy : 'name:asc';
      const cleanedFilters = Object.fromEntries(
        Object.entries(filter).filter(([, value]) => value?.length !== 0),
      );
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('schemas/metric-identifiers/summaries', {
          offset,
          ...orderByVal && { orderBy: orderByVal },
          maxPageSize,
          ...cleanedFilters,
        } as any);
    },

    deleteMetric: async (
      { rootGetters, dispatch }: ActionContext<State, any>,
      fin: string,
    ) => {
      logger.debug('Deleting Metric');
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('schemas/metric-identifiers/delete', { fin })
        .then(() => {
          dispatch('refreshCurrentPage');
        });
    },

    updateMetric: async (
      { rootGetters, dispatch }: ActionContext<State, any>,
      body: PatchMetricRequest,
    ) => {
      logger.debug('Updating Metric');
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .patch('schemas/metric-identifiers/update', body as any)
        .then((response) => {
          dispatch('refreshCurrentPage');
          return response;
        });
    },

    createMetric: async (
      { rootGetters, dispatch }: ActionContext<State, any>,
      body: CreateMetricRequest,
    ) => {
      logger.debug('Creating Metric');
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('schemas/metric-identifiers/create', body as any)
        .then((response) => {
          dispatch('refreshCurrentPage');
          return response;
        });
    },
    sortMetrics: async (
      { dispatch, commit }: ActionContext<State, any>,
      { orderBy = null }: { orderBy: string | null },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Sorting metrics - ', orderBy);
      try {
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    refreshCurrentPage: async (
      { getters, commit, dispatch }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Re-fetching the current page of metrics');
      try {
        const response = await dispatch('getMetrics', {
          offset: getters.offset, filter: getters.filter, orderBy: getters.orderBy,
        });
        commit('SET_METRICS_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    filterMetrics: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      filter: MetricsFilter,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Filtering metrics - the offset is set to 0');
      try {
        commit('SET_FILTER', { ...getters.filter, ...filter });
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to filter metrics', e);
        throw e;
      }
    },

    updateCurrentPageAndMaxPageSize: async (
      { dispatch, commit }: ActionContext<State, any>,
      { page, maxPageSize }: { page: number, maxPageSize: number },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_MAX_METRICS_PER_PAGE', maxPageSize);
      commit('SET_OFFSET', (page - 1) * maxPageSize);
      try {
        await dispatch('refreshCurrentPage');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to update page and maxPageSize for metrics', e);
        throw e;
      }
    },

    updateCurrentPage: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      { page }: { page: number },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', (page - 1) * getters.maxMetricsPerPage);
      try {
        await dispatch('refreshCurrentPage');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to update metrics page', e);
        throw e;
      }
    },

    deleteMetrics: async (
      {
        rootGetters, getters, dispatch, commit,
      }: ActionContext<State, any>,
      metricIds: string[],
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Deleting metrics with the following ids: ', metricIds);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('metrics/delete', { uuids: metricIds })
        .then(async () => {
          logger.debug(`Metric metrics ${metricIds} deleted successfully`);
          const newOffset = getters.offset - metricIds.length;
          commit('SET_OFFSET', newOffset < 0 ? 0 : newOffset);
          await dispatch('refreshCurrentPage');
        })
        .catch((e) => {
          logger.error('Failed to delete metrics: ', metricIds);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
  },
};

export default store;
