import { logger } from '@/store/logger';
import { ActionContext } from 'vuex';
import {
  storeState, storeStateGetters, storeStateMutations, allowedStates,
} from '../helpers/storeState';
import Api from '../helpers/api';
import { State } from './types';
import { MetricSummary } from '../metrics/types';

const store = {
  namespaced: true,
  state: {
    ...storeState,
    entityTypes: null,
  },

  getters: {
    ...storeStateGetters,
    entityTypes: (state: State) => state.entityTypes,
    displayNameByFin: (state: State) => state.entityTypes?.reduce((acc, eT) => {
      acc[eT.fin] = eT.display_name;
      return acc;
    }, {} as { [key: string]: string }),
  },

  mutations: {
    ...storeStateMutations,
  },

  actions: {
    init: async ({ commit, dispatch }: ActionContext<State, any>) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      try {
        const response = await dispatch('getEntityTypes');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        const entityTypeMetrics = response.metrics.map((item: MetricSummary) => ({
          fin: item.fin,
          display_name: item.name,
          validators: item.validators,
        }));
        commit('SET_PROP', { key: 'entityTypes', value: entityTypeMetrics });
        return entityTypeMetrics;
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    lazyInit: async ({ commit, dispatch, getters }: ActionContext<State, any>) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.entityTypes !== null) {
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } else {
        await dispatch('init');
      }
    },

    getEntityTypes: ({ rootGetters }: ActionContext<State, any>) => {
      logger.debug(`Getting entity types, offline: ${rootGetters['entityRequest/offline']}`);
      /**
       * WARNING: Server can return max 500 metrics in paginated response.
       * If the number of entity types in the DB exceeds this limit in the future,
       * consider implementing pagination.
       *  */
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('schemas/metric-identifiers/summaries', {
          orderBy: 'name',
          type: 'ENTITY_NAME',
          maxPageSize: 500,
        } as any);
    },
  },
};

export default store;
