import { z } from 'zod';

export type EditProfileForm = {
  name: string,
  email: string
};

export const editProfileFormValidationSchema = z.object({
  name: z.string({
    required_error: 'Required',
  })
    .min(1, 'Required'),
  email: z.string({
    required_error: 'Required',
  }).min(1, 'Required').email('Invalid email'),
});
