export default {
  BTN_NEG: {
    buttonClass: 'btn-neg',
  },
  BTN_NEG_BORDERLESS: {
    buttonClass: 'btn-neg-borderless',
  },
  BTN_PRIMARY: {
    buttonClass: 'btn-primary',
  },
  BTN_PRIMARY_FILLED: {
    buttonClass: 'btn-primary-filled',
  },
  BTN_SECONDARY: {
    buttonClass: 'btn-secondary',
  },
  BTN_NEUTRAL: {
    buttonClass: 'btn-neutral',
  },
};
