import { EntityMetadataFormInputSchema } from '@/components/entities/forms/schemas';
import { InterDocumentEntity } from '@/components/entities/types';

const ideToEntityMetadata = (ide: InterDocumentEntity): EntityMetadataFormInputSchema => ({
  name: ide.name,
  akas: ide.akas,
  types: ide.fins.map((fin) => ({ fin: fin.value, display_name: fin.display, validators: [] })),
  clientIdentifier: ide.clientIdentifier,
});

export default ideToEntityMetadata;
