/* eslint-disable no-param-reassign */
import { ActionContext } from 'vuex';
import { allowedStates, storeState, storeStateMutations } from '../helpers/storeState';
import constants from './constants';
import { Getters, State, TimePeriod } from './types';

const initialState = (): State => ({
  ...storeState,
  timePeriods: [],
});

const storeGetters: Getters = {
  timePeriods: (state: State) => state.timePeriods,
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_TIME_PERIODS(state: State, timePeriods: TimePeriod[]) {
      state.timePeriods = timePeriods;
    },
  },
  actions: {
    lazyInit: async ({ commit }: ActionContext<State, any>): Promise<void> => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_TIME_PERIODS', constants.TIME_PERIODS);
      commit('SET_STORE_STATUS', allowedStates.IS_READY);
    },
  },
};

export default store;
