import {
  CalendarIcon, DollarSignIcon, PercentIcon, HashIcon,
  TypeIcon,
} from '@zhuowenli/vue-feather-icons';
import EntitiesIcon from '@/components/icons/EntitiesIcon.vue';
import EntityLinkIcon from '@/components/icons/EntityLinkIcon.vue';
import { MetricDataTypeMappings } from './types';

const columnHeaders = [
  {
    id: 'name',
    label: 'Name',
    columnWidth: '20%',

  },
  {
    id: 'description',
    label: 'Description',
    columnWidth: '30%',

  },
  {
    id: 'timePeriod',
    label: 'Time Period',
    columnWidth: '5%',
  },
  {
    id: 'createdBy',
    label: 'Created By',
    columnWidth: '15%',
  },
  {
    id: 'type',
    label: 'Type',
    columnWidth: '10%',

  },
  {
    id: 'association',
    label: 'Association',
    columnWidth: '20%',

  },
];

const DEFAULT_MAX_PAGE_SIZE = 20;

const DATA_TYPE_MAPPINGS: MetricDataTypeMappings = {
  CURRENCY: {
    label: 'Currency',
    icon: DollarSignIcon,
    description: 'Represents a financial amount by combining a currency type, such as USD, with a corresponding numeric value (e.g., USD 27,598).',
  },
  STRING: {
    label: 'Text',
    icon: TypeIcon,
    description: 'Used for alphanumeric data, such as names, descriptions, or any freeform input.',
  },
  NUMERIC: {
    label: 'Number',
    icon: HashIcon,
    description: 'Used for numerical data, including integers or decimals useful for quantities, or measurements  such as # of FTEs (e.g., 1,259, 10.5)',
  },
  DATE: {
    label: 'Date',
    icon: CalendarIcon,
    description: 'Used for calendar-based information such as Investment Date, representing a specific day, month, and year (e.g., 12 October 2024).',
  },
  PERCENTAGE: {
    label: 'Percent',
    icon: PercentIcon,
    description: 'Represent numeric values followed by a \'%\' symbol, making them ideal for conveying proportions, ratios, and comparative data such as IRR or Growth Rate (e.g., 25%)',
  },
  ENTITY_NAME: {
    label: 'Entity Type',
    icon: EntitiesIcon,
    description: 'Categorises the kind of organisation represented, such as a fund, limited partner, or supplier.',
  },
  ENTITY_LINK: {
    label: 'Entity Link',
    icon: EntityLinkIcon,
    description: 'Establishes a connection between two entities, enabling relationships between them, such as linking an underlying asset to a specific fund or vehicle.',
  },
};

export { columnHeaders, DEFAULT_MAX_PAGE_SIZE, DATA_TYPE_MAPPINGS };
