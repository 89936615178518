import { enGB, enUS } from 'date-fns/esm/locale';

const dateFormats = {
  DEFAULT: {
    format: 'DD/MM/YYYY',
    fnsFormat: 'dd/MM/yyyy',
    fnsLocale: enGB,
  },
  'en-gb': {
    format: 'DD/MM/YYYY',
    fnsFormat: 'dd/MM/yyyy',
    fnsLocale: enGB,
  },
  'en-us': {
    format: 'MM/DD/YYYY',
    fnsFormat: 'MM/dd/yyyy',
    fnsLocale: enUS,
  },
};

export default dateFormats;
