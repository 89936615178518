import { ZodObject, ZodEffects } from 'zod';

const formattedValidationErrors = (zodSchema: ZodObject<any>
| ZodEffects<any>, data: any) => {
  let errors = null;
  const formValidationResult: any = zodSchema.safeParse(data);
  if (!formValidationResult.success) {
    errors = formValidationResult.error.format();
  }
  return errors;
};

export default formattedValidationErrors;
