import { getMappingFor } from './mappings';

const currencyPositions = {
  PREFIX: 'PREFIX',
  SUFFIX: 'SUFFIX',
};

const currencies = {
  AUTO: {
    displayOption: 'AUTOMATIC',
  },
  AUD: {
    displayOption: 'AUD $',
    displayInline: '$',
    displayCountry: 'AUD',
    position: currencyPositions.PREFIX,
  },
  BRL: {
    displayOption: 'BRL $',
    displayInline: '$',
    displayCountry: 'BRL',
    position: currencyPositions.PREFIX,
  },
  CAD: {
    displayOption: 'CAD $',
    displayInline: 'C$',
    displayCountry: 'CAD',
    position: currencyPositions.PREFIX,
  },
  CHF: {
    displayOption: 'CHF',
    displayInline: 'Fr.',
    displayCountry: 'CHF',
    position: currencyPositions.PREFIX,
  },
  CNY: {
    displayOption: 'CNY ¥',
    displayInline: '¥',
    displayCountry: 'CNY',
    position: currencyPositions.PREFIX,
  },
  CZK: {
    displayOption: 'CZK',
    displayInline: 'Kč',
    displayCountry: 'CZK',
    position: currencyPositions.SUFFIX,
  },
  DKK: {
    displayOption: 'DKK kr.',
    displayInline: 'kr.',
    displayCountry: 'DKK',
    position: currencyPositions.SUFFIX,
  },
  EUR: {
    displayOption: 'EUR €',
    displayInline: '€',
    displayCountry: 'EUR',
    position: currencyPositions.PREFIX,
  },
  GBP: {
    displayOption: 'GBP £',
    displayInline: '£',
    displayCountry: 'GBP',
    position: currencyPositions.PREFIX,
  },
  HKD: {
    displayOption: 'HKD $',
    displayInline: 'HK$',
    displayCountry: 'HKD',
    position: currencyPositions.PREFIX,
  },
  HRK: {
    displayOption: 'HRK kn',
    displayInline: 'kn',
    displayCountry: 'HRK',
    position: currencyPositions.SUFFIX,
  },
  IDR: {
    displayOption: 'IDR Rp',
    displayInline: 'Rp',
    displayCountry: 'IDR',
    position: currencyPositions.PREFIX,
  },
  ILS: {
    displayOption: 'ILS',
    displayInline: 'ils',
    displayCountry: 'ILS',
    position: currencyPositions.SUFFIX,
  },
  INR: {
    displayOption: 'INR ₹',
    displayInline: '₹',
    displayCountry: 'INR',
    position: currencyPositions.SUFFIX,
  },
  JPY: {
    displayOption: 'JPY ¥',
    displayInline: '¥',
    displayCountry: 'JPY',
    position: currencyPositions.PREFIX,
  },
  KRW: {
    displayOption: 'KRW ₩',
    displayInline: '₩',
    displayCountry: 'KRW',
    position: currencyPositions.PREFIX,
  },
  MYR: {
    displayOption: 'MYR',
    displayInline: 'RM',
    displayCountry: 'MYR',
    position: currencyPositions.SUFFIX,
  },
  NOK: {
    displayOption: 'NOK kr',
    displayInline: 'kr',
    displayCountry: 'NOK',
    position: currencyPositions.PREFIX,
  },
  NZD: {
    displayOption: 'NZD $',
    displayInline: '$',
    displayCountry: 'NZD',
    position: currencyPositions.PREFIX,
  },
  PEN: {
    displayOption: 'PEN S/',
    displayInline: 'S/',
    displayCountry: 'PEN',
    position: currencyPositions.PREFIX,
  },
  PLN: {
    displayOption: 'PLN zł',
    displayInline: 'zł',
    displayCountry: 'PLN',
    position: currencyPositions.SUFFIX,
  },
  RMB: {
    displayOption: 'RMB ¥',
    displayInline: '¥',
    displayCountry: 'RMB',
    position: currencyPositions.PREFIX,
  },
  RON: {
    displayOption: 'RON',
    displayInline: 'lei',
    displayCountry: 'RON',
    position: currencyPositions.SUFFIX,
  },
  RUB: {
    displayOption: 'RUB ₽',
    displayInline: '₽',
    displayCountry: 'RUB',
    position: currencyPositions.SUFFIX,
  },
  RSD: {
    displayOption: 'RSD',
    displayInline: 'DIN',
    displayCountry: 'RSD',
    position: currencyPositions.SUFFIX,
  },
  SEK: {
    displayOption: 'SEK kr',
    displayInline: 'kr',
    displayCountry: 'SEK',
    position: currencyPositions.SUFFIX,
  },
  SGD: {
    displayOption: 'SGD $',
    displayInline: 'S$',
    displayCountry: 'SGD',
    position: currencyPositions.PREFIX,
  },
  USD: {
    displayOption: 'USD $',
    displayInline: '$',
    displayCountry: 'USD',
    position: currencyPositions.PREFIX,
  },
  THB: {
    displayOption: 'THB ฿',
    displayInline: '฿',
    displayCountry: 'THB',
    position: currencyPositions.PREFIX,
  },
  TRY: {
    displayOption: 'TRY ₺',
    displayInline: '₺',
    displayCountry: 'TRY',
    position: currencyPositions.SUFFIX,
  },
  VND: {
    displayOption: 'VND ₫',
    displayInline: '₫',
    displayCountry: 'VND',
    position: currencyPositions.SUFFIX,
  },
  ZAR: {
    displayOption: 'ZAR R',
    displayInline: 'R',
    displayCountry: 'ZAR',
    position: currencyPositions.PREFIX,
  },

};

const currencyMappingToDisplay = getMappingFor(currencies, 'displayCountry');
const currencyMappingToDisplayOption = getMappingFor(currencies, 'displayOption');

export {
  currencies, currencyPositions, currencyMappingToDisplay, currencyMappingToDisplayOption,
};
