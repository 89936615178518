
export default {
  props: {
    overlayOpaque: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
  },
};
