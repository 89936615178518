type EntityTypeStyleConfig = {
  backgroundColor: string,
  color?: string,
};

const entityTypesStyle: { [key: string]: EntityTypeStyleConfig } = {
  ADMINISTRATOR: {
    backgroundColor: '#EFF5F6',
    color: '#1A2C2D',
  },
  AUDITOR: {
    backgroundColor: '#DBF4F3',
    color: '#598B89',
  },
  BANK: {
    backgroundColor: '#FCE8E5',
    color: '#E11900',
  },
  COMPANY: {
    backgroundColor: '#F6EEFE',
    color: '#A855F7',
  },
  'FACILITY PROVIDER': {
    backgroundColor: '#F3F5FF',
    color: '#626473',
  },
  FUND: {
    backgroundColor: '#FFF5E5',
    color: '#FF9D00',
  },
  'FUND MANAGER': {
    backgroundColor: '#E9F9EF',
    color: '#22C55E',
  },
  'GENERAL PARTNER': {
    backgroundColor: '#EBF3FE',
    color: '#3B82F6',
  },
  'INTERMEDIATE BANK': {
    backgroundColor: '#E6F0E0',
    color: '#64725A',
  },
  'INVESTMENT ADVISOR': {
    backgroundColor: '#DEEFFF',
    color: '#425',
  },
  JURISDICTION: {
    backgroundColor: '#FDEBEB',
    color: '#6f5656',
  },
  'LEGAL COUNSEL': {
    backgroundColor: '#EBF3E5',
    color: '#718267',
  },
  'LIMITED PARTNER': {
    backgroundColor: '#D9E2E2',
    color: '#426D70',
  },
  'PLACEMENT AGENT': {
    backgroundColor: '#F6EBFF',
    color: '#665573',
  },
  SUPPLIER: {
    backgroundColor: '#FFF2E3',
    color: '#998063',
  },
  VEHICLE: {
    backgroundColor: '#F1F0FF',
    color: '#7F7ECC',
  },
};

export default entityTypesStyle;
