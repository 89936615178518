<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.07881 5.06891C8.87414 1.27893 15.0438 1.31923 18.8623 5.13778C22.6825 8.95797 22.7212 15.1313 18.9263 18.9262C15.1314 22.7211 8.95805 22.6824 5.13786 18.8622C2.87401 16.5984 1.93916 13.5099 2.34059 10.5812C2.39684 10.1708 2.77512 9.88377 3.18549 9.94002C3.59587 9.99627 3.88295 10.3745 3.8267 10.7849C3.48672 13.2652 4.27794 15.881 6.19852 17.8016C9.443 21.0461 14.6665 21.0646 17.8656 17.8655C21.0647 14.6664 21.0461 9.44292 17.8017 6.19844C14.5588 2.95561 9.33901 2.93539 6.13947 6.12957L6.88717 6.13333C7.30138 6.13541 7.63547 6.47288 7.63339 6.88709C7.63131 7.3013 7.29384 7.63539 6.87963 7.63331L4.33408 7.62052C3.92281 7.61845 3.58993 7.28556 3.58786 6.8743L3.57507 4.32874C3.57299 3.91454 3.90708 3.57707 4.32129 3.57498C4.7355 3.5729 5.07297 3.907 5.07505 4.32121L5.07881 5.06891ZM12 7.24992C12.4142 7.24992 12.75 7.58571 12.75 7.99992V11.6893L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0302C14.7374 15.3231 14.2626 15.3231 13.9697 15.0302L11.25 12.3106V7.99992C11.25 7.58571 11.5858 7.24992 12 7.24992Z"
      fill="currentColor"
    />
  </svg>
</template>
