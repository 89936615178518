import { logger } from '@/store/logger';
import { extractionProgressMappingFromBackend, extractionProgressMappingToBackend } from '../../mapping/extractionProgresses';

const backendToDocumentRequestsInProcessing = (docReqs) => docReqs.map((docReq) => {
  logger.debug('docReq progress stage:', docReq.extraction_progress_stage_name);
  const extractionProgressName = extractionProgressMappingFromBackend[docReq.extraction_progress_stage_name];
  return {
    id: docReq.id,
    documentRequestId: docReq.document_request_id,
    documentType: docReq.fk_versioned_document_type_id,
    documentTypeName: docReq.document_type_name,
    filename: docReq.file_name,
    stage: extractionProgressMappingFromBackend[extractionProgressName],
  };
});

// Paths:
const stagesToQueryStringStages = (stages) => stages.map((s) => extractionProgressMappingToBackend[s]).join(',');

export { stagesToQueryStringStages, backendToDocumentRequestsInProcessing };
