/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext } from 'vuex';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import Api from '../helpers/api';
import {
  State, Getters, EntityDatapointsPage, GetEntityDatapointsResponse,
} from './types';

export const DEFAULT_MAX_PAGE_SIZE = 20;

const initialState = (): State => ({
  ...storeState,
  entityId: null,
  metricFin: null,
  data: null,
  maxPageSize: DEFAULT_MAX_PAGE_SIZE,
  offset: 0,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  entityId: (state: State) => state.entityId,
  metricFin: (state: State) => state.metricFin,
  datapoints: (state: State) => state.data?.items ?? [],
  paginationInfo: (state: State) => ({
    count: state.data?.count ?? 0,
    totalCount: state.data?.totalCount ?? 0,
    maxPageSize: state.maxPageSize,
    page: (state.offset > 0
      ? (state.offset / state.maxPageSize) : 0) + 1,
    offset: state.offset,
    totalPages: state.data
      ? Math.ceil(state.data?.totalCount / state.maxPageSize) : 0,
  }),
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_ENTITY_ID(state: State, entityId: string) {
      state.entityId = entityId;
    },
    SET_DATA(state: State, data: EntityDatapointsPage) {
      state.data = data;
      logger.debug('Data has been updated', state.data);
    },
    SET_METRIC_FIN(state: State, metricFin: string) {
      state.metricFin = metricFin;
    },
    SET_OFFSET(state: State, offset: number) {
      state.offset = Math.floor(
        offset / state.maxPageSize,
      ) * state.maxPageSize;
    },
    SET_MAX_PAGE_SIZE(state: State, maxPageSize: number) {
      state.maxPageSize = maxPageSize;
    },
    RESET(state: State) {
      logger.debug('Resetting state of entity datapoints store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,

    init: async (
      { commit, dispatch, getters }: ActionContext<any, any>,
      {
        entityId, metricFin, page, maxPageSize,
      }: { entityId: string, metricFin: string, page?: number, maxPageSize?: number },
    ): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing entity datapoints for entity id:', entityId);
        commit('SET_ENTITY_ID', entityId);
        commit('SET_METRIC_FIN', metricFin);
        if (maxPageSize) {
          commit('SET_MAX_PAGE_SIZE', maxPageSize);
        }
        if (page) {
          commit('SET_OFFSET', (page - 1) * getters.paginationInfo.maxPageSize);
        }
        await dispatch('refresh');
        logger.debug('Entity datapoints store initialised');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    reset: async (
      { commit }: ActionContext<State, any>,
    ) => {
      logger.debug('Resetting entity metric datapoints store');
      commit('RESET');
    },

    getDatapoints: async (
      { rootGetters }: any, {
        entityId, metricFin, offset, maxPageSize,
      }: { entityId: string, metricFin: string, offset: number, maxPageSize: number },
    ): Promise<GetEntityDatapointsResponse> => (new Api(process.env, rootGetters['authenticate/idToken']))
      .get('entities/datapoints', {
        entityId,
        fin: metricFin,
        maxPageSize,
        offset,
      } as any),

    updateCurrentPageAndMaxPageSize: async (
      { dispatch, commit }: ActionContext<State, any>,
      { page, maxPageSize }: { page: number, maxPageSize: number },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_MAX_PAGE_SIZE', maxPageSize);
      commit('SET_OFFSET', (page - 1) * maxPageSize);
      try {
        await dispatch('refresh');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to update page and maxPageSize for entity metric datapoints', e);
        throw e;
      }
    },

    delete: async (
      {
        rootGetters, getters, dispatch, commit,
      }: ActionContext<State, any>,
      datapointIds: string[],
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Deleting datapoints with the following ids: ', datapointIds);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('interdocumententity/datapoints/delete', { uuids: datapointIds })
        .then(async () => {
          logger.debug(`Entity metric datapoints ${datapointIds} deleted successfully`);
          const newOffset = getters.paginationInfo.offset - datapointIds.length;
          commit('SET_OFFSET', newOffset < 0 ? 0 : newOffset);
          await dispatch('refresh');
        })
        .catch((e) => {
          logger.error('Failed to delete entity datapoints: ', datapointIds);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },

    refresh: async (
      { getters, commit, dispatch }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Re-fetching the current page of entity metrics');
      try {
        const response: EntityDatapointsPage = await dispatch('getDatapoints', {
          entityId: getters.entityId,
          metricFin: getters.metricFin,
          offset: getters.paginationInfo.offset,
          maxPageSize: getters.paginationInfo.maxPageSize,
        });
        commit('SET_DATA', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
  },
};

export default store;
