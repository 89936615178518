<template>
  <div
    id="app"
    class="app"
  >
    <Sidebar
      v-cloak
      v-if="showHozNav"
    />
    <div :class="{'main': true, 'main-fullscreen': !showHozNav, 'main-fullscreen-top-nav': pageHasTopNav}">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Sidebar from './components/sidebar/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Sidebar,
  },
  computed: {
    showHozNav() {
      if (!this.$route.name) {
        return false;
      }

      return !this.$route.matched.some((record) => record.meta && record.meta.shouldHideNav);
    },
    pageHasTopNav() {
      // This property is a bit of a hack. It disables the screen height from being
      // 100%, ensuring that the top nav does not add additional vertical page height
      if (!this.$route.name) {
        return false;
      }

      return this.$route.matched.some((record) => record.meta && record.meta.hasTopNav);
    },
  },
  methods: {
    ...mapActions({
      logout: 'authenticate/logout',
    }),
    signOut() {
      this.logout();
      this.$router.push({ name: 'SignIn' });
    },
  },
};
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

    [v-cloak] {
        display: none;
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 0s ease;
    }

    .fade-leave {

    }

    .fade-leave-active {
        transition: opacity 0.0s ease;
        opacity: 0;
    }

/** Scrollbar */
#app {

  .custom-scrollable-container {
    scrollbar-width: thin;

    /* Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      width: 16px;
    }

    ::-webkit-scrollbar-track {
      background: #FFF;
    }
  }

  .freyda-scrollbar-thin {
    *::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    *::-webkit-scrollbar-track {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--background-System-_secondary-B, #F8F9FC);
    }
  }
}

/** Multiselect */
.multiselect-v2 {
  min-height: unset !important;

  .multiselect__tags {
    background: #f7fafa;
    border: 1px solid var(--stroke-_secondary, #f2f2f2) !important;
    border-radius: 8px;

    padding: 6px 40px 6px 12px;
    min-height: 36px !important;
    min-width: 200px;
  }

  .multiselect__placeholder {
    padding: 0 !important;
    margin: 0 !important;
  }

  .multiselect__select {
    height: 100%;
    z-index: 2;
  }

  .multiselect__single, .multiselect__input {
    background-color: transparent;
  }

  .multiselect__input, .multiselect__content, .multiselect__single { /* stylelint-disable-line */
    /* Body/Body 2 */
    color: var(--foreground-_Primary, #1a2c2d) !important;
    font-family: Arial;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0;
    text-overflow: ellipsis;
  }

   .multiselect__placeholder, .multiselect__input::placeholder { /* stylelint-disable-line */
    color: var(--foreground-_secondary, #999) !important;

    /* Body/Body 2 */
    font-family: Arial;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .multiselect__spinner {  /* stylelint-disable-line */
    background: #f7fafa;
  }

  .multiselect__spinner::before { /* stylelint-disable-line */
    border-color: #426d70;
  }

  &.multiselect--disabled {
    border-radius: 8px;
  }
}
</style>
