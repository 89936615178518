<template>
  <div :class="customClass">
    <textarea
      v-if="isTextArea"
      class="text-input"
      :name="name"
      :type="inputType"
      :value="modelValue"
      :required="required"
      :placeholder="placeholder"
      :disabled="isDisabled"
      autocomplete="off"
      rows="3"
      :readonly="readOnly"
      @input="updateValue($event.target.value)"
      @blur="onBlur"
      @focus="onFocus"
    />
    <input
      v-else
      :class="{'text-input': true, 'input-no-value': showNoValueStyling && !valueIsSet, 'text-input-with-trailing-icon': showEditButton }"
      :name="name"
      :type="inputType"
      :value="modelValue"
      :required="required"
      :placeholder="placeholder"
      :readonly="readOnly"
      :disabled="isDisabled"
      autocomplete="off"
      @input="updateValue($event.target.value)"
      @blur="onBlur"
      @focus="onFocus"
    >
    <button
      v-if="showEditButton"
      class="text-input-edit-button"
    >
      <EditIcon />
    </button>
    <AlertCircleIcon
      v-if="errorMessage.length"
      v-tooltip="errorMessage"
      class="text-input-error-message-icon"
    />
  </div>
</template>

<script>
import { AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import isString from '../../store/helpers/isString';
import EditIcon from '../icons/EditIcon.vue';

export default {
  components: {
    EditIcon,
    AlertCircleIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    customClass: {
      type: [Object, Array],
      default: () => ({}),
    },
    modelValue: {
      validator: (propValue) => isString(propValue) || !Number.isNaN(propValue) || propValue === null,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showNoValueStyling: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    isTextArea: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['focus', 'blur', 'update:modelValue'],
  computed: {
    valueIsSet() {
      return !(this.modelValue === '' || this.modelValue === null);
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
  },
};
</script>
<style scoped lang="scss">
/** V2 UI/UX Refresh */
.text-input-v2 {
  position: relative;

  textarea {
    resize: none;
    min-height: 140px;
  }

  .text-input {
    height: 36px;

    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    background: #F7FAFA;

    color: #1A2C2D;
    /* Body/Body 2 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    &.text-input-with-trailing-icon {
      padding-right: 28px;
    }
  }

  .text-input-edit-button {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    height: 100%;
    display: flex;
    color: #426d70;

    svg {
      margin: auto;
    }
  }

  .text-input-error-message-icon {
    position: absolute;
    top: 0;
    right: 4px;
    background: none;
    height: 100%;
    display: flex;
    color: red;

    svg {
      margin: auto;
    }
  }
}
</style>
