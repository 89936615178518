<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99984 0.708984C7.65466 0.708984 7.37484 0.988806 7.37484 1.33398C7.37484 1.67916 7.65466 1.95898 7.99984 1.95898C11.3366 1.95898 14.0415 4.66393 14.0415 8.00065C14.0415 11.3374 11.3366 14.0423 7.99984 14.0423C7.65466 14.0423 7.37484 14.3221 7.37484 14.6673C7.37484 15.0125 7.65466 15.2923 7.99984 15.2923C12.0269 15.2923 15.2915 12.0277 15.2915 8.00065C15.2915 3.97357 12.0269 0.708984 7.99984 0.708984Z"
      fill="currentColor"
    />
    <path
      d="M4.27511 5.94259C4.51919 5.69851 4.51919 5.30279 4.27511 5.05871C4.03103 4.81463 3.63531 4.81463 3.39123 5.05871L0.891229 7.55871C0.647152 7.80279 0.647152 8.19851 0.891229 8.44259L3.39123 10.9426C3.63531 11.1867 4.03103 11.1867 4.27511 10.9426C4.51919 10.6985 4.51919 10.3028 4.27511 10.0587L2.84205 8.62565H9.6665C10.0117 8.62565 10.2915 8.34583 10.2915 8.00065C10.2915 7.65547 10.0117 7.37565 9.6665 7.37565H2.84205L4.27511 5.94259Z"
      fill="currentColor"
    />
  </svg>
</template>
