<template>
  <table class="freyda-table">
    <tr class="freyda-table-header">
      <slot name="headers" />
    </tr>
    <slot name="rows" />
  </table>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
.freyda-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 !important;
  width: 100%;

  .freyda-table-cell {
    padding: 8px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: #FFF;

    border-right: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
  }
}
</style>
