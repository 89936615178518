<template>
  <ul class="check-list">
    <li
      v-for="(item, index) in checkList"
      :key="index"
      class="check-item"
    >
      <span class="icon-container-static">
        <check-circle-icon
          size="1x"
          :stroke="item.checked ? '#138641' : 'black'"
        />
      </span>
      <span>{{ item.text }}</span>
    </li>
  </ul>
</template>
<script>
import { CheckCircleIcon } from '@zhuowenli/vue-feather-icons';

export default {
  components: { CheckCircleIcon },
  props: {
    checkList: { // e.g. [{text: 'a', checked: true}]
      required: true,
      type: Array,
    },
  },

};
</script>
