import { userHasPermission } from './permissions';

type Role = {
  display: string,
  value: string,
  color: string,
  info?: string,
  // A user with a given role must have this set of permissions
  permissions: string[],
  // A user with a given role must not have this set of permissions
  notPermitted: string[],
};

const ROLES: { [key: string]: Role } = {
  ADMIN: {
    display: 'Admin',
    value: 'ADMIN',
    color: '#D3D2FF',
    info: 'An Admin is a user who has full user management permissions.',
    permissions: ['CAN_LIST_USERS', 'CAN_ADD_USERS', 'CAN_UPDATE_USERS', 'CAN_UPDATE_USER_ACCOUNT_STATUS'],
    notPermitted: [],
  },
  MEMBER: {
    display: 'Member',
    value: 'MEMBER',
    color: '#C2E9FA',
    permissions: [],
    notPermitted: ['CAN_ADD_USERS', 'CAN_UPDATE_USERS', 'CAN_UPDATE_USER_ACCOUNT_STATUS'],
  },
};

function userRole(userPermissionLevel: number): Role {
  const isAdmin = ROLES.ADMIN.permissions.every(
    (permission) => userHasPermission(permission, userPermissionLevel),
  );
  return isAdmin ? ROLES.ADMIN : ROLES.MEMBER;
}

export {
  ROLES,
  Role,
  userRole,
};
