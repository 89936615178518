import { EntityRelationshipsPage, GetRelatedEntitiesResponse } from './types';

export default function transformBackendResponse({
  entityName,
  response,
  displayNameByFin,
}: {
  entityName: string,
  response: GetRelatedEntitiesResponse,
  displayNameByFin: { [key: string]: string },
}): EntityRelationshipsPage {
  const { count, totalCount, items } = response;
  const transformedResponse: EntityRelationshipsPage = {
    count,
    totalCount,
    items: items.map((item) => {
      const { relationship } = item;
      const {
        id, entityId, fin, relatedFin, relatedEntityId, timeCreated, timeUpdated,
      } = relationship;
      return ({
        id,
        timeCreated,
        timeUpdated,
        entity: {
          id: entityId,
          type: {
            fin,
            displayName: displayNameByFin[fin] ?? 'Unknown',
          },
          name: entityName,
        },
        relatedEntity: {
          id: relatedEntityId,
          type: {
            fin: relatedFin,
            displayName: displayNameByFin[relatedFin] ?? 'Unknown',
          },
          name: item.name,
        },
      });
    }),
  };

  return transformedResponse;
}
