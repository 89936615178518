import isSet from '../isSet';

const initialClientDataPoint = (text, value, effectors, userModified, page, confidence) => ({
  text: isSet(text) ? text : '',
  value: isSet(value) ? value : '',
  userModified: isSet(userModified) ? userModified : false,
  confidence,
  effectors: isSet(effectors) ? effectors : {},
  page: isSet(page) ? page : null,
});

export default initialClientDataPoint;
