import VueLogger from 'vuejs3-logger';
import { createApp } from 'vue';

/**
 * --- Information ---
 *
 * To log, use this module instead of plain 'console.log()' statements.
 * This module sets different log levels to be printed in the right environment.
 * There are 5 log levels in this order: 'debug', 'info', 'warn', 'error', 'fatal'.
 * Default is set to 'error'.
 *
 * --- Usage ---
 *
 * > In component:
 *     this.$log.info('message');
 *
 * > In external function:
 *     import { logger } from '@/store/logger';
 *     logger.info('message');
 *
 * --- Package ---
 *
 * vuejs3-logger
 * Link: https://github.com/MarcSchaetz/vuejs3-logger
 */

let logLevel = 'error';
if (process.env.NODE_ENV === 'development') {
  logLevel = 'debug';
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_API_ENDPOINT.includes('integrated')) {
    logLevel = 'debug';
  } else if (process.env.VUE_APP_API_ENDPOINT.includes('test')) {
    logLevel = 'info';
  }
}

const loggerOptions = {
  isEnabled: true,
  logLevel,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

const app = createApp({}); // Dummy app to be used as logger in other js modules
app.use(VueLogger, loggerOptions);
const logger = app.config.globalProperties.$log;

export { VueLogger, loggerOptions, logger };
