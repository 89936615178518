import { getMappingFor, getMappingFrom } from './mappings';

/* Progress in mapping temporary until we fetch from backend */
const extractionProgressMapping = {
  DOC_REQUESTED: {
    backend: 'DOC_REQUESTED',
    display: 'Document Requested',
    progress: 5,
  },
  DOC_UPLOADED: {
    backend: 'DOC_UPLOADED',
    display: 'Document Uploaded',
    progress: 10,
  },
  EXT_START_PDF_TO_DOC: {
    backend: 'EXT_START_PDF_TO_DOC',
    display: 'Extracting',
    progress: 20,
  },
  EXT_START_DOC_TO_GRAPH: {
    backend: 'EXT_START_DOC_TO_GRAPH',
    display: 'Extracting',
    progress: 40,
  },
  EXT_START_LAYOUT_TAGGER: {
    backend: 'EXT_START_LAYOUT_TAGGER',
    display: 'Extracting',
    progress: 60,
  },
  EXT_START_METRIC_QUERY: {
    backend: 'EXT_START_METRIC_QUERY',
    display: 'Extracting',
    progress: 75,
  },
  EXT_MANUAL_QA: {
    backend: 'EXT_MANUAL_QA',
    display: 'Extracting',
    progress: 90,
  },
  EXT_DONE: {
    backend: 'EXT_DONE',
    display: 'Extraction Finished',
    progress: 100,
  },
  EXT_PREPARED: {
    backend: 'EXT_PREPARED',
    display: 'Extraction Prepared',
    progress: 0,
  },
  EXT_FAILED: {
    backend: 'EXT_FAILED',
    display: 'Extraction Failed',
    progress: -1,
  },
  EXT_FAILED_DUPLICATE: {
    backend: 'EXT_FAILED_DUPLICATE',
    display: 'Duplicate document',
    progress: -2,
  },
  EXT_FAILED_INVALID_DOCUMENT: {
    backend: 'EXT_FAILED_INVALID_DOCUMENT',
    display: 'Invalid document',
    progress: -7,
  },
  EXT_FAILED_PASSWORD: {
    backend: 'EXT_FAILED_PASSWORD',
    display: 'Password-protected',
    progress: -5,
  },
  EXT_FAILED_UNSUPPORTED_TYPE: {
    backend: 'EXT_FAILED_UNSUPPORTED_TYPE',
    display: 'Unsupported document type',
    progress: -6,
  },
};

const extractionProgressMappingFromBackend = getMappingFrom(extractionProgressMapping, 'backend');
const extractionProgressMappingToBackend = getMappingFor(extractionProgressMapping, 'backend');
const extractionProgressToDisplay = getMappingFor(extractionProgressMapping, 'display');

export {
  extractionProgressMapping,
  extractionProgressMappingFromBackend,
  extractionProgressMappingToBackend,
  extractionProgressToDisplay,
};
