import Api from '@/store/helpers/api';

/**
 * DEVELOPMENT WARNING: These queries use the outdated interdocumententity
 * endpoint. The response format differs to the inter-document-entities/overview
 * endpoint.
 * Recommend to use only when the overview endpoint can't be used (e.g., if
 * you want a full list of entities returned or want to fetch an entity by id).
 *
 */

export function getEntitiesByNameMatch(
  idToken: string, { name, fin, include_entity_datapoints = false }: {
    name: string, fin?: string, include_entity_datapoints?: boolean,
  },
) {
  return (new Api(process.env, idToken))
    .post('interdocumententity', { filters: { name, ...fin && { fin }, include_entity_datapoints } });
}

export async function isEntityNameTaken(
  idToken: string, name: string,
): Promise<boolean | null> {
  try {
    const res = await (new Api(process.env, idToken))
      .post('interdocumententity', { filters: { name } });
    const entityWithSameName = res.entities.find((e: { name: string }) => e.name === name);
    return !!entityWithSameName;
  } catch (err) {
    return null;
  }
}

export async function getEntityById(
  idToken: string, id: string,
): Promise<any | null> {
  try {
    const res = await (new Api(process.env, idToken))
      .post('interdocumententity', { filters: { uuids: [id] } });
    return res.entities[0];
  } catch (err) {
    return null;
  }
}
