export default {
  beforeMount: (element, binding) => {
    const el = element;
    el.clickOutsideEvent = (event) => {
      // Is click outside of click and children:
      if (!(el.isEqualNode(event.target) || el.contains(event.target))) {
        // call function in `binding` attribute
        binding.value();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};
