<template>
  <div
    class="explorer"
    :style="{width: '100%'}"
  >
    <div
      class="explorer-content-main"
    >
      <loading-overlay
        v-show="userStoreIsLoading"
        :overlay-opaque="true"
        :style="{'background': 'transparent'}"
      />
      <users-table
        :users="users"
        @userSelected="onUserSelected"
      />
    </div>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import { allowedStates } from '@/store/helpers/storeState';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import UsersTable from './UsersTable.vue';

export default {
  components: {
    UsersTable,
    LoadingOverlay,
  },
  emits: ['userSelected'],
  data() {
    return {
      toast: useToast(),
      showDeactivateUserModal: false,
    };
  },
  computed: {
    ...mapGetters({
      users: 'users/users',
      ueState: 'users/storeStatus',
    }),
    userStoreIsLoading() {
      return this.ueState === allowedStates.IS_LOADING;
    },
  },
  methods: {
    ...mapActions({
      updateUser: 'users/updateUser',
    }),

    onUserSelected(id) {
      this.$log.info('User selected with this id:', id);
      this.$emit('userSelected', id);
    },
  },
};
</script>
