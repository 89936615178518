const columnHeaders = [
  {
    id: 'name',
    label: 'Relationship',
    columnWidth: '70%',
  },
  {
    id: 'timeCreated',
    label: 'Date Created',
    columnWidth: '30%',
  },
];

const DEFAULT_MAX_PAGE_SIZE = 20;

export { columnHeaders, DEFAULT_MAX_PAGE_SIZE };
