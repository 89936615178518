/* eslint-disable global-require, import/no-unresolved */
import { logger } from '@/store/logger';

const flattenNestedWordAnnotations = (language) => {
  let annotations = {};
  try {
    if (language === 'en') {
      annotations = require('../../../../public/local_documents/performance_annotations_en.json');
    } else {
      annotations = require('../../../../public/local_documents/performance_annotations.json');
    }
    logger.info('Annotations:', annotations);
    return annotations;
  } catch (e) {
    logger.warn('No annotations found', e);
    return {};
  }
};

export default (language = 'native') => flattenNestedWordAnnotations(language);
