export default () => ([
  {
    name: 'am_capital_account',
    display_name: 'Capital Account',
  },
  {
    name: 'am_capital_call',
    display_name: 'Capital Call',
  },
  {
    name: 'am_capital_distribution',
    display_name: 'Capital Distribution',
  },
  {
    name: 'am_performance',
    display_name: 'Performance',
  },
]);
