<template>
  <div class="sign-in">
    <form
      class="login-form-container"
      @submit.prevent="onResetAndLogin"
    >
      <div
        class="login-item"
      >
        Please enter the verification code sent to:
        <pre>{{ email }}</pre>
      </div>
      <div
        class="login-item"
      >
        <div class="login-input-group">
          <label
            class="form-label"
            for="verificationCode"
          ><b>Verification Code</b></label>
          <input
            id="verificationCode"
            v-model="verificationCode"
            class="text-input"
            type="number"
            name="verificationCode"
            required
          >
        </div>
      </div>
      <hr>
      <div class="login-item">
        Password requirements:
        <check-list :check-list="passwordRequirements" />
      </div>
      <div class="login-item login-input-group">
        <label
          class="form-label"
          for="password"
        ><b>Password</b></label>
        <input
          id="password"
          v-model="password"
          class="text-input"
          type="password"
          name="psw"
          required
        >
      </div>
      <div class="login-item login-input-group">
        <label
          class="form-label"
          for="confirm-password"
        ><b>Confirm password</b></label>
        <input
          id="confirm-password"
          v-model="confirmPassword"
          class="text-input"
          type="password"
          name="psw-confirm"
          required
        >
      </div>

      <div class="login-button">
        <button
          class="btn-primary"
          type="submit"
        >
          Change and Login
        </button>
      </div>
      <div
        class="login-button"
        style="margin-top: 16px;"
      >
        <router-link
          :to="{name: 'SignIn'}"
          tag="button"
          class="btn-neg"
        >
          Back to Login
        </router-link>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import CheckList from '../general/CheckList.vue';

export default {
  name: 'ResetPassword',
  components: { CheckList },
  data() {
    return {
      toast: useToast(),
      email: '',
      verificationCode: '',
      password: '',
      confirmPassword: '',
    };
  },
  computed: {
    ...mapGetters({}),
    isPasswordLongEnough() {
      return this.password && this.password.length >= 8;
    },
    isPasswordAlphaNumeric() {
      return this.password && this.password.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/);
    },
    doesPasswordMatchConfirmation() {
      return this.password && this.password === this.confirmPassword;
    },
    passwordRequirements() {
      return [
        { text: 'More than 8 characters', checked: this.isPasswordLongEnough },
        { text: 'Have at least one letter and a number', checked: this.isPasswordAlphaNumeric },
        { text: 'Exactly match the confirmation password', checked: this.doesPasswordMatchConfirmation },
      ];
    },
  },
  mounted() {
    this.parseUserAttributesAndReset(this.$route.query.userAttributesStr);
    this.email = this.userAttributes.email;
  },
  methods: {
    ...mapActions({
      login: 'authenticate/login',
      resetPasswordWithVerificationCode: 'authenticate/resetPasswordWithVerificationCode',
    }),
    parseUserAttributesAndReset(userAttributesStr) {
      try {
        this.userAttributes = JSON.parse(userAttributesStr);
      } catch (e) {
        this.$log.error(e);
        this.$router.push({ name: 'SignIn' });
      }
      if (!this.userAttributes || !this.userAttributes.email) {
        this.$log.info('raw user attributes:', this.userAttributesStr, 'parsed:', this.userAttributes);
        this.toast.error('Email validation error, please contact a Freyda administrator if this issue persists');
        this.$router.push({ name: 'SignIn' });
      }
      this.reset();
    },
    reset() {
      this.password = '';
      this.confirmPassword = '';
    },
    onResetAndLogin() {
      for (const req of this.passwordRequirements) { // eslint-disable-line no-restricted-syntax
        if (!req.checked) {
          this.toast.error(`Failed password requirement: ${req.text}`);
          return;
        }
      }

      this.$log.info('passwords match, submitting for change and login');
      this.resetPasswordWithVerificationCode({
        newPassword: this.password,
        userAttributes: this.userAttributes,
        verificationCode: String(this.verificationCode),
      })
        .then((r) => {
          this.$log.info('Password change successful:', r);
          this.login(r);
          this.toast.success('Password change successful');
        })
        .catch((e) => {
          this.$log.info('password change error:', e);
          this.toast.error('Password change failed');
        });
    },
  },
};
</script>
