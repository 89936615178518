import axios from 'axios';
import { logger } from '@/store/logger';
import {
  storeState, storeStateActions, storeStateGetters, storeStateMutations,
} from './helpers/storeState';
import Api from './helpers/api';

const store = {
  namespaced: true,
  state: {
    ...storeState,
  },

  getters: {
    ...storeStateGetters,
  },

  mutations: {
    ...storeStateMutations,
    SET_CLIENT_VALUE(state, { field, val }) {
      state.metrics[field.index].clientDataPoint = val;
    },
  },

  actions: {
    ...storeStateActions,

    /* Upload a new document */
    postDocumentRequest: async ({ dispatch }, { file, postVars }) => {
      const path = 'documentrequest';
      const { CancelToken } = axios;
      let cancelFunc = null;
      const cancelTokenExec = new CancelToken(((c) => {
        // An executor function receives a cancel function as a parameter
        cancelFunc = c;
      }));

      // ** Pre-signed URL:
      const postBody = {
        filename: postVars.fileName,
        doc_type: postVars.docType,
      };
      logger.debug('POST documentRequest', postVars, path, '*body:', postBody);
      const req = dispatch('postForPreSignedUrl', { path, postBody })
        .then((r) => {
          logger.debug('retrieved signed response', r);
          return r;
        })
        .then((signedResponse) => dispatch('postFile', { signedResponse, file, cancelTokenExec }))
        .catch((e) => {
          logger.debug(e);
          logger.debug('error getting signed response:', e.response);
          throw e;
        });

      return { req, cancelFunc };
    },
    postFile: async (_, { signedResponse, file, cancelTokenExec }) => {
      // ** Construct Form Data:
      const formData = new FormData();
      Object.keys(signedResponse.fields).forEach((key) => {
        formData.append(key, signedResponse.fields[key]);
      });
      formData.append('file', file, file.name); // Actual file has to be appended last.

      return Api.postFile(signedResponse.url, formData, cancelTokenExec)
        .then((r) => {
          logger.debug('postFile r:', r);
          return { ...r, documentRequestId: signedResponse.doc_request_id };
        })
        .catch((e) => {
          logger.debug('Failed postFile, e:', e, 'formData:', formData);
          throw e;
        });
    },
    postForPreSignedUrl: async ({ rootGetters }, { path, postBody }) => {
      logger.debug('posting for presigned url');
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(path, postBody);
    },
  },
};

export default store;
