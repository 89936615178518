/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { ActionContext } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import {
  allowedStates, storeState, storeStateActions, storeStateGetters, storeStateMutations,
} from '../helpers/storeState';
import {
  APISchemaDefinition,
  CreateSchemaRequest,
  Getters,
  PatchSchemaRequest,
  Schema,
  SchemaMetric,
  SchemaMetricGroup,
  SchemaSubGroup,
  State,
} from './types';
import { logger } from '../logger';
import Api from '../helpers/api';
import {
  createDraftCopySchemaName,
  EMPTY_SCHEMA, getAllMetrics,
} from './helpers';
import {
  validateAddMetricGroup,
  validateUpdateMetricGroup,
  validateUpdatedSchemaMetrics,
  validateUpdateSubGroupName,
} from './schemaUpdateRequestValidators';
import {
  transformAPISchemaDefinition,
  transformMetricDetailsToSchemaMetric,
  transformSchema,
} from './transformers';
import { MetricDetails } from '../metric/types';

const initialState = (): State => ({
  ...storeState,
  schema: null,
  originalSchema: null,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  originalSchema: (state: State) => state.originalSchema,
  schema: (state: State) => state.schema,
  schemaName: (state: State) => state.schema?.name ?? '',
  metricGroups: (state: State) => state.schema?.groups ?? null,
  getMetricGroupByDisplayName: (state: State) => (displayName: string) => {
    if (!state.schema) { return ({ metricGroupIndex: -1, metricGroup: null }); }
    const metricGroupIndex = state.schema.groups.findIndex(
      (group: SchemaMetricGroup) => group.displayName === displayName,
    );
    if (metricGroupIndex !== -1) {
      const metricGroup = state.schema.groups[metricGroupIndex];
      return ({ metricGroupIndex, metricGroup });
    }
    return ({ metricGroupIndex: -1, metricGroup: null });
  },
  getSubGroupFromMetricGroup: (state: State) => (metricGroup: SchemaMetricGroup, subGroupDisplayName: string) => {
    if (!state.schema) { return ({ subGroupIndex: -1, subGroup: null }); }
    const subGroupIndex = metricGroup.subGroups.findIndex(
      (subGroup: SchemaSubGroup) => subGroup.displayName === subGroupDisplayName,
    );
    if (subGroupIndex !== -1) {
      const subGroup = metricGroup.subGroups[subGroupIndex];
      return ({ subGroupIndex, subGroup });
    }
    return ({ subGroupIndex: -1, subGroup: null });
  },
  allSchemaMetrics: (state: State) => (state.schema?.groups?.length ? getAllMetrics(state.schema.groups) : []),
  getAllMetricsInMetricGroup: (state: State) => (displayName: string) => {
    const metricGroup = state.schema?.groups?.find((group) => group.displayName === displayName);
    if (!metricGroup) { return []; }
    return [
      ...metricGroup.generalMetrics,
      ...metricGroup.subGroups.map((subGroup) => subGroup.metrics).flat(1),
    ];
  },
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_SCHEMA(state: State, schema: Schema) {
      state.schema = schema;
    },
    SET_ORIGINAL_SCHEMA(state: State, originalSchema: Schema) {
      state.originalSchema = originalSchema;
    },
    SET_SCHEMA_NAME(state: State, name: string) {
      if (state.schema) {
        state.schema.name = name;
      }
    },
    SET_SCHEMA_IS_PUBLISHED(state: State, isPublished: boolean) {
      if (state.originalSchema) {
        state.originalSchema.isPublished = isPublished;
      }
      if (state.schema) {
        state.schema.isPublished = isPublished;
      }
    },
    RESET(state: State) {
      logger.debug('Reseting state of schemaBuilder store');
      Object.assign(state, initialState());
    },
  },
  actions: {
    ...storeStateActions,
    init: async ({ commit, dispatch }: ActionContext<State, any>,
      { documentSchemaId }: { documentSchemaId: string }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing schemaBuilder store');
        const apiSchemaDefinition: APISchemaDefinition = await dispatch('getSchema', { documentSchemaId });
        const schema = transformAPISchemaDefinition(apiSchemaDefinition);
        commit('SET_ORIGINAL_SCHEMA', cloneDeep(schema));
        commit('SET_SCHEMA', cloneDeep(schema));
      } catch (e) {
        logger.debug('Failed to initialise schemaBuilder store', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    initNew: async ({ commit, rootGetters }: ActionContext<State, any>,
      { name }: { name: string }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing schemaBuilder store');
        const reportingDateMetric: MetricDetails = await (new Api(process.env, rootGetters['authenticate/idToken']))
          .post('schemas/metric-identifiers/get', { fin: 'reporting_date' } as any);
        const generalMetrics = [...reportingDateMetric ? [transformMetricDetailsToSchemaMetric(reportingDateMetric)] : []];
        const schema = { ...EMPTY_SCHEMA, name };
        schema.groups[0].generalMetrics = generalMetrics;
        commit('SET_SCHEMA', schema);
      } catch (e) {
        logger.debug('Failed to initialise schemaBuilder store', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    initNewFromTemplate: async ({ commit, dispatch }: ActionContext<State, any>,
      { templateSchemaId }: { templateSchemaId: string }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing schemaBuilder store by copying template schema', templateSchemaId);
        const templateSchemaApiDefinition: APISchemaDefinition = await dispatch('getSchema', { documentSchemaId: templateSchemaId });
        const templateSchema = transformAPISchemaDefinition(templateSchemaApiDefinition);
        const newSchemaName = createDraftCopySchemaName(templateSchema.name);
        const schema: Schema = {
          documentSchemaId: '',
          name: newSchemaName,
          description: templateSchema.description,
          isTemplate: false,
          isPublished: false,
          versionedDocumentTypes: [],
          groups: templateSchema.groups,
        };
        commit('SET_SCHEMA', schema);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        logger.debug('Failed to initialise schemaBuilder store', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    refreshSchema: async ({ commit }: ActionContext<State, any>,
      { apiSchemaDefinition }: { apiSchemaDefinition: APISchemaDefinition }): Promise<void> => {
      try {
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Refreshing schema');
        const schema = transformAPISchemaDefinition(apiSchemaDefinition);
        commit('SET_ORIGINAL_SCHEMA', cloneDeep(schema));
        commit('SET_SCHEMA', cloneDeep(schema));
      } catch (e) {
        logger.debug('Failed to refresh schema', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    getSchema: async ({ rootGetters }: ActionContext<State, any>,
      { documentSchemaId }: { documentSchemaId: string }): Promise<APISchemaDefinition> => {
      logger.debug(`Fetching schema -- id ${documentSchemaId}`);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get(`schemas/${documentSchemaId}`);
    },

    saveSchema: async ({ rootGetters, getters, dispatch }: ActionContext<State, any>) => {
      logger.debug(`Saving schema -- id ${getters.schema.documentSchemaId}`);
      const schemaApiDefinition: APISchemaDefinition = transformSchema(getters.schema);
      logger.debug('Schema transformed to API Defined Schema: ', schemaApiDefinition);
      const request: PatchSchemaRequest = {
        groups: schemaApiDefinition.groups.map((group) => ({
          ...group,
          metrics: group.metrics.map((metric) => ({
            fin: metric.fin,
            displayName: metric.displayName,
            description: metric.description,
            filterGroup: metric.filterGroup,
            isRequired: metric.isRequired,
            metricSources: metric.metricSources,
          })),
        })),
        name: schemaApiDefinition.name,
      };
      logger.debug('API Schema definition converted into patch request:', request);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .patch(`schemas/${getters.schema.documentSchemaId}`, request).then(async (response) => {
          await dispatch('refreshSchema', { apiSchemaDefinition: response });
        });
    },

    publishSchema: async (
      { rootGetters, getters, commit }: ActionContext<State, any>,
    ) => {
      logger.debug(`Publishing schema -- id ${getters.schema.documentSchemaId}`);
      const response = await (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(`schemas/${getters.schema.documentSchemaId}/publish`, {});
      commit('SET_SCHEMA_IS_PUBLISHED', true);
      return response;
    },

    createSchema: async ({ rootGetters, getters, dispatch }: ActionContext<State, any>) => {
      logger.debug('Creating schema');
      const schemaApiDefinition: APISchemaDefinition = transformSchema(getters.schema);
      logger.debug('Schema transformed to API Defined Schema: ', schemaApiDefinition);
      const request: CreateSchemaRequest = {
        name: schemaApiDefinition.name,
        description: schemaApiDefinition.description,
        isTemplate: schemaApiDefinition.isTemplate,
        isPublished: schemaApiDefinition.isPublished,
        groups: schemaApiDefinition.groups.map((group) => ({
          ...group,
          metrics: group.metrics.map((metric) => ({
            fin: metric.fin,
            isRequired: metric.isRequired,
            displayName: metric.displayName,
            description: metric.description,
            filterGroup: metric.filterGroup,
            metricSources: metric.metricSources,
          })),
        })),
      };
      logger.debug('API Schema definition converted into create request:', request);
      const response = await (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('schemas', request);
      await dispatch('refreshSchema', { apiSchemaDefinition: response });
    },

    updateMetric: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricFin,
        updatedMetric,
        metricGroupDisplayName,
        subGroupDisplayName,
      }: {
        metricFin: string,
        updatedMetric: SchemaMetric,
        metricGroupDisplayName: string,
        subGroupDisplayName?: string,
      },
    ) => {
      const updatedSchema: Schema = cloneDeep(getters.schema);
      const { metricGroup, metricGroupIndex }: { metricGroup: SchemaMetricGroup, metricGroupIndex: number } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to update metric. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      if (subGroupDisplayName) {
        const { subGroup, subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, subGroupDisplayName);
        if (subGroupIndex === -1) {
          logger.warn(`Failed to update metric. Sub group with displayName ${subGroupDisplayName} was not found in metric group with displayName ${metricGroupDisplayName}`);
          throw Error('Sub Group Not Found');
        }
        const metricIndex = subGroup.metrics.findIndex((metric: SchemaMetric) => metric.fin === metricFin);
        updatedSchema.groups[metricGroupIndex].subGroups[subGroupIndex].metrics[metricIndex] = updatedMetric;
        commit('SET_SCHEMA', updatedSchema);
        return;
      }

      const metricIndex = metricGroup.generalMetrics.findIndex((metric: SchemaMetric) => metric.fin === metricFin);
      updatedSchema.groups[metricGroupIndex].generalMetrics[metricIndex] = { ...updatedMetric };
      commit('SET_SCHEMA', updatedSchema);
    },

    updateSubGroupDisplayName: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        currentName,
        newName,
      }: { metricGroupDisplayName: string, currentName: string, newName: string },
    ) => {
      const { metricGroup, metricGroupIndex }: { metricGroup: SchemaMetricGroup, metricGroupIndex: number } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to update name of sub group. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      const { subGroup, subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, currentName);
      if (subGroupIndex === -1) {
        logger.warn(`Failed to update subgroup name. Sub group with displayName ${currentName} was not found in metric group with displayName ${metricGroupDisplayName}`);
        throw Error('Sub Group Not Found');
      }

      const validation = validateUpdateSubGroupName(metricGroup, newName);
      if (!validation.isValid) {
        throw Error('Invalid Request');
      }

      const updatedSchema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex].subGroups[subGroupIndex] = {
        ...subGroup,
        displayName: newName,
      };

      commit('SET_SCHEMA', updatedSchema);
    },

    toggleSubGroupTimePeriodFilter: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        subGroupDisplayName,
      }: { metricGroupDisplayName: string, subGroupDisplayName: string },
    ) => {
      const { metricGroup, metricGroupIndex }: { metricGroup: SchemaMetricGroup, metricGroupIndex: number } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to toggle sub-group time period filter. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      const { subGroup, subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, subGroupDisplayName);
      if (subGroupIndex === -1) {
        logger.warn(`Failed to toggle sub-group time period filter. Sub group with displayName ${subGroupDisplayName} was not found in metric group with displayName ${metricGroupDisplayName}`);
        throw Error('Sub Group Not Found');
      }

      const updatedSchema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex].subGroups[subGroupIndex] = {
        ...subGroup,
        enableTimePeriodFilter: !subGroup.enableTimePeriodFilter,
      };

      commit('SET_SCHEMA', updatedSchema);
    },

    updateSchemaName: async (
      { commit }: ActionContext<State, any>,
      name: string,
    ) => {
      commit('SET_SCHEMA_NAME', name);
    },
    addMetricGroup: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroup,
      }: { metricGroup: SchemaMetricGroup },
    ) => {
      const validation = validateAddMetricGroup(getters.schema, metricGroup);
      if (!validation.isValid) {
        throw Error('Invalid Request');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups = [
        ...updatedSchema.groups,
        metricGroup,
      ];
      commit('SET_SCHEMA', updatedSchema);
    },

    partiallyUpdateMetricGroup: async (
      { getters, commit }: ActionContext<State, any>,
      {
        displayName,
        partialUpdates,
      }: { displayName: string, partialUpdates: Partial<SchemaMetricGroup> },
    ) => {
      const { metricGroup, metricGroupIndex }: { metricGroup: SchemaMetricGroup, metricGroupIndex: number } = getters.getMetricGroupByDisplayName(displayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to update metric group. Metric group with displayName ${displayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const updatedMetricGroup = {
        ...metricGroup,
        ...partialUpdates,
      };
      const validation = validateUpdateMetricGroup(getters.schema, metricGroup, updatedMetricGroup);
      if (!validation.isValid) {
        throw Error('Invalid Request');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex] = updatedMetricGroup;
      commit('SET_SCHEMA', updatedSchema);
      return updatedMetricGroup;
    },

    updateMetricGroupsOrder: async ({ getters, commit }: ActionContext<State, any>,
      {
        orderedMetricGroupDisplayNames,
      }: { orderedMetricGroupDisplayNames: string[] }) => {
      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups.sort((a, b) => orderedMetricGroupDisplayNames.indexOf(a.displayName) - orderedMetricGroupDisplayNames.indexOf(b.displayName));
      commit('SET_SCHEMA', updatedSchema);
    },

    addMetricsToSubGroup: async ({ getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        subGroupDisplayName,
        metrics,
      }: { metricGroupDisplayName: string, subGroupDisplayName: string, metrics: SchemaMetric[] }) => {
      logger.debug('Adding metrics to sub group', metrics);
      const { metricGroup, metricGroupIndex }: { metricGroup: SchemaMetricGroup, metricGroupIndex: number } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to add metrics to metric group subgroup. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const { subGroup, subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, subGroupDisplayName);
      if (subGroupIndex === -1) {
        logger.warn(`Failed to add metrics to metric group subgroup. Sub group with displayName ${subGroupDisplayName} was not found in metric group with displayName ${metricGroupDisplayName}`);
        throw Error('Sub Group Not Found');
      }

      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex].subGroups[subGroupIndex] = {
        ...subGroup,
        metrics: [...subGroup.metrics, ...metrics],
      };

      const validation = validateUpdatedSchemaMetrics(updatedSchema);
      if (!validation.isValid) {
        logger.warn('Failed to add metrics to sub group:', validation.errors);
        throw Error('Invalid Request');
      }
      commit('SET_SCHEMA', updatedSchema);
    },

    addGeneralMetricsToMetricGroup: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metrics,
        metricGroupDisplayName,
      }: { metrics: SchemaMetric[], metricGroupDisplayName?: string },
    ) => {
      logger.debug('Adding general metrics', metrics);
      const { metricGroup, metricGroupIndex }: { metricGroup: SchemaMetricGroup, metricGroupIndex: number } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to add metrics to metric group. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex] = {
        ...metricGroup,
        generalMetrics: [...metricGroup.generalMetrics, ...metrics],
      };

      const validation = validateUpdatedSchemaMetrics(updatedSchema);
      if (!validation.isValid) {
        logger.warn('Failed to add general metrics to metric group:', validation.errors);
        throw Error('Invalid Request');
      }

      commit('SET_SCHEMA', updatedSchema);
    },

    addMetricSubGroup: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        subGroup,
      }: { metricGroupDisplayName: string, subGroup: SchemaSubGroup },
    ) => {
      const {
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to add subgroup to metric group. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      const { subGroups } = updatedSchema.groups[metricGroupIndex];
      updatedSchema.groups[metricGroupIndex].subGroups = [
        subGroup,
        ...subGroups,
      ];
      commit('SET_SCHEMA', updatedSchema);
    },

    deleteGeneralMetric: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        metric,
      }: { metricGroupDisplayName: string, metric: SchemaMetric },
    ) => {
      const {
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to delete metric. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      const { generalMetrics } = updatedSchema.groups[metricGroupIndex];
      updatedSchema.groups[metricGroupIndex].generalMetrics = generalMetrics.filter((generalMetric) => generalMetric.fin !== metric.fin);
      commit('SET_SCHEMA', updatedSchema);
    },

    deleteMetricGroup: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
      }: { metricGroupDisplayName: string },
    ) => {
      const {
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to delete metric group. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups = updatedSchema.groups.filter((mG: SchemaMetricGroup) => mG.displayName !== metricGroupDisplayName);
      commit('SET_SCHEMA', updatedSchema);
    },

    deleteSubGroup: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        subGroupDisplayName,
      }: { metricGroupDisplayName: string, subGroupDisplayName: string },
    ) => {
      const {
        metricGroup,
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to delete sub group. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      const { subGroup, subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, subGroupDisplayName);
      if (subGroupIndex === -1) {
        logger.warn(`Failed to delete metric from subgroup. Sub group with displayName ${subGroupDisplayName} was not found in metric group with displayName ${metricGroupDisplayName}`);
        throw Error('Sub Group Not Found');
      }

      const reportingDateMetricInSubGroup = subGroup.metrics.find((metric: SchemaMetric) => metric.fin === 'reporting_date');
      const updatedSchema: Schema = cloneDeep(getters.schema);
      // Reporting date metric must not be deleted alongside a subgroup
      if (reportingDateMetricInSubGroup) {
        updatedSchema.groups[metricGroupIndex].generalMetrics = [reportingDateMetricInSubGroup, ...metricGroup.generalMetrics];
      }
      updatedSchema.groups[metricGroupIndex].subGroups = metricGroup.subGroups.filter((sG: SchemaSubGroup) => sG.displayName !== subGroupDisplayName);
      commit('SET_SCHEMA', updatedSchema);
    },

    deleteSubGroupMetric: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        subGroupDisplayName,
        metric,
      }: { metricGroupDisplayName: string, subGroupDisplayName: string, metric: SchemaMetric },
    ) => {
      const {
        metricGroup,
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to delete metric. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      const { subGroup, subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, subGroupDisplayName);
      if (subGroupIndex === -1) {
        logger.warn(`Failed to delete metric from subgroup. Sub group with displayName ${subGroupDisplayName} was not found in metric group with displayName ${metricGroupDisplayName}`);
        throw Error('Sub Group Not Found');
      }

      const updatedSchema: Schema = cloneDeep(getters.schema);
      const updatedSubGroupMetrics = subGroup.metrics
        .filter((subGroupMetric: SchemaMetric) => subGroupMetric.fin !== metric.fin);

      updatedSchema.groups[metricGroupIndex].subGroups[subGroupIndex].metrics = updatedSubGroupMetrics;
      commit('SET_SCHEMA', updatedSchema);
    },

    updateMetricGroupSubGroups: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        newSubGroupsList,
      }: { metricGroupDisplayName: string, newSubGroupsList: SchemaSubGroup[] },
    ) => {
      const {
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to update subgroups. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex].subGroups = newSubGroupsList;
      commit('SET_SCHEMA', updatedSchema);
    },

    updateMetricGroupGeneralMetrics: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        newGeneralMetricsList,
      }: { metricGroupDisplayName: string, newGeneralMetricsList: SchemaMetric[] },
    ) => {
      logger.debug('Updating general metrics to', newGeneralMetricsList);
      const {
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to update general metrics. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }
      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex].generalMetrics = newGeneralMetricsList;
      commit('SET_SCHEMA', updatedSchema);
    },

    updateSubGroupMetrics: async (
      { getters, commit }: ActionContext<State, any>,
      {
        metricGroupDisplayName,
        subGroupDisplayName,
        newSubGroupMetricsList,
      }: { metricGroupDisplayName: string, subGroupDisplayName: string, newSubGroupMetricsList: SchemaMetric[] },
    ) => {
      logger.debug('Updating sub group metrics to', newSubGroupMetricsList);
      const {
        metricGroup,
        metricGroupIndex,
      } = getters.getMetricGroupByDisplayName(metricGroupDisplayName);
      if (metricGroupIndex === -1) {
        logger.warn(`Failed to update subgroup's metrics. Metric group with displayName ${metricGroupDisplayName} was not found`);
        throw Error('Metric Group Not Found');
      }

      const { subGroupIndex } = getters.getSubGroupFromMetricGroup(metricGroup, subGroupDisplayName);
      if (subGroupIndex === -1) {
        logger.warn(`Failed to update subgroup's metrics. Sub group with displayName ${subGroupDisplayName} was not found in metric group with displayName ${metricGroupDisplayName}`);
        throw Error('Sub Group Not Found');
      }

      const updatedSchema: Schema = cloneDeep(getters.schema);
      updatedSchema.groups[metricGroupIndex].subGroups[subGroupIndex].metrics = newSubGroupMetricsList;
      commit('SET_SCHEMA', updatedSchema);
    },
  },
};

export default store;
