export default () => ({
  documentTypes: [],
  overallAverages: [
    {
      period: {
        from_date: '2021-08-01T09:47:21.271230',
        to_date: '2021-09-01T09:47:21.271311',
      },
      accuracy: 0.432673,
    },
    {
      period: {
        from_date: '2021-09-01T09:47:21.271311',
        to_date: '9999-12-31T23:59:59.999999',
      },
      accuracy: 0.5432543,
    },
  ],
  proportionMetricsVerified: [
    {
      period: {
        fromDate: '0001-01-01T00:00:00',
        toDate: '9999-12-31T23:59:59.999999',
      },
      metricsVerified: 6,
      metricsTotal: 137,
    },
  ],
});
