import { logger } from '@/store/logger';
import {
  allowedStates,
  storeState,
  storeStateActions,
  storeStateGetters,
  storeStateMutations,
} from './helpers/storeState';
import Api from './helpers/api';
import GETDocumentRequestProcessing from './helpers/mockResponses/GETDocumentRequest_processing';
import {
  backendToDocumentRequestsInProcessing,
  stagesToQueryStringStages,
} from './helpers/request/transformers/transformDocumentRequestsInProcessing';

const store = {
  namespaced: true,
  state: {
    ...storeState,
    documents: [],
    docUploadedCount: 0,
  },

  getters: {
    ...storeStateGetters,
    documents: (state) => state.documents,
    docUploadedCount: (state) => state.docUploadedCount,
  },

  mutations: {
    ...storeStateMutations,
    SET_DOCUMENTS(state, docs) {
      state.documents = docs;
    },
    INCREMENT_DOC_UPLOADED: (state) => {
      state.docUploadedCount += 1;
    },
  },

  actions: {
    ...storeStateActions,

    init: ({ commit }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_READY);
    },

    addNewUploadedDocument: ({ commit }) => {
      logger.debug('addNewUploadedDocument');

      // Increment docUploadedCount counter to trigger the refresh watcher.
      commit('INCREMENT_DOC_UPLOADED');
    },

    getDocumentRequestsInStages: async ({ rootGetters }, stages) => {
      logger.debug('Getting document requests in stages', stages);

      const stagesStr = stagesToQueryStringStages(stages);
      const path = `documentrequest?stage=${stagesStr}`;

      if (!rootGetters['documentRequest/offline']) {
        return (new Api(process.env, rootGetters['authenticate/idToken'])).get(path);
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, GETDocumentRequestProcessing());
      }));
    },
    getDocumentRequestsInProcessing: ({ commit, dispatch }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const inProcessingStages = [
        'DOC_REQUESTED',
        'DOC_UPLOADED',
        'EXT_START_PDF_TO_DOC',
        'EXT_START_DOC_TO_GRAPH',
        'EXT_START_LAYOUT_TAGGER',
        'EXT_START_METRIC_QUERY',
        'EXT_MANUAL_QA',
        'EXT_FAILED',
        'EXT_FAILED_DUPLICATE',
        'EXT_FAILED_INVALID_DOCUMENT',
        'EXT_FAILED_PASSWORD',
        'EXT_FAILED_UNSUPPORTED_TYPE',
      ];
      return dispatch('getDocumentRequestsInStages', inProcessingStages)
        .then((d) => {
          const docs = backendToDocumentRequestsInProcessing(d);
          logger.debug('successful response, transformed to:', docs);
          commit('SET_DOCUMENTS', docs);
          commit('SET_STORE_STATUS', allowedStates.IS_READY);

          return docs;
        });
    },

  },
};

export default store;
