<template>
  <div class="sign-in">
    <form
      class="login-form-container"
      @submit.prevent="onRequestPasswordReset"
    >
      <div
        class="login-item"
      >
        <div class="login-input-group">
          <label
            class="form-label"
            for="email"
          ><b>Email</b></label>
          <input
            id="email"
            v-model="email"
            class="text-input"
            type="text"
            name="email"
            required
          >
        </div>
      </div>
      <div class="login-button">
        <button
          class="btn-primary"
          type="submit"
        >
          Request Password Reset
        </button>
      </div>
      <div
        class="login-button"
        style="margin-top: 16px;"
      >
        <router-link
          :to="{name: 'SignIn'}"
          tag="button"
          class="btn-neg"
        >
          Back to Login
        </router-link>
      </div>
    </form>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import router from '../../router'; // eslint-disable-line import/no-cycle

export default {
  name: 'ForcePasswordChange',

  data() {
    return {
      email: '',
      toast: useToast(),
    };
  },

  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions({
      requestPasswordReset: 'authenticate/requestPasswordReset',
    }),
    reset() {
      this.email = '';
    },
    onRequestPasswordReset() {
      this.$log.info('Requesting password reset for', this.email);
      this.requestPasswordReset({ email: this.email })
        .then((email) => {
          this.$log.info('Successful password reset request!', email);
          this.toast.success('Request received');
          const userAttributes = { email };
          router.push({ name: 'ResetPassword', query: { userAttributesStr: JSON.stringify(userAttributes) } });
        })
        .catch((email) => {
          this.$log.info('Password reset request failure', email);
          this.toast.success(`Request to change password for ${email} failed`);
        });
    },
  },
};
</script>
