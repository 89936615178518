import axios from 'axios';
import clonedeep from 'lodash.clonedeep';
import { logger } from '@/store/logger';
import decodeIdToken from '@/store/helpers/authentication';
import getStartAndEnd from '@/store/helpers/display/stringModifier';
import Api from './helpers/api';
import router from '../router'; // eslint-disable-line import/no-cycle

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const config = {
  poolData: {
    native: {
      ClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    },
    sso: {
      ClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_SSO,
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    },
  },
};

const defaultState = () => ({
  authDetails: '',
  cognitoUser: null,
  desiredEntryUrl: null,
  email: '',
  tokens: {
    accessToken: '',
    idToken: '',
    refreshToken: '',
  },
  userPool: {},
  userData: '',
});

const store = {
  namespaced: true,
  state: defaultState(),

  getters: {
    authDetails: (state) => state.authDetails,
    cognitoUser: (state) => state.cognitoUser,
    desiredEntryUrl: (state) => state.desiredEntryUrl,
    email: (state) => state.email,
    idToken: (state) => state.tokens.idToken,
    isAuthenticated: (state) => !!state.cognitoUser,
    userPool: (state) => state.userPool,
  },

  mutations: {
    RESET_ALL(state) {
      logger.debug('resetting all authenticate state');
      Object.assign(state, defaultState());
    },
    RESET_AUTH(state) {
      logger.debug('resetting authenticate state');
      const newState = defaultState();
      delete newState.desiredEntryUrl;
      Object.assign(state, newState);
    },
    SIGN_OUT(state) {
      if (state.cognitoUser) {
        state.cognitoUser.signOut();
      }
    },
    SET_DESIRED_ENTRY_URL(state, newUrl) {
      state.desiredEntryUrl = newUrl;
    },
    SET_USER_POOL(state) {
      const provider = localStorage.getItem('identity_provider') || 'native';
      const poolData = config.poolData[provider];
      logger.debug('Using user pool', poolData, 'for', provider, 'identity provider');
      state.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    },
    SET_COGNITO_USER(state, cognitoUser) {
      logger.debug('Setting cognito user to:', cognitoUser);
      state.cognitoUser = cognitoUser;
    },
    SET_AUTH_DETAILS(state, authDetails) {
      state.authDetails = authDetails;
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData;
    },
    SET_TOKENS(state, tokens) {
      state.tokens.accessToken = tokens.getAccessToken().getJwtToken();
      state.tokens.idToken = tokens.getIdToken().getJwtToken();
      state.tokens.refreshToken = tokens.getRefreshToken().getToken();
      logger.debug('Set access token:', getStartAndEnd(state.tokens.accessToken));
      logger.debug('Set id token:', getStartAndEnd(state.tokens.idToken));
    },

  },

  actions: {
    logout({ commit, dispatch }) {
      commit('SIGN_OUT');
      commit('RESET_ALL');
      dispatch('localisation/reset', {}, { root: true });
    },

    attemptAuthFromStorage({ commit, getters, dispatch }) {
      // Attempts to sign in using values from local storage.
      if (getters.userPool instanceof AmazonCognitoIdentity.CognitoUserPool) {
        logger.debug('CognitoPool already set up, not attempting to auth from storage');
      } else {
        commit('SET_USER_POOL');
      }

      // Get current user if there is one.
      const newCognitoUser = getters.userPool.getCurrentUser();
      if (newCognitoUser === null) {
        return;
      }

      // Get and refresh session.
      newCognitoUser.getSession((err, session) => {
        if (err) {
          logger.error('Retrieving session failed:', err);
          return false;
        }
        logger.debug('Session validity: ', session.isValid());

        // If the session has expired, refresh the token.
        // Note: '.isValid()' only checks if credentials have expired.
        if (!session.isValid()) {
          logger.warn('Client session is invalid');
          dispatch('refresh', { currentSession: session, currentCognitoUser: newCognitoUser });
          return true;
        }

        commit('SET_COGNITO_USER', newCognitoUser);
        commit('SET_TOKENS', session);
        return true;
      });
    },
    /**
     * Refresh the session then update tokens.
     * Note: This is done in sync so the user does not have to wait while
     * their tokens get refreshed.
     */
    refresh({ commit }, { currentSession, currentCognitoUser }) {
      logger.debug('Refreshing tokens...');
      const refreshToken = currentSession.getRefreshToken();
      currentCognitoUser.refreshSession(refreshToken, (e, session) => {
        if (e) {
          logger.error('Refreshing token failed:', e);
        } else {
          commit('SET_TOKENS', session);
        }
      });
    },
    login({ commit, getters, dispatch }, { email, password }) {
      commit('SIGN_OUT');
      commit('RESET_AUTH');
      logger.debug('login details:', { email, password: '****' });
      commit('SET_USER_POOL');

      const azData = {
        Username: email,
        Password: password,
      };
      const authDetails = new AmazonCognitoIdentity.AuthenticationDetails(azData);
      const userData = { Username: azData.Username, Pool: getters.userPool };
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      return new Promise((resolve, reject) => {
        const authFinished = (_cognitoUser, _authDetails, _userData) => {
          commit('SET_COGNITO_USER', _cognitoUser);
          logger.debug('authFinished, setting cognito user:', _cognitoUser);
          commit('SET_AUTH_DETAILS', _authDetails);
          commit('SET_USER_DATA', _userData);
          localStorage.setItem('id_token', JSON.stringify(getters.idToken));
        };
        logger.debug('doing auth...');
        logger.debug('authDetails:', authDetails);
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (result) => {
            logger.debug('Sign in success!');
            logger.debug('Tokens:', result);
            commit('SET_TOKENS', result);
            logger.debug('Current user:', getters.userPool.getCurrentUser());
            logger.debug('Cognito user:', cognitoUser);
            authFinished(cognitoUser, authDetails, userData);

            dispatch('successfulLoginRedirect')
              .then(() => {
                logger.debug('successful login redirect');
                resolve(getters.email);
              })
              .catch((e) => {
                logger.debug('Unsuccessful login redirect', e);
                reject(e);
              });
          },
          onFailure: (err) => {
            logger.debug('sign in failure');
            logger.debug(err);
            authFinished(cognitoUser, authDetails, userData);
            return reject(Error('Sign in failure'));
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            logger.debug('authFinished for newPasswordRequired');
            authFinished(cognitoUser, authDetails, userData);
            // eslint-disable-next-line camelcase
            const { email_verified, ...userAttrToSubmit } = userAttributes;
            logger.debug('email_verified is not required:', email_verified);
            // User was signed up by an admin
            // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user.
            //   we can't include any non-writable attributes though
            // Required attributes according to schema, which don’t have any values yet, will have blank values.
            // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.
            logger.debug('newPasswordRequired. userAttrs:', userAttrToSubmit, 'reqAttrs:', requiredAttributes);
            logger.debug('cognitoUser before pushToForcePasswordChange:', getters.cognitoUser);
            dispatch('pushToForcePasswordChange', userAttrToSubmit)
              .then(() => resolve(getters.email))
              .catch((e) => {
                const msg = 'Unsuccessful redirect to change temporary password';
                logger.debug(msg, e);
                reject(msg);
              });
          },
        });
      });
    },
    loginViaCodeGrant({ commit, dispatch, getters }, { code }) {
      localStorage.setItem('identity_provider', 'sso');

      commit('SIGN_OUT');
      commit('RESET_AUTH');
      commit('SET_USER_POOL');

      const requestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const payload = new URLSearchParams({
        client_id: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_SSO,
        code,
        grant_type: 'authorization_code',
        redirect_uri: process.env.VUE_APP_OAUTH_AUTHORIZE_CALLBACK_URL,
      });

      return axios.post(
        process.env.VUE_APP_OAUTH_TOKEN_EXCHANGE_URL,
        payload,
        requestConfig,
      )
        .then((response) => {
          const idPayload = decodeIdToken(response.data.id_token);
          console.debug('ID token payload:', idPayload);

          const userData = {
            Username: idPayload['cognito:username'],
            Pool: getters.userPool,
          };

          const user = new AmazonCognitoIdentity.CognitoUser(userData);

          const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: response.data.refresh_token,
          });

          user.refreshSession(refreshToken, (err, session) => {
            if (err) throw new Error(err);
            if (!session.isValid()) throw new Error('Session is not valid');

            commit('SET_TOKENS', session);
            commit('SET_COGNITO_USER', user);
            commit('SET_USER_DATA', userData);

            const idToken = session.getIdToken().getJwtToken();

            new Api(process.env, idToken)
              .post('users/current')
              .then(() => {
                dispatch('successfulLoginRedirect');
              });
          });
        });
    },
    completeNewPasswordChallenge({ getters, dispatch, commit }, { newPassword, userAttributes }) {
      const { cognitoUser } = getters;
      if (cognitoUser === null) {
        const msg = 'Cognito user is not set';
        logger.error(msg);
        return Promise.reject(msg);
      }
      logger.debug('cognito user:', cognitoUser);
      logger.debug('Attempting completeNewPasswordChallenge');
      // eslint-disable-next-line no-param-reassign
      delete userAttributes.email;
      logger.debug('User attributes:', userAttributes);
      const cognitoUserCopy = clonedeep(cognitoUser);
      commit('SET_COGNITO_USER', null);
      return new Promise((resolve, reject) => {
        cognitoUserCopy.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: (result) => {
            logger.debug('Complete new password challenge success!');
            logger.debug('result:', result);
            logger.debug('Setting cognitoUser to:', cognitoUserCopy);
            commit('SET_COGNITO_USER', cognitoUserCopy);
            commit('SET_TOKENS', result);

            dispatch('successfulLoginRedirect').then(() => resolve(getters.email));
          },
          onFailure: (err) => {
            logger.debug('Complete new password challenge failure');
            logger.debug('err:', err);
            reject(new Error('Password change failed'));
          },
        });
      });
    },
    pushToForcePasswordChange(_, userAttributes) {
      return router.push({ name: 'ForcePasswordChange', query: { userAttributesStr: JSON.stringify(userAttributes) } })
        .then(() => logger.debug('Successfully changed route'))
        .catch((e) => logger.error('Route change unsuccessful', e));
    },

    requestPasswordReset({ commit, getters }, { email }) {
      logger.debug('Act. requestPasswordReset');
      commit('RESET_ALL');
      commit('SET_USER_POOL');
      const userData = { Username: email, Pool: getters.userPool };
      logger.debug('userData:', userData);
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: (result) => {
            logger.debug('Successful password reset request!', result);
            resolve(email);
          },
          onFailure: (err) => {
            logger.debug('Password reset request failure', err);
            reject(email);
          },
        });
      });
    },

    resetPasswordWithVerificationCode({ commit, getters }, { newPassword, userAttributes, verificationCode }) {
      logger.debug('Act. resetPasswordWithVerificationCode', verificationCode, 'user:', userAttributes);
      commit('RESET_ALL');
      commit('SET_USER_POOL');
      const username = userAttributes.email;
      const userData = { Username: username, Pool: getters.userPool };
      logger.debug('userData:', userData);
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess: (result) => {
            logger.debug('Successful password reset!', result);
            resolve({ email: userAttributes.email, password: newPassword });
          },
          onFailure: (err) => {
            logger.debug('Password reset failure', err);
            reject(err);
          },
        });
      });
    },

    successfulLoginRedirect({ commit, getters }) {
      let newUrl = '/';
      if (getters.desiredEntryUrl !== null) {
        newUrl = getters.desiredEntryUrl;
        commit('SET_DESIRED_ENTRY_URL', null); // reset entry url
      }
      logger.debug('attempting a redirect to:', newUrl);

      return router.push({ path: newUrl })
        .then(() => logger.debug('Successfully changed route'))
        .catch((e) => {
          logger.error('Route change unsuccessful', e);
          throw e;
        });
    },
  },
};

export default store;
