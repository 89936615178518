import axios from 'axios';
import annotationsToRTrees from '@/store/helpers/annotations/annotationsToRTrees';
import { logger } from '@/store/logger';
import GETAnnotations from './helpers/mockResponses/GETAnnotations';
import {
  storeState, storeStateGetters, storeStateMutations, allowedStates,
} from './helpers/storeState';

const initialState = () => ({
  annotations: {
    native: null,
    en: null,
  },
  language: 'native', // 'native' = default language, 'en' = english
});

const store = {
  namespaced: true,
  state: {
    ...storeState,
    ...initialState(),
  },

  getters: {
    ...storeStateGetters,
    all: (state) => state.annotations[state.language],
    trees: (state) => state.annotationTrees,
    byPageAndId: (state) => (pageNum, nodeId, language = state.language) => state.annotations[language]?.[pageNum]?.[nodeId],
    byLanguage: (state) => (language) => state.annotations[language] ?? null,
    selectedLanguage: (state) => state.language,
  },

  mutations: {
    ...storeStateMutations,
    RESET(state) {
      Object.assign(state, initialState());
    },
    UPDATE_ANNOTATIONS_BY_LANGUAGE(state, { annotations, language }) {
      state.annotations[language] = annotations;
    },
  },

  actions: {
    reset: ({ commit }) => {
      commit('RESET');
      commit('SET_PROP', { key: 'storeStatus', value: allowedStates.IS_BLANK });
    },
    /**
     * Initialises annotations store by:
     * 1. Resetting the store
     * 2. Fetching the original/native annotations from S3 (or from local repository if in offline mode)
     * 3. Setting them in state
     * 4. Computing the annotation trees from the native annotations, and also setting this in state
     * @param {String} annotationsUrl Pre-signed S3 URL of native/untranslated annotations
     * @returns annotations object
     */
    init: async ({ commit, rootGetters, dispatch }, { annotationsUrl }) => {
      logger.debug(`Getting annotations, offline: ${rootGetters['documentRequest/offline']}`);
      await dispatch('reset');
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);

      return dispatch('getAnnotations', { annotationsUrl })
        .then((annotations) => {
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
          commit('UPDATE_ANNOTATIONS_BY_LANGUAGE', { annotations, language: 'native' });
          const annotationTrees = annotationsToRTrees(annotations);
          commit('SET_PROP', { key: 'annotationTrees', value: annotationTrees });
          return annotations;
        })
        .catch((e) => {
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          logger.error(e);
          throw e;
        });
    },
    /**
     * Retrieves annotations for a given language from S3 or from cache (if already in state) and
     * updates currently selected language
     * @param {String} annotationsUrl Pre-signed S3 URL of annotations
     * @param {String} language Language of the annotations being retrieved (native = native, en = english)
     *
     * @returns annotations object
     */
    lazyInit: async ({ dispatch, commit, getters }, { annotationsUrl, language }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      let req;
      if (getters.byLanguage(language) !== null) {
        logger.debug('Getting annotations from state instead of S3 for performance');
        commit('SET_PROP', { key: 'language', value: language });
        req = new Promise(((resolve) => {
          setTimeout(resolve, 200, getters.byLanguage(language));
        }));
      } else {
        logger.debug(`Downloading ${language} annotations from S3:`, annotationsUrl);
        req = dispatch('getAnnotations', { annotationsUrl, language });
      }

      return req
        .then((annotations) => {
          commit('UPDATE_ANNOTATIONS_BY_LANGUAGE', { annotations, language });
          commit('SET_PROP', { key: 'language', value: language });
          logger.debug('Loaded annotations', annotations, 'and set language', language);
          const annotationTrees = annotationsToRTrees(annotations);
          commit('SET_PROP', { key: 'annotationTrees', value: annotationTrees });
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
          return annotations;
        })
        .catch((e) => {
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          logger.error(e);
          throw e;
        });
    },
    /**
     * Fetches and returns a JSON annotation file from S3 using a pre-signed url,
     * or from local directory if in offline mode.
     * @param {annotationsUrl} String Pre-signed url of annotation JSON file in S3
     * @param {language} String Used only in offline mode to retrieve and return an annotation file
     * based on language specified ('en' = english, null = native).
     * @returns JSON Annotation file
     */
    getAnnotations: async ({ rootGetters }, { annotationsUrl, language = 'native' }) => {
      // Get annotations from S3 if they exist.
      if (
        !rootGetters['documentRequest/offline']
        && !(process.env.NODE_ENV === 'development')
        && annotationsUrl !== null
      ) {
        logger.debug('Getting annotations from S3 using: ', annotationsUrl);
        return axios.get(annotationsUrl)
          .then((resp) => {
            logger.debug('Annotations get successful:', resp);
            return resp.data;
          });
      }

      // Otherwise use default annotations.
      const annotations = GETAnnotations(language);
      logger.debug('Overwriting annotations');
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, annotations);
      }));
    },

  },
};

export default store;
