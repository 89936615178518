<template>
  <div class="search-bar">
    <input
      :value="value"
      :placeholder="placeholder"
      @input="updateSearchString"
    >
    <magnifying-glass-icon class="search-icon" />
  </div>
</template>
<script>
import MagnifyingGlassIcon from '../icons/MagnifyingGlassIcon.vue';

export default {
  components: {
    MagnifyingGlassIcon,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['updateSearch'],
  methods: {
    updateSearchString(event) {
      this.$emit('updateSearch', event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  /* Layout */
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  /* Size */
  width: $search-bar-width;
  min-width: $search-bar-width;;
  height: 34px;

  /* Display */
  display: flex;
  position: relative;
  border-radius: 8px;
  border: #F2F2F2;
  background: #F7FAFA;
  align-self: center;

  .search-icon {
    color: $txt-col-grey;
  }

  input {
    color: #1A2C2D;
    width: calc(100% - 12px);

    /* Theme: Body/Body 3 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    ::placeholder {
      color: #999;
    }
  }
}
</style>
