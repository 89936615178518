/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext, Commit, Dispatch } from 'vuex';
import { InterDocumentEntity } from '@/components/entities/types';
import { EntityMetadataFormInputSchema } from '@/components/entities/forms/schemas';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import Api from '../helpers/api';
import { errorMessages } from '../helpers/display/toastMessages';
import { Getters, State } from './types';

const initialState = (): State => ({
  ...storeState,
  entity: null,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  entity: (state: State) => state.entity,
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_ENTITY(state: State, entity: InterDocumentEntity) {
      state.entity = entity;
      logger.debug('Entity has been updated', state.entity);
    },
    RESET(state: State) {
      logger.debug('Resetting state of entity store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ commit, dispatch }: { commit: Commit, getters: Getters, dispatch: Dispatch },
      { entityName }: { entityName: string }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing entity', entityName);
        const response = await dispatch('getEntityByNameOrId', { name: entityName });
        logger.debug('Got entity: ', response);
        commit('SET_ENTITY', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    getEntityByNameOrId: async (
      { rootGetters }: any,
      nameOrId: { name?: string, id?: string }, // { name: string } or { id: string }
    ): Promise<InterDocumentEntity> => {
      logger.debug('Initializing entity store - ', nameOrId);
      if (!nameOrId.id && !nameOrId.name) {
        throw new Error('Unable to fetch entity without a provided ID or Name');
      }
      let entity = null;
      const filters = {
        ...nameOrId.name && { name: nameOrId.name },
        ...nameOrId.id && { uuids: [nameOrId.id] },
      };
      try {
        const response = await new Api(process.env, rootGetters['authenticate/idToken'])
          .post('interdocumententity', { filters });
        if (nameOrId.id) {
          entity = response.entities.find((ide: InterDocumentEntity) => ide.interDocumentEntityId === nameOrId.id);
        }
        if (nameOrId.name) {
          entity = response.entities.find((ide: InterDocumentEntity) => ide.name === nameOrId.name);
        }
      } catch (err) {
        if (err instanceof Error) {
          throw new Error(err.message);
        }
      }

      if (!entity) {
        throw new Error(errorMessages.FAILED_ENTITY_DOES_NOT_EXIST);
      }

      return entity;
    },

    updateEntity: async ({
      rootGetters, commit, dispatch, getters,
    }: ActionContext<State, any>, newEntity: EntityMetadataFormInputSchema) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const entityId = getters.entity.interDocumentEntityId;
      logger.debug('Updating entity with the following new data: ', newEntity);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .patch(`interdocumententity/${entityId}`, newEntity)
        .then(async () => {
          const response = await dispatch('getEntityByNameOrId', { id: entityId });
          commit('SET_ENTITY', response);
          logger.debug(`Entity ${entityId} updated successfully`);
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
        })
        .catch((e: any) => {
          logger.error(`Failed to update entity with id: ${entityId}`, e);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },

    deleteEntity: async (
      { rootGetters, getters, commit }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const entityId = getters.entity.interDocumentEntityId;
      logger.debug(`Deleting entity with the following interDocumentEntityId: ${entityId}`);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('interdocumententity/delete', { uuids: [entityId] })
        .then(() => {
          commit('RESET'); // Note, store status will be set to IS_BLANK
          logger.debug(`Entity ${entityId} deleted successfully and entity store has been reset`);
        })
        .catch((e: any) => {
          logger.error(`Failed to delete entity: ${entityId}`, e);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
  },
};

export default store;
