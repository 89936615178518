<template>
  <td class="freyda-table-cell">
    <slot name="content" />
  </td>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.freyda-table-cell {
  padding: 8px 20px;
  height: 64px;
  max-width: 200px;
  align-items: center;
  align-self: stretch;
  background: #FFF;

  border-right: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
}
</style>
<style lang="scss">
.freyda-table-cell-text-title {
  color: #426D70;

  /* Labels/Labels 2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.freyda-table-cell-text {
  color: #999;

  /* Body/Body 2 */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  &--centered {
    text-align: center;
  }
}

.freyda-table-cell-link {
  color: #426D70;
  text-decoration: none;
  overflow-wrap: anywhere;
}

.freyda-table-cell-subtext {
  color: #999;

  /* Body/Body 2 */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.freyda-table-cell-content-wrapper { // Use this class to apply default styling to the cell
  display: flex;
  align-items: center;
  height: 100%;

  &.cell-content-align-center {
    justify-content: center;
  }
}
</style>
