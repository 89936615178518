import { createApp } from 'vue';
import FloatingVue from 'floating-vue';
import { VueQueryPlugin, VueQueryPluginOptions } from 'vue-query';
import Toast from 'vue-toastification';
import clickOutsideElement from '@/directives/clickOutsideElement';
import VueAwesomePaginate from 'vue-awesome-paginate';
import App from './App.vue';
import store from './store';
import router from './router';
import { VueLogger, loggerOptions } from './store/logger';
import 'vue-toastification/dist/index.css';
import 'floating-vue/dist/style.css';
import 'vue-awesome-paginate/dist/style.css';

const tooltipOptions = {
  themes: {
    html: false,
  },
};

const toastOptions = {
  transition: 'Vue-Toastification__fade',
  hideProgressBar: true,
  icon: false,
  position: 'bottom-center',
};

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

const app = createApp(App);
app.use(router);
app.use(store);
app.use(FloatingVue, tooltipOptions);
app.use(Toast, toastOptions);
app.use(VueQueryPlugin, vueQueryPluginOptions);
app.use(VueLogger, loggerOptions as any);
app.use(VueAwesomePaginate);
app.directive('click-outside-element', clickOutsideElement);
app.mount('#app');
