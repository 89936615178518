import clonedeep from 'lodash.clonedeep';
import exportOptions from '@/store/helpers/mapping/exportOptions';
import { logger } from '@/store/logger';
import {
  storeStateActions, allowedStates, storeStateGetters, storeStateMutations, storeState,
} from './helpers/storeState';
import exportRowsToCsv from './helpers/exportRowsToCsv';
import GETDocumentRequest from './helpers/mockResponses/GETDocumentRequest_2';
import validateDocumentRequest from './helpers/request/validators/validateDocumentRequest';
import PUTDocumentRequest from './helpers/mockResponses/PUTDocumentRequest';
import {
  backendToDocumentRequest,
  documentRequestToBackend,
  removeAsteriskFromAKAHeaders,
} from './helpers/request/transformers/transformDocumentRequest';
import Api from './helpers/api';
import initialClientDataPoint from './helpers/metrics/initialClientDataPoint';
import { getExportValueFromDp, getVerifyExportValueFromDp } from './helpers/metrics/exportDatapointValue';
import {
  saveJsonFile, saveB64ZipFile, saveFile, gzipToJSON,
} from './helpers/fileDownloaders';
/*
Name: {Display label}, Mandatory: {is_mand}, Data Type: {type},
Verified: {is_verif}, Meta: {meta}, Datapoints: [{text, value, page, conf}]
 */

const ensureFirstDataPointUserModified = (metric) => {
  const m = metric;
  logger.debug('ensure dp for:', m.metric);
  if (!m.datapoints.length
    || !m.datapoints[0].userModified
    || !m.editedSinceSave
  ) {
    m.editedSinceSave = true;

    const prev = clonedeep(m.datapoints[0]);
    const newDP = initialClientDataPoint(prev.text, prev.value, prev.effectors, true, prev.page, 1.0);
    logger.debug('New dp:', newDP);
    m.datapoints.unshift(newDP);
  }
  return m;
};

const initialState = () => ({
  id: '',
  verifyStatus: 'DOC_PROCESSED',
  documentType: '',
  metrics: [],
  grouping: {},
  selectedMetricIndex: -1,
  fieldIndexBeingSelected: -1,
});

const store = {
  namespaced: true,
  state: {
    ...storeState,
    offline: false,
    ...initialState(),
  },

  getters: {
    ...storeStateGetters,
    grouping: (state) => state.grouping,
    offline: (state) => state.offline,
    id: (state) => state.id,
    requiredMetrics: (state) => state.metrics.filter((m) => m.required),
    optionalMetrics: (state) => state.metrics.filter((m) => !m.required),
    verifiedMetrics: (_, getters) => getters.verifiedRequiredMetrics.concat(getters.verifiedOptionalMetrics),
    verifiedOptionalMetrics: (_, getters) => getters.optionalMetrics.filter((m) => m.verified),
    verifiedRequiredMetrics: (_, getters) => getters.requiredMetrics.filter((m) => m.verified),
    metrics: (state) => state.metrics,
    metricByIndex: (state) => (metricIndex) => state.metrics[metricIndex],
    allRequiredVerified: (state, getters) => getters.requiredMetrics.reduce((prev, cur) => (cur.required ? prev && cur.verified : prev), [true]),
    documentType: (state) => state.documentType,
    verifyStatus: (state) => state.verifyStatus,
    fieldIndexBeingSelected: (state) => state.fieldIndexBeingSelected,
    selectedMetricIndex: (state) => state.selectedMetricIndex,
    selectedMetric: (_, getters) => (getters.selectedMetricIndex >= 0
      ? getters.metrics[getters.selectedMetricIndex]
      : {}),
    metricDataPoint: (_, getters) => (metricIndex) => getters.metricByIndex(metricIndex).datapoints[0],
    metricDataPoints: (_, getters) => (metricIndex) => getters.metricByIndex(metricIndex).datapoints,
    metricTextValue: (_, getters) => (metricIndex) => getters.metricDataPoint(metricIndex).text,
    metricValue: (_, getters) => (metricIndex) => getters.metricDataPoint(metricIndex).value,
    metricConfidence: (_, getters) => (metricIndex) => getters.metricDataPoint(metricIndex).confidence,
    metricCurrencyValue: (_, getters) => (metricIndex) => getters.metricDataPoint(metricIndex).effectors.currency,
    metricExportValue: (_, getters, rootState, rootGetters) => (metricIndex) => {
      const { dataType } = getters.metricByIndex(metricIndex);
      return getVerifyExportValueFromDp(getters.metricDataPoint(metricIndex), dataType, rootGetters['localisation/dateFormat']);
    },
    metricValueHasBeenEdited: (_, getters) => (metricIndex) => {
      const metric = getters.metricByIndex(metricIndex);
      if (metric.datapoints.length < 1) {
        logger.error(`Metric ${metric} does not have any datapoints`);
        return false;
      }

      return metric.datapoints[0].userModified;
    },
  },

  mutations: {
    ...storeStateMutations,
    RESET(state) {
      Object.assign(state, initialState());
    },
    SET_NEW_DATAPOINTS(state, { metricIndex, datapoints }) {
      state.metrics[metricIndex].datapoints = [];
      datapoints.forEach((dp) => state.metrics[metricIndex].datapoints.push(dp));
    },
    SET_CLIENT_DATA_POINT(state, { fieldIndex, val, annotationInfo }) {
      /**
       * @param annotationInfo: { pageIndex, nid, nodeText }
       */

      // TODO: move this mutation to an action
      const metric = state.metrics[fieldIndex];
      logger.debug('Setting datapoint for:', metric.datapoints[0].value);
      logger.debug(`(${metric.datapoints[0].text}), editedSinceSave: ${metric.editedSinceSave}`);
      ensureFirstDataPointUserModified(metric);

      // Keep text input the same if the value has not changed, as this impacts whether the value
      // will be found in the pdf.
      // If value has changed, blank the loc so that no pdf search is made

      if (annotationInfo !== undefined) {
        metric.datapoints[0].page = annotationInfo.pageIndex + 1;
        metric.datapoints[0].text = annotationInfo.nodeText;
        metric.datapoints[0].node_id = annotationInfo.nid;
      } else {
        let newTextValue = val;
        if (metric.datapoints.length > 1 && metric.datapoints[1].value === val) {
          newTextValue = metric.datapoints[1].text;
        } else {
          metric.datapoints[0].page = null;
          metric.datapoints[0].node_id = null;
        }

        metric.datapoints[0].text = newTextValue;
      }

      metric.datapoints[0].value = val;
      logger.debug('new dp:', metric.datapoints[0].value, `(${metric.datapoints[0].text})`);
    },
    SET_DATA_POINT_CURRENCY(state, { field, val }) {
      logger.debug('SET_DATA_POINT_CURRENCY', val);
      const metric = state.metrics[field.index];
      logger.debug('Setting datapoint for:', metric.datapoints[0].value);
      logger.debug(`(${metric.datapoints[0].text}), editedSinceSave: ${metric.editedSinceSave}`);
      ensureFirstDataPointUserModified(metric);

      metric.datapoints[0].effectors.currency = val;
    },
    SET_ID(state, id) {
      state.id = id;
    },
    SET_INITIAL_STATE(state, t) {
      if (state.id !== t.id) {
        throw Error(`Id retrieved did not match stored: ${state.id} --fetched ${t.id}`);
      }
      state.id = t.id;
      state.verifyStatus = t.verifyStatus;
      state.documentType = t.documentType;
      state.metrics = t.metrics;
      state.grouping = t.grouping;
    },
    SET_METRIC_PROP(state, {
      index, listName, prop, value,
    }) {
      state[listName][index][prop] = value;
    },
    TOGGLE_METRIC_PROP(state, { metric, prop }) {
      ensureFirstDataPointUserModified(metric); // ** TODO: This should be removed once metrics stored on backend
      state.metrics[metric.index][prop] = !state.metrics[metric.index][prop];
    },
    TOGGLE_IS_METRIC_VERIFIED(state, { metric }) {
      state.metrics[metric.index].verified = !state.metrics[metric.index].verified;
    },
    TOGGLE_IS_METRIC_REQUIRED(state, { metric }) {
      state.metrics[metric.index].required = !state.metrics[metric.index].required;
    },
    SET_FIELD_INDEX_BEING_SELECTED(state, index) {
      state.fieldIndexBeingSelected = index;
    },
    SET_SELECTED_METRIC_INDEX(state, { metric }) {
      state.selectedMetricIndex = metric.index;
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ dispatch, commit }, { documentRequestId }) => {
      try {
        logger.debug('documentRequest init');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        commit('RESET');

        const response = await dispatch('getDocumentRequest', { documentId: documentRequestId });
        const docReq = gzipToJSON(response.gzip);
        removeAsteriskFromAKAHeaders(docReq);
        validateDocumentRequest(docReq);
        const transformed = backendToDocumentRequest(docReq);
        logger.debug('documentRequestId:', documentRequestId, 'transformed:', transformed);
        commit('SET_ID', documentRequestId);
        commit('SET_INITIAL_STATE', transformed);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    save: async ({
      state, getters, dispatch, commit,
    }) => {
      if ([allowedStates.IS_BLANK, allowedStates.IS_LOADING].includes(getters.storeStatus)) {
        throw new Error('Store not ready (request still loading) Please try refreshing the page');
      }
      try {
        logger.debug('Save data for documentrequest');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);

        const content = documentRequestToBackend(clonedeep(state));
        const res = await dispatch('postDocumentRequest', { body: content });
        logger.debug('res:', res);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);

        return res;
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    getDocumentRequest: ({ getters, rootGetters }, { documentId }) => {
      logger.debug(`Getting documentrequest ${documentId}, offline: ${getters.offline}`);
      if (!getters.offline) {
        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .get(`documentrequest/${documentId}`);
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, GETDocumentRequest());
      })).then((response) => {
        logger.debug('getting offline document request:', documentId);
        const r = response;
        r.documentRequestId = documentId;
        return r;
      });
    },
    alternativeDatapointSelected: ({ getters, commit }, { metric, newDpId }) => {
      logger.debug(getters, commit);
      logger.debug('new dp index:', newDpId, 'metric:', metric);

      const dpId = newDpId;
      let dps = clonedeep(metric.datapoints);
      for (let i = 0; i < dps.length; i++) {
        if (dps[i].dpId === dpId) {
          logger.debug('Moving index dpId:', dpId, 'to the front');

          dps.unshift(dps.splice(i, 1)[0]);

          // filter out userModified choices since a system selected option has been chosen:
          dps = dps.filter((d) => !d.userModified);
          commit('SET_NEW_DATAPOINTS', { metricIndex: metric.index, datapoints: dps });
          return;
        }
      }
      logger.debug('Could not find dpId:', dpId, 'in', metric.datapoints);
    },
    downloadTables: ({ getters, rootGetters }) => {
      logger.debug('Downloading tables');

      const path = `documentrequest/${getters.id}/tables`;
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get(path)
        .then((r) => {
          logger.debug('Get download url:', r);
          return r;
        })
        .then((url) => {
          window.location = url;
          return url;
        });
    },
    postDocumentRequest: async ({ getters, rootGetters }, { body }) => {
      logger.debug('POST body:', body);
      if (!getters.offline) {
        const path = `documentrequest/${getters.id}/datapoints`;

        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .post(path, body);
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, PUTDocumentRequest);
      }));
    },
    postExportMany: async ({ getters, rootGetters }, { body, format }) => {
      logger.debug('POST body:', body);
      if (getters.offline) {
        throw Error('Local export_many not implemented');
      }
      const contentType = exportOptions.FORMAT_MAPPINGS[format] ?? null; // csv, json, etc...
      const path = `documentrequest/export_many${contentType && `?content=${contentType}`}`;

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(path, body);
    },
    exportMany: async ({ dispatch }, { documentRequestIds, format, includeUnverified = false }) => {
      logger.debug(`Exporting ${documentRequestIds} to format ${format}, includeUnverified = ${includeUnverified}`);
      const contentType = exportOptions.FORMAT_MAPPINGS[format];
      return dispatch('postExportMany', { body: { documentRequests: documentRequestIds, include_unverified: includeUnverified }, format })
        .then((data) => {
          const fileName = includeUnverified ? 'all_metrics' : 'verified_metrics';
          switch (contentType) {
            case 'document_graph':
              saveFile('document_graph.zip', data.documentGraph);
              break;
            case 'json':
              saveJsonFile(data, fileName);
              break;
            default:
              saveB64ZipFile(data.b64, fileName);
              break;
          }
        });
    },
    exportManyToJson: async ({ rootGetters }, { documentRequestIds }) => {
      logger.debug(`Exporting ${documentRequestIds} to JSON`);
      const path = 'documentrequest/export_many?content=json';

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(path, { documentRequests: documentRequestIds });
    },
    exportVerifiedMetricsToCSV: async ({ getters, rootGetters, dispatch }) => {
      const metricIndexToRgNameMapping = await dispatch('getMetricIndexToParentalRepeatingGroupNameMapping', {
        group: getters.grouping, currentRgName: null,
      });
      logger.debug('metricIndexToRgNameMapping', metricIndexToRgNameMapping);

      const { verifiedMetrics } = getters;
      if (!verifiedMetrics.length) {
        throw new Error('No metrics have been verified for this document');
      }
      if (!getters.allRequiredVerified) {
        throw new Error('Not all of the required metrics have been verified');
      }
      const rows = [['Field Name', 'Verified Value']];
      const dateFormat = rootGetters['localisation/dateFormat'];
      verifiedMetrics.forEach((m) => {
        const idxToRgName = metricIndexToRgNameMapping[m.index];
        let prefix = '';
        if (idxToRgName !== null && idxToRgName !== undefined) {
          prefix = `${idxToRgName} - `;
        }
        rows.push([
          `${prefix}${m.displayLabel}`,
          getExportValueFromDp(m.datapoints[0], m.dataType, dateFormat),
        ]);
      });

      exportRowsToCsv('verified_metrics.csv', rows);

      return { status: 'SUCCESS' };
    },
    getMetricIndexToParentalRepeatingGroupNameMapping: async ({ dispatch, getters }, { group, currentRgName }) => {
      const all = {};
      for (const g of group.groups) { // eslint-disable-line no-restricted-syntax
        let subGroupRgName = currentRgName;
        if (g.allowRepeatable) {
          if (g.groups.length && g.groups[0].metrics.length) {
            const [subGroupRgIndex] = g.groups[0].metrics;
            const subGroupRgMetric = getters.metricByIndex(subGroupRgIndex);
            if (subGroupRgMetric.datapoints.length) {
              subGroupRgName = subGroupRgMetric.datapoints[0].value;
            }
          }
        }

        // eslint-disable-next-line no-await-in-loop
        const mappingInSubgroups = await dispatch('getMetricIndexToParentalRepeatingGroupNameMapping', { group: g, currentRgName: subGroupRgName });
        Object.assign(all, mappingInSubgroups);

        for (let i = 0; i < g.metrics.length; i++) {
          const metricIndex = g.metrics[i];
          if (metricIndex in all) {
            throw Error(`metricIndex ${metricIndex} already in mapping ${all}`);
          }

          all[metricIndex] = subGroupRgName;
        }
      }

      return all;
    },
    generateDocumentsReport: async ({ rootGetters }, { documentRequestIds }) => {
      logger.debug(`Generating report for ${documentRequestIds}`);
      const path = 'reports';

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(path, { document_request_ids: documentRequestIds })
        .then((r) => {
          if (r.status === 'failure') {
            throw new Error('Request failure');
          }
          return r;
        });
    },
  },
};

export default store;
