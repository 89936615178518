type EntityTypeStyleConfig = {
  backgroundColor: string,
};

const entityTypesStyle: { [key: string]: EntityTypeStyleConfig } = {
  ADMINISTRATOR: {
    backgroundColor: '#ADFFD3',
  },
  AUDITOR: {
    backgroundColor: '#F78964',
  },
  BANK: {
    backgroundColor: '#C2E9FA',
  },
  COMPANY: {
    backgroundColor: '#FFF59B',
  },
  'FACILITY PROVIDER': {
    backgroundColor: '#FFBB6A',
  },
  FUND: {
    backgroundColor: '#D3D2FF',
  },
  'FUND MANAGER': {
    backgroundColor: '#78C2C7',
  },
  'GENERAL PARTNER': {
    backgroundColor: '#F7BFE4',
  },
  'INTERMEDIATE BANK': {
    backgroundColor: '#93CBFF',
  },
  'INVESTMENT ADVISOR': {
    backgroundColor: '#56AEFF',
  },
  JURISDICTION: {
    backgroundColor: '#F7BFBF',
  },
  'LEGAL COUNSEL': {
    backgroundColor: '#BDD8AC',
  },
  'LIMITED PARTNER': {
    backgroundColor: '#F2D08E',
  },
  'PLACEMENT AGENT': {
    backgroundColor: '#E2BDFF',
  },
  SUPPLIER: {
    backgroundColor: '#FFD6A5',
  },
  VEHICLE: {
    backgroundColor: '#9F9DFF',
  },
};

export default entityTypesStyle;
