<template>
  <tr class="freyda-table-row">
    <slot name="cells" />
  </tr>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
</style>
